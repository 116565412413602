import Phaser from 'phaser'
import { SANTA } from './player'

// export const GROUND_WIDTH = 400
export const GROUND_HEIGHT = 200

export default class Ground extends Phaser.GameObjects.Container {
  constructor (x, y, width, scene) {
    super(scene)

    this.image = this.scene.add.image(x, y, 'white')
    this.image.setSize(width, GROUND_HEIGHT)
    this.image.setDisplaySize(width, GROUND_HEIGHT)
    this.image.setOrigin(0, 0)
    this.image.setDepth(5)
    this.image.setTint(scene.colorFG)

    this.line = this.scene.add.image(x, y, 'white')
    this.line.setSize(width, 5)
    this.line.setDisplaySize(width, 5)
    this.line.setOrigin(0, 0)
    this.line.setDepth(5)

    if (scene.game.registry.get('selectedAnimal') === SANTA) {
      // ..
    } else {
      this.line.setTint(0)
    }
  }
}
