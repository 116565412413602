import Phaser from 'phaser'

export default class CameraController {
  constructor (scene, player) {
    this.scene = scene
    this.player = player
    this.cam = this.scene.cameras.main

    this.x = 0
    this.y = 360
    this.offset = 500
    this.targetX = 0
    this.speed = 0.01

    // some debug lines:
    // var temp = this.scene.add.image(1280 / 3, 400, 'white').setTint(0)
    // temp.setDisplaySize(10, 1600)
    // temp.setScrollFactor(0, 0)
    // temp = this.scene.add.image(1280 / 6, 400, 'white').setTint(0x999999)
    // temp.setDisplaySize(10, 1600)
    // temp.setScrollFactor(0, 0)
    // temp = this.scene.add.image(1280 * (2 / 3), 400, 'white').setTint(0)
    // temp.setDisplaySize(10, 1600)
    // temp.setScrollFactor(0, 0)
    // temp = this.scene.add.image(500, 720 * (4 / 6), 'white').setTint(0)
    // temp.setDisplaySize(2000, 10)
    // temp.setScrollFactor(0, 0)
    // temp = this.scene.add.image(500, 720 * (2 / 6), 'white').setTint(0)
    // temp.setDisplaySize(2000, 10)
    // temp.setScrollFactor(0, 0)
    // temp = this.scene.add.image(500, 720 * (5 / 6), 'white').setTint(0x999999)
    // temp.setDisplaySize(2000, 10)
    // temp.setScrollFactor(0, 0)

    this.editorScroll = 500
  }

  scrollX (x) {
    this.editorScroll -= x
  }

  update (time, delta) {
    if (this.scene.levelEditor.enabled) {
      if (this.scene.game.device.os.desktop) {
        if (this.scene.input.activePointer.y > 200 &&
          (this.scene.input.activePointer.x > 1000 || this.scene.input.activePointer.x < 280)) {
          this.targetX = this.scene.levelEditor.image.x - 500
          var diff = (this.scene.input.activePointer.x > 1000) ? this.scene.input.activePointer.x - 1000 : 280 - this.scene.input.activePointer.x
          this.speed = 0.00001 * diff
        } else {
          this.targetX = this.x
        }
        this.x += ((this.targetX - this.x) * (delta * this.speed))
        this.offset = 500
      } else {
        this.offset = this.editorScroll
      }
    } else {
      // if (this.scene.finished) {
      this.targetX = Math.min(this.player.image.x, this.scene.spawner.finishX + 80)
      // } else {
      //  this.targetX = this.player.image.x
      // }
      this.speed = 0.01
      this.offset = 500
      var max = 70
      var min = 70
      if (this.targetX > this.x + max) {
        this.x = this.targetX - max
      } else if (this.targetX < this.x + min) {
        this.x = this.targetX - min
      }
    }

    this.cam.setScroll(this.x - this.cam.width / 2 + this.offset, this.cam.scrollY)
  }
}
