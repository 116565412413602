import Phaser from 'phaser'

export default class AnimatedParticle extends Phaser.GameObjects.Particles.Particle {
  constructor (emitter) {
    super(emitter)

    this.t = 0
    this.i = 0

    this.length = 1
    this.rotationSpeed = Phaser.Math.Between(-0.5, 0.5)

    this.anim = emitter.manager.scene.anims.create({
      key: 'confetti_anim',
      frames: 'confetti',
      frameRate: 20,
      repeat: -1
    })
  }

  update (delta, step, processors) {
    var result = super.update(delta, step, processors)
    this.t += delta
    if (this.t >= this.anim.msPerFrame) {
      this.i++
      if (this.i > this.length) {
        this.i = 0
      }
      this.frame = this.anim.frames[this.i].frame
      this.t -= this.anim.msPerFrame
    }

    this.angle += delta * this.rotationSpeed
    return result
  }
}
