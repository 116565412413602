import Phaser from 'phaser'
import { SANTA } from './player'

const WIDTH = 125

export default class MovingObstacle extends Phaser.GameObjects.Container {
  constructor (x, bottom, range, phase, scene) {
    super(scene)
    this.scene = scene

    this.hasPassed = false

    this.bottom = bottom
    this.range = range
    this.phase = phase

    this.bottomHandler = null
    this.topHandler = null

    const vertices = [
      new Phaser.Math.Vector2(0, WIDTH / 2),
      new Phaser.Math.Vector2(WIDTH / 2, 0),
      new Phaser.Math.Vector2(0, -WIDTH / 2),
      new Phaser.Math.Vector2(-WIDTH / 2, 0)]

    if (this.scene.game.registry.get('selectedAnimal') === SANTA) {
      this.triangle = this.scene.matter.add.image(x, 558 - bottom, 'santa_images', 'santa_diamond', { vertices: vertices })
    } else {
      this.triangle = this.scene.matter.add.image(x, 558 - bottom, 'images', 'diamond', { vertices: vertices })
      this.triangle.setTint(this.scene.colorSpikes)
    }

    this.triangle.setIgnoreGravity(true)
    this.triangle.setSensor(true)
    this.triangle.setDepth(3)
    this.triangle.parentContainer = this
    this.triangle.setCollisionGroup(this.scene.collisionGroup1)
    this.triangle.setCollisionCategory(this.scene.collisionCategory2)

    this.line = this.scene.add.image(x, 0, 'images', 'finish')
    this.line.setOrigin(0.5, 1)
    this.refreshLine()

    this.resetTween()
  }

  refreshLine () {
    this.line.x = this.triangle.x
    this.line.y = 558 - this.bottom// - this.range
    this.line.setDisplaySize(10, this.range)
  }

  resetTween () {
    if (this.tween) {
      this.scene.tweens.remove(this.tween)
    }

    this.triangle.y = 558 - this.bottom

    this.tween = this.scene.tweens.add({
      targets: this.triangle,
      y: '-=' + this.range,
      yoyo: true,
      repeat: -1,
      ease: 'Sine.easeInOut',
      duration: 2000,
      delay: this.phase

    })
  }

  pauseTween (reset = true) {
    if (this.tween) {
      this.scene.tweens.remove(this.tween)
      this.tween = null
    }
    if (reset) {
      this.triangle.y = (558 - this.bottom) - ((this.phase / 2000) * this.range)
    }
  }

  resumeTween () {
    this.resetTween()
  }

  preUpdate (time, delta) {
    if (!this.hasPassed && this.triangle.x < this.scene.player.image.x - 10) {
      this.hasPassed = true
      this.scene.player.addScore()
    }
  }

  getX () {
    return this.triangle.x
  }

  getY () {
    return this.triangle.y
  }

  setX (x) {
    this.triangle.x = x
    this.topHandler.updateX(x)
    this.bottomHandler.updateX(x)
    this.refreshLine()
  }

  setY (y) {
    this.triangle.y = Phaser.Math.Clamp(558 - y, this.topHandler.image.y, this.bottomHandler.image.y)
    this.phase = 2000 * (((558 - this.triangle.y) - this.bottom) / this.range)
  }

  setTop () {
    this.bottom = 558 - this.bottomHandler.image.y
    this.range = (this.bottomHandler.image.y - this.topHandler.image.y)
    this.refreshLine()
  }

  setBottom () {
    this.bottom = 558 - this.bottomHandler.image.y
    this.range = (this.bottomHandler.image.y - this.topHandler.image.y)
    this.refreshLine()
  }

  toDef () {
    return { x: Math.round(this.triangle.x), t: 'mO', c: { b: Math.round(this.bottom), r: Math.round(this.range), p: Math.round(this.phase) } }
  }

  delete () {
    this.bottomHandler.onDeleted()
    this.bottomHandler.destroy()
    this.topHandler.onDeleted()
    this.topHandler.destroy()

    this.triangle.destroy()
    this.line.destroy()
    this.destroy()
    return this
  }
}
