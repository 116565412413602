import Phaser from 'phaser'
import TitleScene from './title'
import AnimatedParticle from '../actors/animatedParticle'

const TUT_RIGHT_LEVEL_ID = 0
const TUT_LEFT_LEVEL_ID = 3

export default class HudScene extends Phaser.Scene {
  create ({ game, player }) {
    this.player = player
    this.game = game

    var config = {
      fontSize: '30px',
      fontStyle: 'bold',
      rtl: true
    }

    var levelId = this.game.levelId
    this.currLevelTxt = this.add.text(640 - 110, 50, this.game.isLevelURL ? '0' : levelId, config)
    levelId++
    config.rtl = false
    this.nextLevelTxt = this.add.text(640 + 110, 50, this.game.isLevelURL ? 'X' : levelId, config)

    if (location.hostname === 'localhost') {
      this.nextLevelTxt.text += ' (' + Math.round(this.game.difficultyManager.rating / 55.55) + ')'
    }
    // this.currLevelTxt.setShadow(2, 2, '#bbb')
    // this.nextLevelTxt.setShadow(2, 2, '#bbb')

    // add some particles
    this.exploded = false
    this.confettiParticles = this.add.particles('confetti')
    this.confettiParticles.setDepth(900)

    this.confetti = this.confettiParticles.createEmitter({
      on: false,
      x: 500,
      y: 300,
      tint: [0xff4444, 0xffff00, 0x4444ff, 0xff0000, 0x00ff00, 0x0000ff],
      active: true,
      // frequency: 1000,
      quantity: 75,
      lifespan: 4000,
      // angle: { min: -30, max: -60 },
      speed: { min: 750, max: 1500 },
      gravityY: 1000,
      rotate: { onEmit: function (particle, key, t, value) { return Phaser.Math.Between(0, 360) } },
      particleClass: AnimatedParticle
    })

    this.progressBarBG = this.add.image(640 - 100, 54, 'white')
    this.progressBarBG.setDisplaySize(200, 20)
    this.progressBarBG.setOrigin(0, 0)
    this.progressBarBG.setTint(0)
    this.progressBarBG.setAlpha(0.25)

    this.progressBar = this.add.image(640 - 100, 54, 'white')
    this.progressBar.setDisplaySize(0, 20)
    this.progressBar.setOrigin(0, 0)

    this.progress = 0

    this.hasJustUnlocked = TitleScene.hasJustUnlocked(this.game.levelId)
    if (this.hasJustUnlocked > 0) {
      this.levelComplete = this.add.image(0, 0, 'images', 'unlocked')
      this.unlockIm = this.add.image(0, 0, 'unlocks', this.hasJustUnlocked)
      this.unlockIm.setVisible(false)
      this.unlockName = this.add.sprite(0, 0, 'names', this.hasJustUnlocked)
      this.unlockName.setScale(0.5)
      this.unlockName.setVisible(false)

      this.text2 = this.add.sprite(0, 0, 'names', 0)
      this.text2.setScale(0.5)
      this.text2.setVisible(false)
    } else {
      this.levelComplete = this.add.image(0, 0, 'images', 'levelComplete')
    }
    this.levelComplete.setVisible(false)

    this.landscape = window.innerWidth > window.innerHeight
    this.scale.on('resize', () => {
      const oldLS = this.landscape
      this.landscape = window.innerWidth > window.innerHeight
      if (this.landscape !== oldLS) {
        this.onResize()
      }
    })
    this.onResize()
  }

  onResize () {
    if (this.cameras.main) {
      if (this.landscape) {
        this.cameras.main.setZoom(1)
      } else {
        this.cameras.main.setZoom(0.5625)
      }

      this.levelComplete.setPosition((this.scale.gameSize.width / 2), 230)
      if (this.unlockIm) {
        this.unlockIm.setPosition((this.scale.gameSize.width / 2) + 174, 230)
        this.unlockName.setPosition((this.scale.gameSize.width / 2) + 174 + this.getOffsetForUnlock(this.hasJustUnlocked), 330) // TODO: plus offset!
        this.text2.setPosition(this.unlockName.x + 70, this.unlockName.y) // TODO: plus offset!
      }
      this.progressBar.setPosition((this.scale.gameSize.width / 2) - 100, 54)
      this.progressBarBG.setPosition((this.scale.gameSize.width / 2) - 100, 54)
      this.currLevelTxt.setPosition((this.scale.gameSize.width / 2) - 110, 50)
      this.nextLevelTxt.setPosition((this.scale.gameSize.width / 2) + 110, 50)

      if (this.touch) {
        this.touch.onResize(this.landscape)
      }
    }
  }

  update (time, delta) {
    this.progress = this.player.image.x / (this.game.spawner.finishX + 500)
    this.progress = Phaser.Math.Clamp(this.progress, 0, 1)
    this.progressBar.setDisplaySize(this.progress * 200, 20)

    if (this.game.finished) { // progress >= 1) {
      this.levelComplete.setVisible(true)
      if (this.hasJustUnlocked > 0) {
        this.explodeConfetti()
        this.unlockIm.setVisible(true)
        this.unlockName.setVisible(true)

        this.text2.setVisible(this.hasJustUnlocked === 6 || this.hasJustUnlocked === 7)
      }
      this.progressBar.setVisible(false)
      this.progressBarBG.setVisible(false)
      this.currLevelTxt.setVisible(false)
      this.nextLevelTxt.setVisible(false)
    }
  }

  explodeConfetti () {
    if (this.exploded) {
      return
    }

    this.confetti.setPosition(this.landscape ? 0 : -500, 600)
    this.confetti.setAngle({ min: -60, max: -90 })
    this.confetti.explode()

    this.confetti.setPosition(1280, 600)
    this.confetti.setAngle({ min: -120, max: -90 })
    this.confetti.explode()

    this.exploded = true
  }

  getOffsetForUnlock (id) {
    switch (id) {
      case 1:
        return -24
      case 2:
        return -20// 10
      case 3:
        return -6// 3
      case 4:
        return 2// 1
      case 5:
        return -22// 11
      case 6:
        return -56// 8
      case 7:
        return -40// 5
      case 8:
        return -10// 5
    }
    return 0
  }
}
