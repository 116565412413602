import Phaser from 'phaser'

export default class SkipLevelScene extends Phaser.Scene {
  create (game) {
    this.game = game

    this.image = this.add.image(0, 0, 'images', 'skiplevel')
    this.image.setInteractive()
    this.image.input.alwaysEnabled = true
    this.image.setAlpha(0.75)
    this.image.on('pointerover', () => { this.image.setAlpha(1) })
    this.image.on('pointerout', () => { this.image.setAlpha(0.75) })
    this.image.on('pointerup', (pointer, localX, localY, event) => { this.skipButtonPressed() })

    this.landscape = window.innerWidth > window.innerHeight
    this.scale.on('resize', () => {
      const oldLS = this.landscape
      this.landscape = window.innerWidth > window.innerHeight
      if (this.landscape !== oldLS) {
        this.onResize()
      }
    })
    this.onResize()

    this.show()
  }

  show () {
    this.tweens.add({
      targets: this.image,
      y: this.landscape ? '-= 200' : '+= 200',
      ease: 'Back.easeOut',
      duration: 500
    })
  }

  onResize () {
    this.image.setPosition(this.scale.gameSize.width / 2, this.landscape ? 860 : 0)
  }

  skipButtonPressed () {
    this.scene.get('GameScene').skipLevel()
  }

  update (time, delta) {
    if (this.game.game.player && this.game.game.player.image.x > 400) {
      this.hide()
    }
  }

  hide () {
    this.tweens.add({
      targets: this.image,
      y: this.landscape ? '+= 200' : '-= 200',
      ease: 'Back.easeOut',
      duration: 500
    })

    setTimeout(() => { this.scene.stop('SkipLevelScene') }, 500)
  }
}
