import Phaser from 'phaser'
import CameraController from '../components/camera_controller'
import Player, { SANTA } from '../actors/player'
import Spawner from '../components/spawner'
import DifficultyManager from '../components/difficultyManager'
import { LAYER_PLAYER, LAYER_PARTICLES } from '../layers'
import LevelDefs from '../components/levelDefs'
import LevelEditor from '../actors/levelEditor'
import TouchInput from '../components/touchInput'
import TitleScene from './title'

export default class GameScene extends Phaser.Scene {
  create () {
    if (process.env.NODE_ENV !== 'production') {
      window.scene = this
    }

    this.difficultyManager = new DifficultyManager(this)

    this.levelDefs = new LevelDefs(this)
    this.levelId = this.loadLevelProgress()

    const nRespawn = this.game.registry.get('respawn')

    var colors = this.genColors(6)
    // console.log('colors.bg = \'' + colors.bg + '\'')
    // console.log('colors.bg2 = \'' + colors.bg2 + '\'')
    // console.log('colors.fg = \'' + colors.fg + '\'')
    // console.log('colors.sp = \'' + colors.sp + '\'')

    // SANTA
    if (this.game.registry.get('selectedAnimal') === SANTA) {
      colors.fg = 'eeeeff'
      colors.bg = '000011'
      colors.bg2 = '222299'
      colors.sp = 'eeeeff'// '01A43B'
    }

    // Generate a new color scheme every 5 levels:
    if ((this.levelId % 5 === 0 && nRespawn <= 0) || typeof this.game.registry.get('lastColorBG') === 'undefined') {
      this.game.registry.set('lastColorBG', colors.bg)
      this.game.registry.set('lastColorBG2', colors.bg2)
      this.game.registry.set('lastColorFG', colors.fg)
      this.game.registry.set('lastColorSp', colors.sp)
    }

    this.colorBG = '0x' + this.game.registry.get('lastColorBG')
    this.colorFG = '0x' + this.game.registry.get('lastColorFG')
    this.colorSpikes = '0x' + this.game.registry.get('lastColorSp')
    this.colorBG2 = '0x' + this.game.registry.get('lastColorBG2')

    // NB: this breaks DEBUG DRAWING and LOCALHOST-GUIDE DRAWING
    this.bg = this.add.graphics()
    this.bg.clear()
    this.bg.setTexture('white', 0)
    this.bg.fillGradientStyle(this.colorBG, this.colorBG, this.colorBG2, this.colorBG2, 1)
    this.bg.fillRect(-640, -360, 1280 * 1.05, 720 * 1.05)
    this.bg.setScrollFactor(0)

    this.collisionGroup1 = 1 // player, obstacles
    this.collisionGroup2 = 2 // level editor, handlers
    this.collisionGroup3 = 3 // tails

    this.collisionCategory1 = 4 // player
    this.collisionCategory2 = 8 // obstacles
    this.collisionCategory3 = 16 // editor, handlers
    this.collisionCategory4 = 32 // tails

    this.finished = false
    this.obstacles = []
    this.setupParticles()

    var cont = this.add.container(0, 0)
    const s = 1.5// 1.5
    const offY = -200
    const offX = -200 + (this.levelId % 5) * -50
    for (var i = 0; i < 10; i++) {
      for (var j = 0; j < 8; j++) {
        if (this.game.registry.get('selectedAnimal') === SANTA) {
          cont.add(this.add.image(i * 600 * s, j * 400 * s, 'santa_images', 'santa_bg2').setAlpha(0.1).setScale(s))
        } else {
          cont.add(this.add.image(i * 600 * s, j * 400 * s, 'images', 'bg2').setAlpha(0.1).setScale(s))
        }
      }
    }
    cont.setAngle(this.levelId % 5)
    cont.setPosition(offX, offY)
    cont.setScrollFactor(0.5, 1)

    this.isCustomLevel = false
    this.isLevelURL = false
    this.levelEditor = new LevelEditor(this)
    this.add.existing(this.levelEditor)

    var hideTitle = this.game.registry.get('hideTitle')
    var lastPlayerY = this.game.registry.get('lastPlayerY')
    this.game.registry.inc('nAttempts') // increases if in registry, otherwise sets it to 1

    this.addPlayer(-500, hideTitle, this.game.registry.get('selectedAnimal') || 0)

    this.spawner = new Spawner(this)
    this.cameraController = new CameraController(this, this.player)

    // snow particles
    if (this.game.registry.get('selectedAnimal') === SANTA) {
      this.snowParticles = this.add.particles('images', 'circle')
      this.snowParticles.setDepth(5)
      this.snowEmitter = this.snowParticles.createEmitter({
        tint: 0xEEEEFF,
        scale: 0.25,
        alpha: 0.75,
        lifespan: 6000,
        speedX: { min: -10, max: -60 },
        speedY: { min: 125, max: 200 },
        on: true,
        emitZone: {
          type: 'random',
          source: new Phaser.Geom.Rectangle(-800, -20, 3000, 5)
        }
      })
    }

    if (!this.game.registry.get('hideTitle')) {
      this.scene.run('TitleScene', { game: this, player: this.player })
    } else if (nRespawn > 0) {
      this.startLevel(this.game.registry.get('lastLevel'), nRespawn)
      if (nRespawn >= 3 && this.game.registry.get('poki')) {
        this.scene.run('SkipLevelScene', { game: this })
      }
    } else {
      this.startLevel(this.levelId, 0)
    }

    this.barTop = this.add.image(360, -300, 'white')
    this.barTop.setTint(this.colorFG)
    this.barTop.setScrollFactor(0)
    this.barTop.setDisplaySize(this.scale.gameSize.width * 2, 600)
    this.barTop.setDepth(900)

    this.barTop = this.add.image(360, 1430, 'white')
    this.barTop.setTint(this.colorFG)
    this.barTop.setScrollFactor(0)
    this.barTop.setDisplaySize(this.scale.gameSize.width * 2, 1400)
    this.barTop.setDepth(900)

    this.touch = new TouchInput(this, this.player)
    this.add.existing(this.touch)

    this.landscape = window.innerWidth > window.innerHeight
    this.scale.on('resize', () => {
      const oldLS = this.landscape
      this.landscape = window.innerWidth > window.innerHeight
      if (this.landscape !== oldLS) {
        this.onResize()
      }
    })
    this.onResize()

    // slow framerate Testing
    this.keyTest = this.input.keyboard.addKey('q')
    this.keyTestWasDown = false
    this.sleeptime = 0
  }

  getBanner () {
    return this.scene.get('BannerAdScene')
  }

  addPlayer (y, hideTitle, index) {
    if (this.player) {
      this.player.destroy()
    }
    this.player = new Player(this, y, hideTitle, index)
    this.add.existing(this.player)
    this.player.setDepth(LAYER_PLAYER)
  }

  onResize () {
    if (this.landscape) {
      this.scale.setGameSize(1280, 720)
    } else {
      this.scale.setGameSize(720, 1280)
    }

    if (this.cameras.main) {
      if (this.landscape) {
        this.cameras.main.setZoom(1)
      } else {
        this.cameras.main.setZoom(0.5625)
      }

      // this.background.setPosition(this.scale.gameSize.width / 2, this.scale.gameSize.height / 2)
      // this.background.setDisplaySize(this.scale.gameSize.width * (this.landscape ? 1.05 : 2), this.scale.gameSize.height)

      this.bg.setPosition(this.scale.gameSize.width / 2, 360)
    }

    if (this.touch) {
      this.touch.onResize(this.landscape)
    }
  }

  sleep (seconds) {
    // watch out, we REALLY dont want this in game!!
    var e = new Date().getTime() + (seconds * 1000)
    while (new Date().getTime() <= e) {}
  }

  startLevel (id, respawn = 0) {
    if (id[0] === 'r') {
      this.difficultyManager.setRating(id.slice(1))
    }

    // QQQ
    id = 'RATED'

    if (!isNaN(parseInt(id))) {
      this.isCustomLevel = false
      this.levelId = parseInt(id)
      this.spawner.setLevel(this.levelDefs.levels[this.levelId], respawn)
      if (this.game.registry.get('analytics')) {
        try {
          gameanalytics.GameAnalytics.addProgressionEvent(gameanalytics.EGAProgressionStatus.Start, 'level_' + this.levelIdString())
        } catch (err) { }
      }
    } else if (id === 'NEW') {
      this.isCustomLevel = true
      this.spawner.setLevelEmpty()
    } else if (id === 'RATED') {
      if (this.game.registry.get('analytics')) {
        try {
          gameanalytics.GameAnalytics.addProgressionEvent(gameanalytics.EGAProgressionStatus.Start, 'level_' + this.levelIdString())
        } catch (err) { }
      }
      // this.isCustomLevel = true
      this.spawner.spawnRatedLevel(respawn)
    } else {
      this.isCustomLevel = true
      this.spawner.setLevelFromJSONString(id)
    }

    if (!this.player.hasStarted) {
      if (this.game.registry.get('poki')) {
        PokiSDK.commercialBreak().then(
          () => {
            PokiSDK.gameplayStart()
            this.start()
          }
        )
      } else {
        this.start()
      }
    }
  }

  enableGrayscale () {
    // this.cameras.main.clearRenderToTexture()
    // this.cameras.main.setRenderToTexture(this.grayscalePipeline)
  }

  disableGrayscale () {
    // this.cameras.main.clearRenderToTexture()
  }

  start () {
    this.player.setupInput()
    this.levelEditor.setupInput()
    this.player.hasStarted = true
    this.startHUD()

    if (this.game.registry.get('selectedAnimal') === SANTA) {
      this.snowEmitter.setEmitZone({
        type: 'random',
        source: new Phaser.Geom.Rectangle(-800, -20, this.spawner.finishX + 2000, 5)
      })
    }
  }

  setupParticles () {
    this.particles = this.add.particles('images', 'circle')
    this.particles.setDepth(LAYER_PARTICLES)
  }

  startHUD () {
    this.scene.run('HudScene', { game: this, player: this.player })
    this.scene.run('NonEditorHudScene', { game: this })
  }

  startEditorHUD () {
    this.scene.run('EditorHudScene', { game: this, player: this.player })
    this.scene.sleep('HudScene')
  }

  stopEditorHud () {
    this.scene.stop('EditorHudScene')
    this.scene.wake('HudScene')
  }

  hideEditorHud () {
    this.scene.sleep('EditorHudScene')
    this.scene.wake('NonEditorHudScene')
  }

  unhideEditorHud () {
    this.scene.wake('EditorHudScene')
  }

  update (time, delta) {
    this.cameraController.update(time, delta)
    this.spawner.update(time, delta)

    // if the framerate is not doing well, adjust moving obstacle speeds
    this.tweens.timeScale = 16.66 / delta

    if (this.restartTimer > 0) {
      this.restartTimer -= delta / 1000
      if (this.restartTimer <= 0) {
        this.restart()
      }
    }

    if (this.keyTestWasDown && !this.keyTest.isDown) {
      this.sleeptime += 0.01
    }
    this.keyTestWasDown = this.keyTest.isDown

    if (this.sleeptime > 0 && location.hostname === 'localhost') {
      this.sleep(this.sleeptime)
    }
  }

  gameover (hideTitle = true) {
    this.difficultyManager.onLevelFailed(100)

    this.scene.stop('SkipLevelScene')
    this.player.isGameOver = true
    this.restartTimer = 1
    this.game.registry.set('hideTitle', hideTitle)
    if (this.isCustomLevel || this.isLevelURL) {
      this.game.registry.set('lastLevel', this.levelEditor.save())
    } else {
      this.game.registry.set('lastLevel', this.levelId)
    }
    this.game.registry.inc('respawn')
    if (!hideTitle) {
      if (this.isLevelURL) {
        // this.game.registry.set('ignoreURL', true)
      }
    }
    if (this.game.registry.get('poki')) {
      PokiSDK.gameplayStop()
    }

    if (this.game.registry.get('analytics')) {
      try {
        gameanalytics.GameAnalytics.addProgressionEvent(gameanalytics.EGAProgressionStatus.Fail, 'level_' + this.levelIdString())
      } catch (err) { }
    }
  }

  skipLevel () {
    this.scene.stop('SkipLevelScene')
    PokiSDK.gameplayStop()
    this.player.pause()
    PokiSDK.rewardedBreak().then(
      (success) => {
        this.player.unpause()
        if (success) {
          this.difficultyManager.increaseRating(-300)
          this.player.teleportToFinish()
        } else {
          this.player.unpause()
        }
        PokiSDK.gameplayStart()
      }
    )
  }

  finish () {
    if (!this.finished) {
      // console.log('finished with ' + this.game.registry.get('respawn') + ' respawns')
      this.difficultyManager.onLevelSuccess(this.game.registry.get('respawn') || 0)

      this.scene.stop('SkipLevelScene')
      this.finished = true
      this.restartTimer = 3
      if (!this.isCustomLevel) {
        if (this.game.registry.get('analytics')) {
          try {
            gameanalytics.GameAnalytics.addProgressionEvent(gameanalytics.EGAProgressionStatus.Complete, 'level_' + this.levelIdString())
          } catch (err) { }
        }
        var unlockedAnimal = TitleScene.hasJustUnlocked(this.levelId)
        if (unlockedAnimal > 0) {
          this.restartTimer = 4.5
          this.game.registry.set('selectedAnimal', unlockedAnimal)
        }
        this.levelId++
        this.game.registry.set('nTries', 0)
        this.saveLevelProgress(this.levelId)
        this.game.registry.set('hideTitle', false)
        this.game.registry.set('respawn', 0)
      } else if (this.isLevelURL) {
        // this.game.registry.set('ignoreURL', true)
      } else {
        this.game.registry.set('hideTitle', true)
        this.game.registry.inc('respawn')
        this.game.registry.set('lastLevel', this.levelEditor.save())
      }

      if (this.game.registry.get('poki')) {
        PokiSDK.gameplayStop()
        this.getBanner().displayAd()
      }
    }
  }

  restart (setY = true) {
    this.scene.stop('HudScene')
    this.scene.stop('NonEditorHudScene')
    this.scene.stop('EditorHudScene')
    this.scene.stop('SkipLevelScene')
    this.scene.restart('GameScene')
    if (setY) { this.game.registry.set('lastPlayerY', this.player.image.y) }
  }

  saveLevelProgress (levelId) {
    try { window.localStorage.setItem('levelId', this.levelId) } catch (e) { this.game.registry.set('levelIdBackup', levelId) }
    // this.difficultyManager.saveRating()
  }

  loadLevelProgress () {
    try {
      return parseInt(window.localStorage.getItem('levelId')) || 1
    } catch (e) { return this.game.registry.get('levelIdBackup') || 1 }
  }

  levelIdString () {
    if (this.levelId < 10) {
      return '0' + this.levelId
    }
    return this.levelId
  }

  genColors (len) {
    // Randomly generate background color, making sure it is not too dark:
    var r = 100 + Math.random() * 156
    var g = 100 + Math.random() * 156
    var b = 100 + Math.random() * 156

    // desaturate
    if (r > b && r > g) {
      b = Phaser.Math.Linear(b, r, 0.75)
      g = Phaser.Math.Linear(g, r, 0.75)
    } else if (g > r && g > b) {
      r = Phaser.Math.Linear(r, g, 0.75)
      b = Phaser.Math.Linear(b, g, 0.75)
    } else if (b > r && b > g) {
      r = Phaser.Math.Linear(r, b, 0.75)
      g = Phaser.Math.Linear(g, b, 0.75)
    }
    var bg = this.toColorString(r) + this.toColorString(g) + this.toColorString(b)

    // Calculate gradient color and foreground color:
    var bg2 = this.toColorString(r * 0.67) + this.toColorString(g * 0.67) + this.toColorString(b * 0.67)
    var fg = this.toColorString(r * 0.5) + this.toColorString(g * 0.5) + this.toColorString(b * 0.5)

    // Spikes are colored "opposite" to the background:
    if (r > g && r > b) {
      // console.log('Mostly red, make spikes yellow-green')
      r = 100 + Math.random() * 156
      g = 200 + Math.random() * 56
      b = 0 + Math.random() * 56
    } else if (b > r && b > g) {
      // console.log('Mostly blue, make spikes green or red')
      r = 0 + Math.random() * 256
      g = (256 - r) + Math.random() * r
      b = 0 + Math.random() * 100
    } else if (g > r && g > b) {
      // console.log('Mostly green, make spikes white-blue')
      r = 128 + Math.random() * 128
      g = 128 + Math.random() * 128
      b = Math.max(r, g) + Math.random() * (256 - Math.max(r, g))
    }

    // desaturate
    if (r > b && r > g) {
      b = Phaser.Math.Linear(b, r, 0.5)
      g = Phaser.Math.Linear(g, r, 0.5)
    } else if (g > r && g > b) {
      r = Phaser.Math.Linear(r, g, 0.5)
      b = Phaser.Math.Linear(b, g, 0.5)
    } else if (b > r && b > g) {
      r = Phaser.Math.Linear(r, b, 0.5)
      g = Phaser.Math.Linear(g, b, 0.5)
    }

    var sp = this.toColorString(r) + this.toColorString(g) + this.toColorString(b)

    return { bg, bg2, fg, sp }
  }

  toColorString (color) {
    var a = Math.floor(color / 16)
    var b = Math.floor(color % 16)
    var str = ''
    str += a.toString(16)
    str += b.toString(16)
    return str
  }
}
