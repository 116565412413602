import Phaser from 'phaser'
import GuideHandler from './guideHandler'

export default class Guide extends Phaser.GameObjects.Container {
  constructor (scene) {
    super(scene)

    this.handlers = []

    this.scene = scene
    this.graphics = this.scene.add.graphics()

    this.graphics.clear()
    this.graphics.lineStyle(100, 0xffffff, 1)
    this.graphics.setDepth(2)
  }

  refresh (offset = 0) {
    if (this.handlers.length >= 2) {
      this.graphics.clear()
      this.graphics.lineStyle(100, 0xffffff, 1)
      this.graphics.setDepth(2)
      for (var i = 1; i < this.handlers.length; i++) {
        this.addCurve(this.handlers[i - 1].getX() - offset, this.handlers[i].getX() - offset)
      }
    }
  }

  addCurve (x0, x1) {
    const s = new Phaser.Math.Vector2(x0, 620)
    const c1 = new Phaser.Math.Vector2(x0 + 100, -50)
    const c2 = new Phaser.Math.Vector2(x1 - 100, -50)
    const e = new Phaser.Math.Vector2(x1, 620)

    const curve = new Phaser.Curves.CubicBezier(s, c1, c2, e)
    curve.draw(this.graphics)
  }

  getHeightAndTangentAtX (x) {
    var x0 = 0
    var x1 = 0
    for (var i = 1; i < this.handlers.length; i++) {
      if (this.handlers[i - 1].getX() < x && this.handlers[i].getX() >= x) {
        x0 = this.handlers[i - 1].getX()
        x1 = this.handlers[i].getX()
        break
      }
    }

    const s = new Phaser.Math.Vector2(x0, 620)
    const c1 = new Phaser.Math.Vector2(x0 + 100, -50)
    const c2 = new Phaser.Math.Vector2(x1 - 100, -50)
    const e = new Phaser.Math.Vector2(x1, 620)

    const curve = new Phaser.Curves.CubicBezier(s, c1, c2, e)

    var t = (x - x0) / (x1 - x0)
    var out = curve.getPoint(t)
    var tan = curve.getTangent(t)

    return [out.y, tan]
  }

  preUpdate (time, delta) {
  }

  getX () {
  }

  getY () {
  }

  setX (x) {
  }

  setY (y) {
  }

  toDef () {
    var data = []
    for (var i = 0; i < this.handlers.length; i++) {
      data.push(Math.floor(this.handlers[i].getX()))
    }
    return { x: 0, t: 't', c: data }
  }

  delete () {
    // cannot delete Guide
  }

  addHandler (handler) {
    this.handlers.push(handler)
    this.refresh()
  }

  insertHandler (x) {
    var index = this.handlers.length
    if (x < this.handlers[0].getX()) {
      index = 0
    } else {
      for (var i = 1; i < this.handlers.length; i++) {
        if (x >= this.handlers[i - 1].getX() && x < this.handlers[i].getX()) {
          index = i
          break
        }
      }
    }

    var temp = new GuideHandler(x, 350, this, this.scene, index)
    this.scene.add.existing(temp)
    this.handlers.splice(index, 0, temp)
    this.refresh()
  }

  deleteHandler (index) {
    this.handlers.splice(index, 1)
    this.refresh()
  }
}
