// import Ground from '../actors/ground'
// import Gap from '../actors/gap'
// import Obstacle from '../actors/obstacle'
// import MovingObstacle from '../actors/movingObstacle'
// import Finish from '../actors/finish'
// import TopObstacle from '../actors/topObstacle'
import Phaser from 'phaser'
import LevelDefs from './levelDefs'
// import Level from '../components/level'
// import GapHandler from '../actors/gapHandler'
// import Guide from '../actors/guide'
// import MovingObstacleHandler from '../actors/movingObstacleHandler'
// import GuideHandler from '../actors/guideHandler'

// const ABS_MAX_HOPSIZE = 1200 // 1200 is only doable if the previous hop was also really wide
const MAX_HOPSIZE = 1200 // so, normally, the max hopSize is 1100
const MIN_HOPSIZE = 300
const AVG_HOPSIZE = MIN_HOPSIZE + (MAX_HOPSIZE - MIN_HOPSIZE) / 2 // 750
const EASY_HOPSIZE = 850
const MIN_RATING = 50
const MAX_RATING = 2500
const MIN_GAPSIZE = 200
const MIN_GAP_EDGE = 25
const GUIDE_WIDTH = 175

const SHAPE_LINE = 0
const SHAPE_TRIANGLE = 1
const SHAPE_SLOPEDOWN = 2
const SHAPE_SLOPEUP = 3
// const SHAPE_TOP = 4

const MIN_OBSTACLE_DIST = 110
const MIN_MOVER_DIST = 130 // TODO Do something with this..
const EST_HOP_DURATION = 1363
const EST_FALL_DURATION = 960

export default class LevelGenerator {
  constructor (scene) {
    this.scene = scene
    this.player = scene.player
  }

  generateLevelWithRating (rating, seed) {
    this.relativeRating = (rating - MIN_RATING) / (MAX_RATING - MIN_RATING)

    // console.log('>>>>>>>>>>> Generating level with rating: ' + this.relativeRating + ' and seed: ' + seed)
    // console.log('(relativeRating = ' + this.relativeRating + ')')

    // Use provided seed for RNG:
    this.rnd = Phaser.Math.RND
    this.rnd.sow(seed + rating)

    // 1. generate the guide.
    // 1a. determine number of Hops:
    this.nHops = 1
    var rStep = 0.04
    for (var rR = 0.04; rR < 1; rR += rStep) {
      if (this.relativeRating > rR) {
        if (this.rnd.frac() < 0.75) {
          this.nHops++
        }
      }
      rStep += 0.01
    }

    this.guideData = [this.nHops + 1]

    // 1b. determine hopSizes:
    var hopSizeLimitMin = AVG_HOPSIZE - (this.relativeRating * (AVG_HOPSIZE - MIN_HOPSIZE))
    var hopSizeLimitMax = 0 // This is set later

    var lastX = 500
    var lastHopSize = lastX

    var maxHopSizeVarianceUp = 0 // This is set later
    var maxHopSizeVarianceDown = this.relativeRating * AVG_HOPSIZE * 1.5 // allow abrupt stops
    this.guideData[0] = lastX
    for (var i = 1; i < this.nHops + 1; i++) {
      hopSizeLimitMax = AVG_HOPSIZE + (this.relativeRating * (AVG_HOPSIZE - MIN_HOPSIZE))
      maxHopSizeVarianceUp = this.relativeRating * AVG_HOPSIZE * (1 - (lastHopSize / MAX_HOPSIZE)) // Give some space to accelerate

      var maxHopSize = Math.min(lastHopSize + maxHopSizeVarianceUp, hopSizeLimitMax)
      var minHopSize = Math.max(lastHopSize - maxHopSizeVarianceDown, hopSizeLimitMin)
      if (i === 1) {
        maxHopSize = Math.min(maxHopSize, 900)
      }

      lastHopSize = this.rnd.between(minHopSize, maxHopSize)

      // Early levels should have easy hopSizes (which is different than the average hopSize)
      if (this.relativeRating <= 0.30) {
        lastHopSize = Phaser.Math.Linear(EASY_HOPSIZE, lastHopSize, this.relativeRating / 0.30)
      }
      lastX = lastX + lastHopSize
      this.guideData[i] = lastX
    }

    this.scene.spawner.spawnGuide(this.guideData)

    // 2. Determine hop types and difficulty:
    this.hopTypes = this.generateHopTypes(this.relativeRating, this.nHops)

    // 3. Spawn filling (obstacles, topObstacles, movers and gaps):
    for (var hopIndex = 0; hopIndex < this.nHops; hopIndex++) {
      var hopSize = this.guideData[hopIndex + 1] - this.guideData[hopIndex]
      var relativeHopSize = (hopSize - MIN_HOPSIZE) / (MAX_HOPSIZE - MIN_HOPSIZE)

      // 1. Should this hop have an obstacle, gap or moving obstacle (or a combination?), or nothing (top obstacle)?
      var hasObstacle = this.hopTypes[hopIndex].hasObstacle
      var hasGap = this.hopTypes[hopIndex].hasGap
      var hasMovingObstacle = this.hopTypes[hopIndex].hasMovingObstacle
      var hasTopObstacle = this.hopTypes[hopIndex].hasTopObstacle
      var gapStart = (1 - this.relativeRating) * MIN_OBSTACLE_DIST
      var maxGapSize = hopSize - (2 * (gapStart + MIN_GAP_EDGE))

      var maxObstacles = 1
      var nObstacles = 1
      var xAlongHop = 0
      var obstacleX = 0
      var obstacleY = 0

      var shape = 0
      var xPositions = null
      var minStartPerc = 0
      var maxStartPerc = 0
      var startPerc = 0
      var yDecrease = 0

      // For some hops, decrease the height of all obstacles by a random, but significant amount
      if (this.relativeRating < 0.7) {
        yDecrease = this.rnd.frac() < 0.5 ? this.rnd.between(50, 150) : 0
      } else if (this.relativeRating < 0.90) {
        yDecrease = this.rnd.frac() < 0.25 ? this.rnd.between(50, 150) : 0
      } else {
        yDecrease = 0
      }

      //  OOOOOO  OOOOOO   OOOOO
      // OO    OO OO   OO OO
      // OO    OO OOOOOO   OOOOO
      // OO    OO OO   OO      OO
      //  OOOOOO  OOOOOO  OOOOOO

      if (hasObstacle) {
        // 1. Determine min/max/actual distFromStartPerc
        // Based on:
        // - rating ()
        // - has Previous a TopObs?
        // - is previous a short hop?
        // Limit maxObstacles based on difficulty
        minStartPerc = 0
        maxStartPerc = 1
        startPerc = 0.5
        var endPerc = 0.5

        if (this.relativeRating < 0.16) {
          // LEVELS 1 - 7:
          // - maxObstacles: 1
          // - centered: always
          minStartPerc = 0.5
          maxStartPerc = 0.5
          startPerc = this.rnd.realInRange(minStartPerc, maxStartPerc)
          endPerc = 1 - startPerc
          nObstacles = 1 + Math.floor(((endPerc - startPerc) * hopSize) / MIN_OBSTACLE_DIST)
          xPositions = this.generateCenteredPositions(startPerc, nObstacles)
          shape = SHAPE_LINE
        } else if (this.relativeRating < 0.21) {
          // LEVELS 8, 9
          // - maxObstacles: 2
          // - distance between obstacles: very close
          // - centered: always
          // - shapes: LINE
          minStartPerc = 0.5 - (0.75 * (MIN_OBSTACLE_DIST / hopSize))
          minStartPerc = Math.max(minStartPerc, (MIN_OBSTACLE_DIST / hopSize))
          maxStartPerc = 0.5

          startPerc = this.rnd.realInRange(minStartPerc, maxStartPerc)
          endPerc = 1 - startPerc
          nObstacles = 1 + Math.floor(((endPerc - startPerc) * hopSize) / MIN_OBSTACLE_DIST)
          xPositions = this.generateCenteredPositions(startPerc, nObstacles)
          shape = SHAPE_LINE
        } else if (this.relativeRating < 0.28) {
          // LEVELS 10, 11, 12
          // - maxObstacles: 3
          // - dist: close
          // - centered: always
          // - shapes: LINE if 2, TRIANGLE if 3
          minStartPerc = 0.5 - (1.25 * (MIN_OBSTACLE_DIST / hopSize))
          maxStartPerc = 0.5
          startPerc = this.rnd.realInRange(minStartPerc, maxStartPerc)
          endPerc = 1 - startPerc
          nObstacles = 1 + Math.floor(((endPerc - startPerc) * hopSize) / MIN_OBSTACLE_DIST)
          xPositions = this.generateCenteredPositions(startPerc, nObstacles)
          shape = nObstacles === 3 ? SHAPE_TRIANGLE : SHAPE_LINE
        } else if (this.relativeRating < 0.35) {
          // LEVELS 13, 14, 15, 16
          // - maxObstacles: 4
          // - dist: close
          // - centered: always
          // - shapes: LINE if 2 or 4, TRIANGLE if 3
          minStartPerc = 0.5 - (1.75 * (MIN_OBSTACLE_DIST / hopSize))
          maxStartPerc = 0.5
          startPerc = this.rnd.realInRange(minStartPerc, maxStartPerc)
          endPerc = 1 - startPerc
          nObstacles = 1 + Math.floor(((endPerc - startPerc) * hopSize) / MIN_OBSTACLE_DIST)
          xPositions = this.generateCenteredPositions(startPerc, nObstacles)
          shape = nObstacles === 3 ? SHAPE_TRIANGLE : SHAPE_LINE
        } else if (this.relativeRating < 0.5) {
          // TODO FROM HERE--------------------------
          // LEVELS 17, 18, 19, 20, 21, 22
          // - maxObstacles: 4
          // - dist: close
          // - centered: always?
          // - shapes: LINE, TRIANGLE, SLOPEDOWN
          minStartPerc = 0.5 - (1.75 * (MIN_OBSTACLE_DIST / hopSize))
          maxStartPerc = 0.5
          startPerc = this.rnd.realInRange(minStartPerc, maxStartPerc)
          endPerc = 1 - startPerc
          nObstacles = 1 + Math.floor(((endPerc - startPerc) * hopSize) / MIN_OBSTACLE_DIST)
          xPositions = this.generateCenteredPositions(startPerc, nObstacles)
          shape = SHAPE_LINE
        } else if (this.relativeRating < 0.63) {
          // LEVELS 23, 24, 25, 26, 27, 28
          // - maxObstacles: 5
          // - dist: starts to get bigger
          // - centered: always?
          // - shapes: ALL
          minStartPerc = 0.5 - (1.75 * (MIN_OBSTACLE_DIST / hopSize))
          maxStartPerc = 0.5
          startPerc = this.rnd.realInRange(minStartPerc, maxStartPerc)
          endPerc = 1 - startPerc
          nObstacles = 1 + Math.floor(((endPerc - startPerc) * hopSize) / MIN_OBSTACLE_DIST)
          xPositions = this.generateCenteredPositions(startPerc, nObstacles)
          shape = SHAPE_LINE
        } else if (this.relativeRating < 0.76) {
          // LEVELS 29, 30, 31, 32, 33, 34
          // - maxObstacles: MAX
          // - dist: bigger
          // - centered: preferable
          // - shapes: ALL
          minStartPerc = 0.5 - (1.75 * (MIN_OBSTACLE_DIST / hopSize))
          maxStartPerc = 0.5
          startPerc = this.rnd.realInRange(minStartPerc, maxStartPerc)
          endPerc = 1 - startPerc
          nObstacles = 1 + Math.floor(((endPerc - startPerc) * hopSize) / MIN_OBSTACLE_DIST)
          xPositions = this.generateCenteredPositions(startPerc, nObstacles)
          shape = SHAPE_LINE
        } else if (this.relativeRating < 0.89) {
          // LEVELS 35, 36, 37, 38, 39, 40
          // - maxObstacles: MAX
          // - dist: big?
          // - centered: not necessarily
          // - shapes: ALL
          minStartPerc = 0.5 - (1.75 * (MIN_OBSTACLE_DIST / hopSize))
          maxStartPerc = 0.5
          startPerc = this.rnd.realInRange(minStartPerc, maxStartPerc)
          endPerc = 1 - startPerc
          nObstacles = 1 + Math.floor(((endPerc - startPerc) * hopSize) / MIN_OBSTACLE_DIST)
          xPositions = this.generateCenteredPositions(startPerc, nObstacles)
          shape = SHAPE_LINE
        } else {
          // LEVELS 41, 42, 43, 44, 45
          // - maxObstacles: MAX
          // - dist: big?
          // - centered: preferably not
          // - shapes: ALL
          // debugger
          shape = this.rnd.between(SHAPE_LINE, SHAPE_SLOPEUP)
          minStartPerc = (MIN_OBSTACLE_DIST / hopSize)
          if (hopSize > MAX_HOPSIZE) {
            minStartPerc *= 2
          } else if (hopSize > MAX_HOPSIZE - 100) {
            minStartPerc *= 1.5
          }
          // console.log('shape: ' + shape)
          maxStartPerc = 2 * minStartPerc
          startPerc = this.rnd.realInRange(minStartPerc, maxStartPerc)
          endPerc = 1 - startPerc
          nObstacles = 1 + Math.floor(((endPerc - startPerc) * hopSize) / MIN_OBSTACLE_DIST)
          // if (hopSize > MAX_HOPSIZE - 100) {
          // nObstacles -= 1
          xPositions = this.generateCenteredPositions(startPerc, nObstacles)
          // } else {
          // xPositions = this.generateNonCenteredPositions(startPerc, nObstacles, hopSize, MIN_OBSTACLE_DIST)
          // }
        }

        // 2. Determine maxObstacles
        maxObstacles = 1 + Math.floor(((1 - (2 * minStartPerc)) * hopSize) / MIN_OBSTACLE_DIST)

        var lineHeight = -10
        var slopeStart = -4
        var slopeEnd = 100

        // Spawn a gap if there is space
        // TODO: don't always do this, based on difficulty
        // (and also/instead) make the gapsize based on difficulty..
        if (xPositions[0] * hopSize >= (MIN_GAPSIZE + MIN_OBSTACLE_DIST) || xPositions[nObstacles - 1] * hopSize <= (MIN_GAPSIZE + MIN_OBSTACLE_DIST)) {
          var placeGapLeft = true
          if (xPositions[0] < 1 - xPositions[nObstacles - 1]) {
            placeGapLeft = false
          }

          var potentialGapSize = placeGapLeft ? xPositions[0] : 1 - xPositions[nObstacles - 1]
          if (potentialGapSize * hopSize >= (MIN_GAPSIZE + MIN_OBSTACLE_DIST)) {
            // TODO: tweak the second number?:
            if (this.relativeRating >= 0.24 && this.rnd.frac() < 0.5) {
              hasGap = true
              maxGapSize = (potentialGapSize * hopSize) - MIN_OBSTACLE_DIST - MIN_GAP_EDGE
              gapStart = placeGapLeft ? MIN_GAP_EDGE : (xPositions[nObstacles - 1] * hopSize) + MIN_GAP_EDGE
            }
          }
        }

        // Prepare for slopeUp and slopeDown shapes:
        if (shape === SHAPE_SLOPEUP || shape === SHAPE_SLOPEDOWN) {
          var lastAlongHop = xPositions[nObstacles - 1]
          var lastObstacleX = Phaser.Math.Linear(this.guideData[hopIndex], this.guideData[hopIndex + 1], lastAlongHop)
          slopeEnd = this.getObstacleY(lastObstacleX, lastAlongHop, relativeHopSize, this.relativeRating)
          if (nObstacles > 1) {
            slopeStart = this.rnd.between(slopeStart, slopeEnd * (1 - (0.2 * (nObstacles - 1))))
          }
        }

        // Spawn Obstacles:
        for (var obstacleId = 0; obstacleId < nObstacles; obstacleId++) {
          xAlongHop = xPositions[obstacleId]
          obstacleX = Phaser.Math.Linear(this.guideData[hopIndex], this.guideData[hopIndex + 1], xAlongHop)

          obstacleY = this.getObstacleY(obstacleX, xAlongHop, relativeHopSize, this.relativeRating)

          if (shape === SHAPE_SLOPEUP && nObstacles > 1) {
            obstacleY = slopeStart + (obstacleId * ((slopeEnd - slopeStart) / (nObstacles - 1)))
          }

          // only spawn if the obstacle is above ground
          if (obstacleY >= -4) {
            // decrease all obstacle heights in this hop by the same amount
            obstacleY = Math.max(-4, obstacleY - yDecrease)

            // in case of SHAPE_LINE, make all obstacles the same height:
            if (shape === SHAPE_LINE || shape === SHAPE_SLOPEDOWN) {
              // the first obstacle determines the lineHeight
              if (lineHeight < -4) {
                lineHeight = obstacleY
                slopeEnd = Math.max(Math.min(slopeEnd, this.rnd.between(0, lineHeight * (1 - (0.2 * (nObstacles - 1))))), -4)
              }
              obstacleY = Math.min(obstacleY, lineHeight)
              if (shape === SHAPE_SLOPEDOWN && obstacleId > 0) {
                obstacleY = lineHeight - (obstacleId * ((lineHeight - slopeEnd) / (nObstacles - 1)))
              }
            }

            // Sometimes omit an obstacle, to keep things fresh:
            var dontSpawn = false
            if (nObstacles > 2) {
              if (this.rnd.frac() < 0.35) {
                dontSpawn = true
              }
            }

            // FINALLY, SPAWN!
            if (!dontSpawn) {
              this.lastSpawnedObstacle = this.scene.spawner.spawnObstacle(obstacleX, obstacleY)
            }
          }
        }
      }

      // TTTTTTTT  TTTTTT  TTTTTT
      //    TT    TT    TT TT   TT
      //    TT    TT    TT TTTTTT
      //    TT    TT    TT TT
      //    TT     TTTTTT  TT

      if (hasTopObstacle) {
        var nextHopSize = this.guideData[hopIndex + 2] - this.guideData[hopIndex + 1]
        var nextRelativeHopSize = (nextHopSize - MIN_HOPSIZE) / (MAX_HOPSIZE - MIN_HOPSIZE)

        shape = this.rnd.between(SHAPE_LINE, SHAPE_SLOPEUP)

        if (hopSize < MIN_HOPSIZE * 1.2 || this.relativeRating < 0.32) {
          maxObstacles = 1
        } else if (shape === SHAPE_SLOPEDOWN || shape === SHAPE_SLOPEUP) {
          maxObstacles = 2
        } else {
          maxObstacles = 3
        }
        nObstacles = this.rnd.between(1, maxObstacles)

        // triangles with 2 obstacles should be regarded as lines, to make the code easier..
        if (shape === SHAPE_TRIANGLE && nObstacles === 2) {
          shape = SHAPE_LINE
        }

        xPositions = this.generateTopObstacleXpositions(shape, nObstacles, hopSize, nextHopSize)

        // Find out for LINE and TRIANGLE shapes, which of the (side-)obstacles is lowest:
        var lowestHeight = 40
        if (nObstacles > 1 && (shape === SHAPE_LINE || shape === SHAPE_TRIANGLE)) {
          var lowestHeightIndex = 0
          if (hopSize > nextHopSize) {
            lowestHeightIndex = nObstacles - 1
          }

          var lowestXAlongHop = xPositions[lowestHeightIndex]
          var lowestObstacleX
          if (lowestXAlongHop <= 1) {
            lowestObstacleX = Phaser.Math.Linear(this.guideData[hopIndex], this.guideData[hopIndex + 1], lowestXAlongHop)
            lowestHeight = this.getTopObstacleY(lowestObstacleX, relativeHopSize, this.relativeRating, hopIndex)
          } else {
            lowestObstacleX = Phaser.Math.Linear(this.guideData[hopIndex + 1], this.guideData[hopIndex + 2], lowestXAlongHop - 1)
            lowestHeight = this.getTopObstacleY(lowestObstacleX, nextRelativeHopSize, this.relativeRating, hopIndex + 1)
          }
        }

        // Spawn Obstacles:
        for (obstacleId = 0; obstacleId < nObstacles; obstacleId++) {
          xAlongHop = xPositions[obstacleId]
          if (xAlongHop <= 1) {
            obstacleX = Phaser.Math.Linear(this.guideData[hopIndex], this.guideData[hopIndex + 1], xAlongHop)
            obstacleY = this.getTopObstacleY(obstacleX, relativeHopSize, this.relativeRating, hopIndex)
          } else {
            obstacleX = Phaser.Math.Linear(this.guideData[hopIndex + 1], this.guideData[hopIndex + 2], xAlongHop - 1)
            obstacleY = this.getTopObstacleY(obstacleX, nextRelativeHopSize, this.relativeRating, hopIndex + 1)
          }

          // If SHAPE_LINE, make ALL obstacles the lowest height
          // If SHAPE_TRIANGLE, make only the left and the right one the lowest height
          if (nObstacles > 1 && ((shape === SHAPE_LINE) || (shape === SHAPE_TRIANGLE && nObstacles === 3 && (obstacleId === 0 || obstacleId === 2)))) {
            obstacleY = lowestHeight
          }

          this.lastSpawnedTopObstacle = this.scene.spawner.spawnTopObstacle(obstacleX, obstacleY)
        }
      }

      // MMM      MMM  MMMMMMM  MM       MM
      // MMMM    MMMM MM     MM  MM     MM
      // MM MM  MM MM MM     MM   MM   MM
      // MM  MMMM  MM MM     MM    MM MM
      // MM   MM   MM  MMMMMMM      MMM

      if (hasMovingObstacle) {
        var isOffsettedMover = this.rnd.frac() < this.relativeRating * 0.5
        isOffsettedMover = isOffsettedMover && hopSize > 350 && this.relativeRating > 0.4

        // NB: Triangle shape might actually be too difficult..
        shape = SHAPE_LINE// rnd.between(SHAPE_LINE, SHAPE_TRIANGLE)
        // if (rnd.frac() < relativeRating) {
        //   shape = SHAPE_TRIANGLE
        // }
        if (shape === SHAPE_LINE) {
          maxObstacles = Math.min(2, Math.floor((hopSize - MIN_MOVER_DIST) / MIN_MOVER_DIST))
        } else if (shape === SHAPE_TRIANGLE) {
          maxObstacles = Math.min(3, Math.floor((hopSize - MIN_MOVER_DIST) / MIN_MOVER_DIST))
        }

        nObstacles = this.rnd.between(1, Math.ceil(this.relativeRating * maxObstacles))

        if (isOffsettedMover) {
          nObstacles = maxObstacles
        }

        // Don't place too many movers if there's little space:
        if (hopSize < 500 && (hasTopObstacle || (this.lastSpawnedTopObstacle && this.lastSpawnedTopObstacle.getY() > 275))) {
          // console.log('Limiting nMovers to 1 for hopIndex ' + (hopIndex + 1))
          nObstacles = Math.min(nObstacles, 1)
        }

        minStartPerc = ((2 - this.relativeRating) * MIN_MOVER_DIST) / hopSize
        maxStartPerc = ((hopSize - ((nObstacles - 1) * MIN_MOVER_DIST)) / 2) / hopSize
        startPerc = this.rnd.realInRange(minStartPerc, maxStartPerc)
        if (this.relativeRating < 0.5) { // TODO: Tweak this value
          xPositions = this.generateCenteredPositions(startPerc, nObstacles)
        } else {
          xPositions = this.generateNonCenteredPositions(startPerc, nObstacles, hopSize, MIN_MOVER_DIST)
        }

        var linePhase = -10

        var hasSpawnedAMovingObstacle = false
        var hasSpawnedAnObstacle = false

        var replaceMoverIndex = -1
        if (nObstacles > 1 && this.rnd.frac() < 0.35) {
          replaceMoverIndex = this.rnd.between(0, nObstacles - 1)
        }

        var bottom = 25
        var range = 500

        if (isOffsettedMover) {
          bottom = this.rnd.between(25, 200)
          range = this.rnd.between(250, 525 - bottom)
        }

        for (obstacleId = 0; obstacleId < nObstacles; obstacleId++) {
          xAlongHop = xPositions[obstacleId]
          obstacleX = Phaser.Math.Linear(this.guideData[hopIndex], this.guideData[hopIndex + 1], xAlongHop)

          var guideInfo = this.scene.spawner.guide.getHeightAndTangentAtX(obstacleX)
          var guideHeight = guideInfo[0]

          var normalizedHeight = 1 - ((guideHeight - 117.5) / (620 - 117.5))
          obstacleY = normalizedHeight * 450 + (Math.abs(xAlongHop - 0.5) * 70)

          // Implemented difficulty by giving less leeway in obstacleY placement (200 is juuuust miss, 500 is the biggest leeway)
          var leeway = 500 - (this.relativeRating * 300)

          // Give some extra leeway if we have multiple movers
          if (nObstacles > 1) { // && shape === SHAPE_LINE) {
            leeway = Math.min(500, leeway + 200)
          }

          // instead of that Y, we want to the obstacle to be at the opposite place, so
          if (obstacleY < (bottom + range) / 2) {
          // If obstacleY is already pretty low, we need more leeway
            if (obstacleY < 150) {
              leeway *= 1.5
            }
            // console.log('adding ' + leeway + ' to ' + obstacleY)
            obstacleY = Math.min(obstacleY + leeway, bottom + range)
          } else {
          // console.log('subtracting 200 from ' + obstacleY)
            obstacleY = Math.max(obstacleY - leeway, bottom)
          }

          var percInTween = (obstacleY - bottom) / range
          var playerReachesAt = EST_FALL_DURATION + ((hopIndex + 1 + xAlongHop) * EST_HOP_DURATION)
          var phase = (playerReachesAt - (percInTween * 2000)) % 4000

          // in case of SHAPE_LINE, make all obstacles the same phase:
          if (shape === SHAPE_LINE) {
            // the first obstacle determines the phase
            if (obstacleId === 0) {
              linePhase = phase
            }
            phase = linePhase
          } else if (shape === SHAPE_TRIANGLE) {
            // Have "alternating" phases:
            if (obstacleId === 0) {
              linePhase = phase
            } else if (obstacleId === 1) {
              phase = linePhase - 500
            } else if (obstacleId === 2) {
              phase = linePhase
            }
          }

          if (obstacleId === replaceMoverIndex) {
            // console.log('Spawning Obstacle instead of Mover at x=' + obstacleX)
            this.scene.spawner.spawnObstacle(obstacleX, this.getObstacleY(obstacleX, xAlongHop, relativeHopSize, this.relativeRating) - yDecrease)
            hasSpawnedAnObstacle = true
            hasGap = false
          } else {
            // SPAWN, unless the player will see me waiting..
            if (phase < playerReachesAt - 2000) {
              hasSpawnedAMovingObstacle = true
              this.scene.spawner.spawnMovingObstacle(obstacleX, bottom, range, phase)
            } else if (this.nHops === 1) {
              // console.log('Adjusting Mover phase, because the player will see me waiting at x=' + obstacleX)
              phase = 0
              this.scene.spawner.spawnMovingObstacle(obstacleX, bottom, range, phase)
            } else {
              // console.log('Not spawning Mover because the player will see me waiting at x=' + obstacleX)
              if (!hasGap) {
                obstacleY = this.getObstacleY(obstacleX, xAlongHop, relativeHopSize, this.relativeRating)
                // because movers ares spawned later than topObstacles, we do not know the lastTopObstacle's correct position,
                // so just make it easy on the player:
                if (hasTopObstacle) {
                  obstacleY = Math.min(obstacleY, 175)
                }
                this.scene.spawner.spawnObstacle(obstacleX, obstacleY)
                hasSpawnedAnObstacle = true
              }
            }
          }
        }

        if (hasSpawnedAMovingObstacle) {
          // See if we can place a spike in the middle (only if more than one mover and distance in pixels between movers is > 240)
          // TODO, maybe: don't ALWAYS do this; only if rnd < some value (perhaps relativeRating)
          if (!isOffsettedMover && nObstacles > 1 && (xPositions[1] - xPositions[0]) * hopSize >= (MIN_OBSTACLE_DIST * 2) + 20) {
            var obsX = this.guideData[hopIndex] + hopSize * 0.5 * (xPositions[0] + xPositions[1])
            this.scene.spawner.spawnObstacle(obsX, this.getObstacleY(obsX, xAlongHop, relativeHopSize, this.relativeRating) - yDecrease)
            hasSpawnedAnObstacle = true
            hasGap = false
          }

          // Sometimes, have the movers be above a gap:
          // TODO: tweak these numbers:
          if (this.relativeRating > 0.5 && this.rnd.frac() < 0.5 && !hasSpawnedAnObstacle) {
            hasGap = true
          }
        } else {
          // If no Mover was spawned, due to the player seeing it wait, simply spawn a gap
          if (this.relativeRating > 0.5 && !hasSpawnedAnObstacle) {
            hasGap = true
          }
        }
      }

      //  GGGGGG   GGGGG   GGGGGG
      // GG       GG   GG  GG   GG
      // GG  GG   GGGGGGG  GGGGGG
      // GG   GG  GG   GG  GG
      //  GGGGG   GG   GG  GG

      if (hasGap && maxGapSize >= MIN_GAPSIZE) {
        // gap's size depends on difficulty. Sqrt the difficulty, so that we reach higher gapsizes quicker
        var gapSize = Phaser.Math.Linear(MIN_GAPSIZE, maxGapSize, Phaser.Math.Clamp(Math.sqrt(this.relativeRating), 0, 1))
        var startOffset = (maxGapSize - gapSize) / 2

        // console.log('maxGapSize: ' + maxGapSize + ', gapSize: ' + gapSize)
        this.scene.spawner.spawnGap(this.guideData[hopIndex] + MIN_GAP_EDGE + gapStart + startOffset, gapSize)
      }
    }

    // Last but not least, spawn the Finish:
    var def = { x: lastX + 500, t: 'f', c: {} }
    this.scene.spawner.spawnFromDefinition(def, true)
    this.scene.spawner.level.setObjectDef(def.x, def.t, def.c)

    // TEST: Draw and Analyze the generated level
    // this.scene.levelDefs.drawLevel('RATED ' + rating, this.scene.levelEditor.convertObjectsToDefs(this.scene.spawner.objects))
    // var difficulty = this.scene.levelDefs.analyzeLevel(this.scene.levelEditor.convertObjectsToDefs(this.scene.spawner.objects))
    // console.log('Difficulty is : ' + difficulty)// + ' (Should be around ' + ((rating / 2500) * 45 * 0.1667).toFixed(2) + ')')
  }

  getObstacleY (obstacleX, xAlongHop, relativeHopSize, relativeRating) {
    var guideInfo = this.scene.spawner.guide.getHeightAndTangentAtX(obstacleX)
    var guideHeight = guideInfo[0]

    // Based on difficulty, set max obstacle height
    var maxObstacleHeight = 320
    if (relativeRating <= 0.08) {
      maxObstacleHeight = relativeRating * 2200 // 50..175
    } else if (relativeRating <= 0.28) {
      maxObstacleHeight = 140 + (relativeRating * 500) // 180..280
    } else {
      maxObstacleHeight = 320 - ((1 - relativeRating) * 55) // 280..320
    }

    var obstacleY = LevelDefs.getMaxObstacleHeight(guideHeight, xAlongHop, relativeHopSize)
    // Apply difficulty
    obstacleY *= maxObstacleHeight / 320

    // if the hop is really wide, make it a bit easier on the player
    if (relativeHopSize > 0.9) {
      obstacleY = Math.max(obstacleY - 30, -4)
    }

    // Don't allow an obstacle to be too close to a previous high topObstacle
    if (this.lastSpawnedTopObstacle) {
      var obsX = this.lastSpawnedTopObstacle.getX()
      var obsY = this.lastSpawnedTopObstacle.getY()
      var xDist = obstacleX - obsX

      if (xDist > 0 && xDist < MIN_OBSTACLE_DIST * 2) {
        if (xDist < MIN_OBSTACLE_DIST / 2) {
          // console.log('(Re)moving obstacle at x=' + obstacleX)
          obstacleY -= 100
        }

        // Too close on the y-axis:
        if (obstacleY - obsY < 200) {
          var newObstacleY = Math.min(obstacleY, 580 - obsY - Math.max(0, 220 - xDist) - ((1 - this.relativeRating) * 150)) // Move me down by some margin
          if (newObstacleY !== obstacleY) {
            // console.log('Moving obstacle at xPos: ' + obstacleX + ' from yPos: ' + obstacleY + ' to: ' + newObstacleY)
            obstacleY = newObstacleY
          }
        }
      }
    }

    return obstacleY
  }

  getTopObstacleY (obstacleX, relativeHopSize, relativeRating, hopIndex) {
    var guideInfo = this.scene.spawner.guide.getHeightAndTangentAtX(obstacleX)
    var guideHeight = guideInfo[0]
    var guideTangent = guideInfo[1]

    // now move the obstacle around a bit so if fits on top of the guide:
    var obstacleY = LevelDefs.getMaxTopObstacleHeight(guideHeight, guideTangent.x, relativeHopSize)
    obstacleY += ((1 - relativeHopSize) * 50)

    obstacleY *= relativeRating

    // Don't allow too difficult obstacles when there's only little room to move
    if (relativeHopSize < 0.1 && hopIndex >= 1 && this.hopTypes[hopIndex - 1].hasTopObstacle) {
      // console.log('limiting top obstacle to 275, due to small gapsize')
      obstacleY = Math.min(obstacleY, 275)

      if (this.hopTypes[hopIndex].hasMovingObstacle) {
        // console.log('limiting top obstacle even more, due to the existence of a mover')
        obstacleY = Math.max(obstacleY - 100, 40)
      }
    }

    // Don't allow a topObstacle to be too close to a previous high obstacle
    if (this.lastSpawnedObstacle) {
      var obsX = this.lastSpawnedObstacle.getX()
      var obsY = 558 - this.lastSpawnedObstacle.getY()

      if (obstacleX - obsX < MIN_OBSTACLE_DIST * 2) {
        // Too close on the x-axis:
        if (obstacleX - obsX < MIN_OBSTACLE_DIST / 2 && (obstacleY > 200 || obsY > 300)) {
          // console.log('Removing topObstacle at xPos: ' + obstacleX)
          return -100 // Simply "don't spawn me"
        }

        // Too close on the y-axis:
        if (obstacleY - obsY < 200) {
          var xDist = obstacleX - obsX
          var yDist = this.lastSpawnedObstacle.getY() - obstacleY

          if (yDist >= 0) {
            var a2 = xDist * xDist
            var b2 = yDist * yDist
            var c = Math.sqrt(a2 + b2)

            var minDist = GUIDE_WIDTH + (GUIDE_WIDTH * (1 - this.relativeRating))
            if (c < minDist) {
              // console.log('Moving topObstacle at xPos: ' + obstacleX + ' from yPos: ' + obstacleY)
              var desiredC2 = minDist * minDist
              var desiredYDist = Math.sqrt(desiredC2 - a2)
              obstacleY -= (desiredYDist - yDist)
              // console.log('.. to yPos: ' + obstacleY + ', using minDist technique')
            }
          } else {
            // console.log('Moving topObstacle at xPos: ' + obstacleX + ' from yPos: ' + obstacleY)
            obstacleY = Math.min(obstacleY, 580 - obsY - Math.max(0, 220 - xDist) - ((1 - this.relativeRating) * 150)) // Move me up by some margin
            // console.log('.. to yPos: ' + obstacleY)
          }
        }
      }
    }

    // Always show the whole triangle
    obstacleY = Math.max(40, obstacleY)

    return obstacleY
  }

  generateTopObstacleXpositions (shape, numObstacles, hopSize, nextHopSize) {
    var pos = [numObstacles]
    if (numObstacles === 1) {
      pos[0] = 1
      return pos
    }

    var leftDistPerc = MIN_OBSTACLE_DIST / hopSize
    var rightDistPerc = MIN_OBSTACLE_DIST / nextHopSize

    if (shape === SHAPE_LINE || shape === SHAPE_TRIANGLE) {
      if (numObstacles === 3) {
        pos[0] = 1 - leftDistPerc
        pos[1] = 1
        pos[2] = 1 + rightDistPerc
      } else if (numObstacles === 2) {
        pos[0] = 1 - (leftDistPerc / 2)
        pos[1] = 1 + (rightDistPerc / 2)
      }
    } else if (shape === SHAPE_SLOPEUP) {
      pos[0] = 1
      pos[1] = 1 + rightDistPerc
    } else if (shape === SHAPE_SLOPEDOWN) {
      pos[0] = 1 - leftDistPerc
      pos[1] = 1
    }

    return pos
  }

  generateObstacleXpositionsByPerc (startPerc, nObstacles, minObstacleDist, hopSize) {
    var pos = [nObstacles]

    var percLeft = 1 - startPerc
    var stepSize = 1

    stepSize = (percLeft - startPerc) / (nObstacles - 1)
    for (var i = 0; i < nObstacles; i++) {
      pos[i] = startPerc + (i * stepSize)
    }
    return pos
  }

  // Returns 'nObstacles' x-positions, centered around 0.5, starting at 'startPerc'
  generateCenteredPositions (startPerc, nObstacles) {
    var pos = [nObstacles]

    if (nObstacles === 1) {
      pos[0] = 0.5
      return pos
    }

    var percLeft = 1 - startPerc
    var stepSize = (percLeft - startPerc) / (nObstacles - 1)

    for (var i = 0; i < nObstacles; i++) {
      pos[i] = startPerc + (i * stepSize)
    }
    return pos
  }

  // Returns 'nObstacles' x-positions, equally-distanced from eachother, starting at 'startPerc', but NOT (necessarily) centered around 0.5
  generateNonCenteredPositions (startPerc, nObstacles, hopSize, minObstacleDist) {
    var pos = [nObstacles]

    if (nObstacles === 1) {
      pos[0] = startPerc
      return pos
    }

    var percLeft = 1 - startPerc
    // var stepSize = (percLeft - startPerc) / (nObstacles - 1)

    var maxStepSize = percLeft / nObstacles
    var minStepSize = minObstacleDist / hopSize
    var stepSize = Phaser.Math.RND.realInRange(minStepSize, maxStepSize)

    for (var i = 0; i < nObstacles; i++) {
      pos[i] = startPerc + (i * stepSize)
    }
    return pos
  }

  generateObstacleXpositions (shape, numObstacles, hopSize, relativeRating, minObstacleDist = MIN_OBSTACLE_DIST) {
    var pos = [numObstacles]
    var maxStartPerc = 1
    var minStartPerc = ((2 - relativeRating) * minObstacleDist) / hopSize // TODO: maybe this is too strict? At least not in line with calculation for maxObstacles above..

    if (numObstacles === 1) {
      var x = Phaser.Math.RND.realInRange(minStartPerc, 1 - minStartPerc)
      pos[0] = x
      return pos
    }

    // TODO: if rR < 0.22, the obstacles are always centered

    switch (shape) {
      default:
      case SHAPE_LINE:
      case SHAPE_TRIANGLE:
        // Keep symmetry:
        maxStartPerc = ((hopSize - ((numObstacles - 1) * minObstacleDist)) / 2) / hopSize
        break
      case SHAPE_SLOPEDOWN:
      case SHAPE_SLOPEUP:
        // Symmetry is not needed:
        maxStartPerc = (hopSize - (numObstacles * minObstacleDist)) / hopSize
        break
    }

    if (maxStartPerc < minStartPerc) {
      // debugger
      // console.log('ERROR: TOO MANY OBSTACLES (' + numObstacles + ') FOR THIS HOP!!')
    }

    var startPerc = Phaser.Math.RND.realInRange(minStartPerc, maxStartPerc)
    var percLeft = 1 - startPerc
    var stepSize = 1

    switch (shape) {
      case SHAPE_TRIANGLE:
        // While keeping symmetry, don't always space obstacles out in equal parts (e.g. allow obstacles at 1/6th, 3/6th and 5/6th)
        stepSize = (percLeft - startPerc) / (numObstacles - 1)
        break
        // Keep objects evenyly spaced, but symmetry is not neccessary:
      default:
        // TODO: this calculation of maxStepSize is incorrect (at least for SHAPE_LINE), as it doesnt leave enough space at the end
        var maxStepSize = percLeft / numObstacles
        var minStepSize = minObstacleDist / hopSize
        stepSize = Phaser.Math.RND.realInRange(minStepSize, maxStepSize)
        break
    }

    for (var i = 0; i < numObstacles; i++) {
      pos[i] = startPerc + (i * stepSize)
    }
    return pos
  }

  // distributes nPies over nPeople
  generateRandomDistribution (nPies, nPeople) {
    var ret = [nPeople]
    var total = 0
    var r = 0

    // give each person a random amount of slices
    for (var i = 0; i < nPeople; i++) {
      r = Phaser.Math.RND.frac() * nPies
      total += r
      ret[i] = r
    }

    // now 'normalize' the slices
    // and don't give any hop an added difficulty of more than 0.5..
    for (i = 0; i < nPeople; i++) {
      ret[i] = Math.min(0.5, ret[i] / total)
    }

    return ret
  }

  generateHopTypes (relativeRating, nHops) {
    var ret = [nHops]
    var hasObstacle = false
    var hasTopObstacle = false
    var hasMovingObstacle = false
    var hasGap = false

    var nHopsWithTopObstacles = 0
    var nHopsWithMovingObstacles = 0
    var nHopsWithGaps = 0

    if (relativeRating < 0.045) {
      nHopsWithTopObstacles = 0
      nHopsWithMovingObstacles = 0
      nHopsWithGaps = 0
    } else if (relativeRating < 0.11) {
      nHopsWithTopObstacles = Phaser.Math.RND.between(0, 1)
      nHopsWithMovingObstacles = 0
      nHopsWithGaps = 1 - nHopsWithTopObstacles
    } else if (relativeRating < 0.15) {
      nHopsWithTopObstacles = 0
      nHopsWithMovingObstacles = nHops// Phaser.Math.RND.between(1, nHops)
      nHopsWithGaps = 0
    } else if (relativeRating < 0.23) {
      nHopsWithTopObstacles = 1
      nHopsWithMovingObstacles = 0
      nHopsWithGaps = Phaser.Math.RND.between(0, 1)
    } else if (relativeRating < 0.38) {
      nHopsWithTopObstacles = Phaser.Math.RND.between(0, 2)
      nHopsWithMovingObstacles = nHopsWithMovingObstacles === 0 ? 1 : 0
      nHopsWithGaps = Phaser.Math.RND.between(0, Math.min(nHops, 3))
    } else if (relativeRating < 0.75) {
      nHopsWithTopObstacles = Phaser.Math.RND.between(1, 3)
      nHopsWithMovingObstacles = Phaser.Math.RND.between(0, 3)
      nHopsWithGaps = Phaser.Math.RND.between(0, 3)
    } else { // if (relativeRating < )
      nHopsWithTopObstacles = Phaser.Math.RND.between(2, nHops)
      nHopsWithMovingObstacles = Phaser.Math.RND.between(0, Math.floor(nHops / 2))
      nHopsWithGaps = Phaser.Math.RND.between(0, 2)
    }

    var nTopHopsFilledIn = 0
    var nMovingHopsFilledIn = 0
    var startWithTopOrMovingIndex = this.rnd.between(0, Math.max(0, nHops - nHopsWithTopObstacles - nHopsWithMovingObstacles))

    var nextHopSize = 0
    var thisHopSize = 0
    for (var i = 0; i < nHops; i++) {
      thisHopSize = this.guideData[i + 1] - this.guideData[i]

      if (i < nHops - 1) {
        nextHopSize = this.guideData[i + 2] - this.guideData[i + 1]
      }

      // TODO: this has all the topObstacles being spawned at the start,
      // and the moving obstacles being spawned at the end.
      // Move that around a bit..

      if (i >= startWithTopOrMovingIndex && i < nHopsWithTopObstacles + nHopsWithMovingObstacles) {
        // this is either a top, moving or both
      }

      // hasTopObstacle = i < nHopsWithTopObstacles && i < nHops - 1 && (nextHopSize - thisHopSize) < 400
      // hasMovingObstacle = !hasTopObstacle && i < nHopsWithTopObstacles + nHopsWithMovingObstacles
      hasTopObstacle = Phaser.Math.RND.frac() < nHopsWithTopObstacles / nHops

      // Don't put top obstacles after extermely short hops, or at the end (?)
      hasTopObstacle = hasTopObstacle /* && i < nHops - 1 */ && (nextHopSize - thisHopSize) < (MIN_HOPSIZE * 1.3)

      hasMovingObstacle = Phaser.Math.RND.frac() < nHopsWithMovingObstacles / nHops

      hasGap = Phaser.Math.RND.frac() < nHopsWithGaps / nHops

      // Fill unfilled hops up with obstacles:
      hasObstacle = !hasMovingObstacle && !hasGap

      // Limit obstacle placement if there is a topObstacle following, based on difficulty:
      if (hasObstacle && hasTopObstacle) {
        if (relativeRating < 0.4) {
          hasObstacle = false
        } else if (relativeRating < 0.7) {
          hasObstacle = Phaser.Math.RND.frac() < 0.5
        }
      }

      // hasObstacle = true// relativeRating < 0.1 || Phaser.Math.RND.frac() < 0.65
      // hasMovingObstacle = false//! hasObstacle && relativeRating >= 0.1// && rnd.frac() < 0.75
      // hasGap = false// relativeRating >= 0.08 && !hasObstacle && !hasMovingObstacle && Phaser.Math.RND.frac() < 0.35 // if there is an obstacle, there might also be a gap, but we do that later
      // hasTopObstacle = false// i < nHops - 1 && (hasObstacle || hasGap ? Phaser.Math.RND.frac() < 0.25 : true)// rnd.frac() < 0.75)

      ret[i] = { hasObstacle, hasTopObstacle, hasMovingObstacle, hasGap }
      // console.log(ret[i])
    }

    return ret
  }
}
