import Phaser from 'phaser'

export default class Finish extends Phaser.GameObjects.Container {
  constructor (x, scene) {
    super(scene)

    this.image = this.scene.add.image(x, 342, 'images', 'finish')

    this.collider = this.scene.matter.add.image(x, 342, 'white')
    this.collider.setTint(0xFFFFFF)
    this.collider.setSize(100, 720)
    this.collider.setDisplaySize(100, 720)
    this.collider.setIgnoreGravity(true)
    this.collider.setStatic(true)
    this.collider.setSensor(true)
    this.collider.setVisible(false)
    this.collider.parentContainer = this
    this.collider.setCollisionGroup(this.scene.collisionGroup2)
    this.collider.setCollisionCategory(this.scene.collisionCategory3)
  }

  preUpdate (time, delta) {
    // if (this.image.x < this.scene.player.image.x - this.image.width - 400) {
    //   this.image.destroy()
    //   this.destroy()
    // }
  }

  getX () {
    return this.image.x
  }

  getY () {
    return this.image.y
  }

  setX (x) {
    this.image.x = x
    this.collider.x = x
    this.scene.spawner.setFinishX(x)
  }

  setY (y) {
  }

  toDef () {
    return { x: Math.round(this.collider.x), t: 'f', c: { } }
  }

  delete () {
    // cannot delete
  }
}
