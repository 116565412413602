import Phaser from 'phaser'

export default class GuideHandler extends Phaser.GameObjects.Container {
  constructor (x, y, guide, scene, handlerIndex) {
    super(scene)

    this.guide = guide
    this.handlerIndex = handlerIndex

    this.image = this.scene.add.image(x, y, 'images', 'circle')
    this.image.setSize(20, 20)
    this.image.setDisplaySize(20, 20)
    this.image.setDepth(1)
    this.image.setVisible(false)

    this.collider = this.scene.matter.add.image(x, y, 'white')
    this.collider.setTint(0xFFFFFF)
    this.collider.setSize(50, 50)
    this.collider.setDisplaySize(50, 50)
    this.collider.setIgnoreGravity(true)
    this.collider.setStatic(true)
    this.collider.setSensor(true)
    this.collider.setDepth(5)
    this.collider.setVisible(false)
    this.collider.parentContainer = this
    this.collider.setCollisionGroup(this.scene.collisionGroup2)
    this.collider.setCollisionCategory(this.scene.collisionCategory3)
  }

  preUpdate (time, delta) {
  }

  updateX (x) {
    this.image.x = x
    this.collider.x = x
  }

  getX () {
    return this.image.x
  }

  getY () {
    return this.image.y
  }

  setX (x) {
    // TODO: move bounce point on guide
    // this.guide.setXFor(this.handlerIndex, x)
    this.image.x = x
    this.collider.x = x
    this.guide.refresh()
  }

  setY (y) {
    // does nothing
  }

  toDef () {
    return this.guide.toDef()
  }

  onDeleted () {
    this.image.destroy()
    this.collider.destroy()
  }

  delete () {
    // TODO: delete this handler from the guide. The guide remains.
    this.guide.deleteHandler(this.handlerIndex)
    this.onDeleted()

    return 0
  }
}
