import Phaser from 'phaser'

import GameScene from './game'
import TitleScene from './title'
import HudScene from './hud'
import EditorHudScene from './editorHud'
import NonEditorHudScene from './nonEditorHud'
import SharePopupScene from './sharePopup'
import BannerAdScene from './bannerAd'
import SkipLevelScene from './skipLevel'

export const NUM_LEVELS = 45

function assets (loader) {
  loader.atlas('images', 'textures/packed/images_q.png', 'textures/packed/images.json')
  loader.atlas('spritesheets', 'textures/spritesheets/spritesheets_q.png', 'textures/spritesheets/spritesheets.json')

  // CHRISTMAS VERSION:
  // loader.atlas('santa_images', 'textures/packed/santasheet_q.png', 'textures/packed/santasheet.json')

  // load individual levels:
  // for (var i = 1; i <= NUM_LEVELS; i++) {
  //   loader.json('level' + i, 'levels/level' + i + '.json')
  // }

  // load batched levels:
  // loader.json('allLevels', 'levels/allLevels.json')
}

function spritesheets (textures) {
  sheet(textures, 'keyboard', 159, 107, 1)
  sheet(textures, 'confetti', 32, 32, 1)
  sheet(textures, 'names', 256, 84, 9)
  sheet(textures, 'unlocks', 150, 150, 9)
  sheet(textures, 'unlocks_locked', 38, 38, 8)
  sheet(textures, 'cake2', 202, 183, 3)
}

function sheet (textures, name, width, height, endFrame) {
  textures.addSpriteSheetFromAtlas(
    name,
    {
      atlas: 'spritesheets',
      frame: name,
      frameWidth: width,
      frameHeight: height,
      endFrame: endFrame
    }
  )
}

export default class LoadingScene extends Phaser.Scene {
  preload () {
    this.load.image('white', 'textures/white.png')
    this.started = false
    this.loadingFinished = false
    this.requiresDeviceRotation = false
  }

  // loadScenes loads multiple scenes here and returns the starting scene
  static loadScenes (manager) {
    manager.add('GameScene', GameScene, false)
    manager.add('TitleScene', TitleScene, false)
    manager.add('HudScene', HudScene, false)
    manager.add('EditorHudScene', EditorHudScene, false)
    manager.add('NonEditorHudScene', NonEditorHudScene, false)
    manager.add('SharePopup', SharePopupScene, false)
    manager.add('BannerAdScene', BannerAdScene, false)
    manager.add('SkipLevelScene', SkipLevelScene, false)

    return 'GameScene'
  }

  startScenes () {
    if (this.started) {
      return
    }
    this.started = true

    this.scene.run('BannerAdScene')

    if (this.game.registry.get('poki')) {
      PokiSDK.commercialBreak().then(
        () => {
          this.scene.start(this.initialScene)
        }
      )
    } else {
      this.scene.start(this.initialScene)
    }
  }

  create () {
    assets(this.load, 2)

    const bar = this.add.image((this.scale.gameSize.width / 2) - 200, 360, 'white')
    bar.setDisplaySize(2, 80).setOrigin(0, 0.5)

    this.load.on('progress', progress => {
      bar.setDisplaySize(400 * progress, 80)
    })

    this.load.once('complete', () => {
      if (this.game.registry.get('poki')) {
        PokiSDK.gameLoadingFinished()
      }

      spritesheets(this.textures)

      this.tweens.add({ targets: [bar], alpha: 0, duration: 100 })

      this.initialScene = LoadingScene.loadScenes(this.scene)
      this.loadingFinished = true

      this.startScenes()

      if (this.game.registry.get('analytics')) {
        try {
          gameanalytics.GameAnalytics.addProgressionEvent(gameanalytics.EGAProgressionStatus.Complete, 'loading')
        } catch (err) { }
      }
    })

    if (this.game.registry.get('poki')) {
      PokiSDK.gameLoadingStart()
    }
    this.load.start()

    if (this.game.registry.get('analytics')) {
      try {
        gameanalytics.GameAnalytics.addProgressionEvent(gameanalytics.EGAProgressionStatus.Start, 'loading')
      } catch (err) { }
    }
  }
}
