import Phaser from 'phaser'

export default class MovingObstacleHandler extends Phaser.GameObjects.Container {
  constructor (x, y, obstacle, scene) {
    super(scene)

    this.obstacle = obstacle

    this.image = this.scene.add.image(x, y, 'images', 'circle')
    this.image.setSize(20, 20)
    this.image.setDisplaySize(20, 20)
    // this.image.setOrigin(0, 0)
    this.image.setDepth(1)

    this.collider = this.scene.matter.add.image(x, y, 'white')
    this.collider.setTint(0xFFFFFF)
    this.collider.setSize(50, 50)
    this.collider.setDisplaySize(50, 50)
    this.collider.setIgnoreGravity(true)
    this.collider.setStatic(true)
    this.collider.setSensor(true)
    this.collider.setDepth(5)
    this.collider.setVisible(false)
    this.collider.parentContainer = this
    this.collider.setCollisionGroup(this.scene.collisionGroup2)
    this.collider.setCollisionCategory(this.scene.collisionCategory3)
  }

  preUpdate (time, delta) {
    // if (this.image.x < this.scene.player.image.x - this.image.width - 400) {
    //   this.image.destroy()
    //   this.destroy()
    // }
  }

  updateX (x) {
    this.image.x = x
    this.collider.x = x
  }

  getX () {
    return this.image.x
  }

  getY () {
    return this.image.y
  }

  setX (x) {
    this.obstacle.setX(x)
  }

  setY (y) {
    this.image.y = 558 - y
    this.collider.y = 558 - y

    if (this === this.obstacle.bottomHandler) {
      this.obstacle.setBottom()
    } else {
      this.obstacle.setTop()
    }
  }

  toDef () {
    return this.obstacle.toDef()
  }

  onDeleted () {
    this.image.destroy()
    this.collider.destroy()
  }

  delete () {
    return this.obstacle.delete()
  }
}
