import Phaser from 'phaser'
import Button from '/components/button'

export default class SharePopupScene extends Phaser.Scene {
  create ({ game, url }) {
    this.game = game
    const y = 300

    this.bg = this.add.image(640, y - 25, 'white').setOrigin(0.5, 0.5)
    this.bg.setDisplaySize(590, 50)
    this.bg.setAlpha(0.125)
    this.bg.setTint(0x555555)

    this.add.text(350, y - 88, 'Copy the following URL:', { fontSize: '30px', fontStyle: 'bold' })

    this.btn = new Button(this, 915, y - 75, '', 'exit', () => {
      this.scene.stop('SharePopup')
      this.game.unhideEditorHud()
    }, 0)

    this.inputField = this.add.dom(360, y - 40, 'input', {
      background: 'transparent',
      border: 0,
      color: 'white',
      fontSize: '20pt',
      fontWeight: 'bold',
      fontFamily: 'Courier',
      outline: 'none'
    }).setOrigin(0, 0)
    this.inputField.node.size = 99
    this.inputField.node.value = url
  }

  update (time, delta) {

  }
}
