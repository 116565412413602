import Phaser from 'phaser'

export default class BannerAdScene extends Phaser.Scene {
  create () {
    this.showingAd = false
    this.useLargeBanner = true
    this.isLandscape = true

    this.deviceIsPortrait = window.matchMedia('(orientation: portrait)')
    this.deviceIsLarge = window.matchMedia('(min-width: 730px)')
    this.deviceIsPortrait.addListener(() => { this.checkDeviceOrientation() })
    this.deviceIsLarge.addListener(() => { this.checkDeviceSize() })

    this.checkDeviceOrientation()
    this.checkDeviceSize()

    if (location.hostname === 'localhost') {
      // this.testLocation()
    }
  }

  testLocation () {
    console.log('testing banner location..')

    this.adContainer = document.createElement('div')
    this.adContainer.setAttribute('id', 'adContainer')
    this.adContainer.style.position = 'absolute'
    this.adContainer.style.left = '50%'
    this.adContainer.style.transform = 'translateX(-50%)'
    this.adContainer.style.top = '5px'
    this.adContainer.style.width = '728px'
    this.adContainer.style.height = '90px'
    this.adContainer.style.backgroundColor = '#FFFF0066'
    document.body.appendChild(this.adContainer)
  }

  checkDeviceSize () {
    if (this.deviceIsLarge.matches) {
      this.useLargeBanner = true
    } else {
      this.useLargeBanner = false
    }
  }

  checkDeviceOrientation () {
    if (this.deviceIsPortrait.matches) {
      this.isLandscape = false
    } else {
      this.isLandscape = true
    }
  }

  displayAd () {
    if (this.showingAd) {
      return
    }

    const alreadyExists = document.getElementById('adContainer')
    if (alreadyExists) {
      document.body.removeChild(alreadyExists)
    }

    this.adContainer = document.createElement('div')
    this.adContainer.setAttribute('id', 'adContainer')
    this.adContainer.style.position = 'absolute'
    this.adContainer.style.left = '50%'
    this.adContainer.style.transform = 'translateX(-50%)'
    this.adContainer.style.top = '5px'
    document.body.appendChild(this.adContainer)

    // console.log('DisplayAd() at ' + this.time.now)
    this.adStartTime = this.time.now
    if (this.useLargeBanner && this.game.device.os.desktop) {
      PokiSDK.displayAd(this.adContainer, '728x90')
      this.showingAd = true
    } else if (!this.useLargeBanner && !this.game.device.os.desktop) {
      PokiSDK.displayAd(this.adContainer, '320x50')
      this.showingAd = true
    }
  }

  destroyAd () {
    if (!this.showingAd) {
      return
    }
    // console.log('DestroyAd() at ' + this.time.now)
    const timeLeft = 3500 - (this.time.now - this.adStartTime)
    if (timeLeft > 0) {
      setTimeout(() => { this.actuallyDestroyAd() }, timeLeft)
    } else {
      this.actuallyDestroyAd()
    }
  }

  actuallyDestroyAd () {
    // console.log('ActuallyDestroyAd() at ' + this.time.now)
    PokiSDK.destroyAd(this.adContainer)
    this.showingAd = false
  }
}
