export default class MenuButton {
  constructor (scene, x, y, unlockId, callback, unlockIm, showText, scale = 1) {
    this.scene = scene
    this.callback = callback
    this.unlockId = unlockId
    this.unlockImScale = scale

    var config = {
      fontSize: '16px',
      fontStyle: 'bold'
    }

    this.text = this.scene.add.sprite(0, 0, 'names', unlockId)
    this.text.setScale(0.25)
    this.text.setVisible(showText)

    this.image = this.scene.add.image(x, y, 'images', 'circle')
    this.image.setScale(1)
    this.image.setInteractive()
    this.image.input.alwaysEnabled = true
    this.image.on('pointerdown', (pointer, localX, localY, event) => { this.callback(unlockId) })

    this.unlockIm = this.scene.add.sprite(x, y, unlockIm, unlockId)

    this.setScale(1)
  }

  destroy () {
    this.text.destroy()
    this.image.destroy()
    this.unlockIm.destroy()
  }

  getOffsetForUnlock (id) {
    switch (id) {
      case 0:
        return -18
      case 1:
        return -12
      case 2:
        return -10
      case 3:
        return -3
      case 4:
        return 1
      case 5:
        return -11
      case 6:
        return -8
      case 7:
        return -5
      case 8:
        return -5
      case 9:
        return -4
    }
    return 0
  }

  setX (x) {
    this.text.x = x + this.getOffsetForUnlock(this.unlockId)
    this.image.x = x
    this.unlockIm.x = x
  }

  setY (y) {
    this.image.y = y
    this.unlockIm.y = y
    this.text.y = y + 47
  }

  setPosition (x, y) {
    this.setX(x)
    this.setY(y)
  }

  setScale (s) {
    this.image.setScale(s)
    this.unlockIm.setScale(0.4 * s * this.unlockImScale)
  }
}
