import Phaser from 'phaser'
import { SANTA } from './player'

const WIDTH = 105
const HEIGHT = 125
const MAX_Y = 345

export default class Obstacle extends Phaser.GameObjects.Container {
  constructor (x, height, scene) {
    super(scene)
    this.scene = scene

    this.hasPassed = false

    this.height = height

    if (this.scene.game.registry.get('selectedAnimal') === SANTA) {
      this.triangle = this.scene.matter.add.image(x, 558 - height, 'santa_images', 'santa_spike')
      this.triangle.setFlipX(x % 2 === 0)
    } else {
      this.triangle = this.scene.matter.add.image(x, 558 - height, 'images', 'spike')
      this.triangle.setTint(this.scene.colorSpikes)
    }
    this.triangle.setTrapezoid(WIDTH, HEIGHT, 1)
    this.triangle.setOrigin(0.5, 0.67)
    this.triangle.setIgnoreGravity(true)
    this.triangle.setStatic(true)
    this.triangle.setSensor(true)
    this.triangle.setDepth(3)
    this.triangle.parentContainer = this
    this.triangle.setCollisionGroup(this.scene.collisionGroup1)
    this.triangle.setCollisionCategory(this.scene.collisionCategory2)

    if (this.scene.game.registry.get('selectedAnimal') === SANTA) {
      this.squares = new Array(4)
      for (var i = 0; i < this.squares.length; i++) {
        var s = this.scene.matter.add.image(x, 558 - height + 93 + (i * (WIDTH - 1)), 'santa_images', 'santa_square')
        s.setSize(WIDTH, WIDTH)
        s.setDisplaySize(WIDTH, WIDTH)
        s.setFlipX(i % 2 === 0)
        s.setIgnoreGravity(true)
        s.setStatic(true)
        s.setSensor(true)
        s.setDepth(1)
        s.setCollisionGroup(this.scene.collisionGroup1)
        s.setCollisionCategory(this.scene.collisionCategory2)
        this.squares[i] = s
      }
    } else {
      this.square = this.scene.matter.add.image(x, 558 - height / 2 + 45, 'images', 'square')
      this.square.setTint(scene.colorSpikes)
      this.square.setSize(WIDTH, height + 10)
      this.square.setDisplaySize(WIDTH, height + 10)
      this.square.setIgnoreGravity(true)
      this.square.setStatic(true)
      this.square.setSensor(true)
      this.square.setDepth(1)
      this.square.setCollisionGroup(this.scene.collisionGroup1)
      this.square.setCollisionCategory(this.scene.collisionCategory2)
    }

    // this.image = this.scene.add.image(x, 558 - height, 'white')
    // this.image.setSize(20, 20)
    // this.image.setDisplaySize(20, 20)
    // this.image.setDepth(6)

    this.offset = 0
  }

  preUpdate (time, delta) {
    if (!this.hasPassed && this.triangle.x < this.scene.player.image.x - 10) {
      this.hasPassed = true
      this.scene.player.addScore()
    }
  }

  getX () {
    return this.triangle.x
  }

  getY () {
    return this.triangle.y
  }

  setX (x) {
    this.triangle.x = x
    if (this.scene.game.registry.get('selectedAnimal') === SANTA) {
      for (var i = 0; i < this.squares.length; i++) {
        this.squares[i].x = x
      }
    } else {
      this.square.x = x
    }
  }

  setY (y) {
    y = Math.min(MAX_Y, y)

    this.height = y
    this.triangle.y = 558 - y
    if (this.scene.game.registry.get('selectedAnimal') === SANTA) {
      for (var i = 0; i < this.squares.length; i++) {
        this.squares[i].y = 558 - y + 93 + (i * (WIDTH - 1))
      }
    } else {
      this.square.y = 558 - y / 2 + 45
      this.square.setSize(WIDTH, y + 10)
      this.square.setDisplaySize(WIDTH, y + 10)
    }
  }

  toDef () {
    return { x: Math.round(this.triangle.x), t: 'o', c: { h: Math.round(this.height) } }
  }

  delete () {
    this.triangle.destroy()
    if (this.scene.game.registry.get('selectedAnimal') === SANTA) {
      // ..
    } else {
      this.square.destroy()
    }
    this.destroy()
    return this
  }
}
