import Phaser from 'phaser'

export default class RotatingParticle extends Phaser.GameObjects.Particles.Particle {
  constructor (emitter) {
    super(emitter)

    this.rotationSpeed = 0.003
  }

  update (delta, step, processors) {
    var result = super.update(delta, step, processors)
    this.angle += delta * this.rotationSpeed * this.velocityX
    return result
  }
}
