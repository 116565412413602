import Level from './level'
import Phaser from 'phaser'

export default class LevelDefs {
  constructor (scene) {
    this.levels = []
    this.scene = scene
    this.levels.push(null)

    // this.loadIndividualLevels(true)
    // this.loadBatchedLevels()

    // this.analyzeLevels()
    // this.drawLevels()
  }

  drawLevel (levelId, defs) {
    // TODO: see if we can draw )( where the guide hits the floor..

    var str = 'Level ' + levelId + ': '
    var dist = 0
    var x = 0
    var lastX = 0
    for (var defIndex = 0; defIndex < defs.length; defIndex++) {
      x = defs[defIndex].x
      dist = x - lastX
      if (defIndex > 1 && defs[defIndex - 1].t === 'g') {
        dist -= defs[defIndex - 1].c.w
      }
      while (dist > 330) {
        str += ' '
        dist -= 330
      }
      lastX = x

      if (defs[defIndex].t === 'mO') {
        str += '['
      } else if (defs[defIndex].t === 'tO') {
        if (defs[defIndex].c.h < 150) {
          str += 'ˇ'
        } else if (defs[defIndex].c.h < 250) {
          str += 'v'
        } else {
          str += '\\/'
        }
      } else if (defs[defIndex].t === 'o') {
        if (defs[defIndex].c.h < 150) {
          str += '.'
        } else if (defs[defIndex].c.h < 250) {
          str += ':'
        } else {
          str += '⋮'
        }
      } else if (defs[defIndex].t === 'g') {
        if (defs[defIndex].c.w < 300) {
          str += '_'
        } else if (defs[defIndex].c.w < 600) {
          str += '__'
        } else {
          str += '___'
        }
      } else if (defs[defIndex].t === 't') {
        // guide
      }
    }

    console.log(str)
  }

  drawLevels () {
    console.log('DrawLevels() : \n')
    for (var levelId = 1; levelId < this.levels.length; levelId++) {
      this.drawLevel(levelId, this.levels[levelId].data)
      console.log('\n\n')
    }
  }

  analyzeLevel (defs) {
    // 1. Get the Guide info:
    var hops = null
    for (var defIndex = 0; defIndex < defs.length; defIndex++) {
      if (defs[defIndex].t === 't') {
        hops = defs[defIndex].c
        break
      }
    }

    var hopIndex = 0
    var hopDifficulties = [hops.length]
    var momentumDifficulty = 0
    var sizeDifficulty = 0
    var prevHopSize = 0
    hopDifficulties[0] = 0

    hopIndex = 1
    var hopSize = (hops[hopIndex] - hops[hopIndex - 1])
    var relativeHopSize = (hopSize - 300) / (1200 - 300)

    var nObstacles = 0
    var nTopObstaclesAtEnd = 0
    var nTopObstaclesAtStart = 0
    var nMovers = 0
    var hasGap = false
    var closestObsToGuideDist = 1000
    var closestTopObsToGuideDist = 1000
    var guideHeight = 0
    var gapSize = 0
    var x = 0
    var info = null
    var xAlongHop = 0
    var distToGuide = 0
    var guideDifficulty = 0

    // Now, for each hop, determine its difficulty in terms of obstacles
    for (defIndex = 0; defIndex < defs.length; defIndex++) {
      if (defs[defIndex].t === 't') {
        continue
      }

      x = defs[defIndex].x

      // First, which hop am I in?
      while (x > hops[hopIndex]) {
        // console.log('nObstacles in hop ' + hopIndex + ' is: ' + nObstacles)
        // console.log('nTopObstacles in hop ' + hopIndex + ' is: ' + nTopObstacles)
        // console.log('nMovers in hop ' + hopIndex + ' is: ' + nMovers)
        // console.log('hasGap in hop ' + hopIndex + ' is: ' + hasGap)
        // console.log('clostestObsDist in hop ' + hopIndex + ' is: ' + closestObsToGuideDist)

        hopSize = hops[hopIndex] - hops[hopIndex - 1]
        if (hopIndex === 1) {
          momentumDifficulty = 0
        } else {
          prevHopSize = hops[hopIndex - 1] - hops[hopIndex - 2]

          if (nObstacles > 0 || nTopObstaclesAtStart > 0) {
            // Obstacles, and/or topObstacles at the start: acceleration is difficult
            if (hopSize > prevHopSize) {
              momentumDifficulty = 1 * Math.abs(hopSize - prevHopSize) / 500
            } else {
              // Deceleration is fairly easy, unless there are topObstacles at the end, which makes it (very) difficult
              if (nTopObstaclesAtEnd > 0) {
                momentumDifficulty = 2 * Math.abs(hopSize - prevHopSize) / 500
              } else {
                momentumDifficulty = 0.5 * (Math.abs(hopSize - prevHopSize) / 500)
              }
            }
          } else if (nTopObstaclesAtEnd > 0) {
            // TopObstacles at the end, makes deceleration (very) difficult:
            if (hopSize < prevHopSize) {
              momentumDifficulty = 2 * Math.abs(hopSize - prevHopSize) / 500
            } else {
              // Acceleration is fairly easy:
              momentumDifficulty = 0.5 * (Math.abs(hopSize - prevHopSize) / 500)
            }
          } else {
            // No (top)Obstacles that make acceleration or deceleration particularly difficult:
            momentumDifficulty = 0.25 * (Math.abs(hopSize - prevHopSize) / 500)
          }
        }

        // Very small and very large hops are difficult in general
        sizeDifficulty = (Math.abs(hopSize - 750)) / 450

        guideDifficulty = (1 * sizeDifficulty) + (1 * momentumDifficulty)

        // console.log('momentumDifficulty for hop ' + hopIndex + ' is: ' + momentumDifficulty)
        // console.log('sizeDifficulty for hop ' + hopIndex + ' is: ' + sizeDifficulty)

        var obsDifficulty = 0
        var topObsDifficulty = 0
        var moverDifficulty = 0
        var gapDifficulty = 0

        if (nObstacles > 0) {
          // Obstacle Difficulty is defined by how close the closest obstacle is to the guide
          var distanceDifficulty = 1 - (closestObsToGuideDist / 300)
          distanceDifficulty *= distanceDifficulty
          obsDifficulty = 4 * distanceDifficulty

          // var maxObstacles = Math.floor(hopSize / 110)
          // obsDifficulty += 1 * (nObstacles / maxObstacles)
          // console.log('obsDifficulty for hop ' + hopIndex + ' is: ' + obsDifficulty)
        }

        if (nTopObstaclesAtStart > 0) {
          // Top Obstacle Difficulty is defined by how close the closest topObstacle is to the guide
          // And a bonus if there is an extra topObstacle

          topObsDifficulty += 1.5 * (1 - (closestTopObsToGuideDist / 250))
          if (nTopObstaclesAtStart > 1) {
            topObsDifficulty += 0.25 * topObsDifficulty
          }
        }

        if (nTopObstaclesAtEnd > 0) {
          // Top Obstacle Difficulty is defined by how close the closest topObstacle is to the guide
          // And a bonus if there is an extra topObstacle

          topObsDifficulty += 3 * (1 - (closestTopObsToGuideDist / 250))
          if (nTopObstaclesAtEnd > 1) {
            topObsDifficulty += 0.25 * topObsDifficulty
          }
        }

        if (nObstacles > 0 && (nTopObstaclesAtStart + nTopObstaclesAtEnd) > 0) {
          obsDifficulty += topObsDifficulty
        }

        if (nMovers > 0) {
          moverDifficulty = 1 + (2 * (1 - relativeHopSize) * nMovers)

          if (nTopObstaclesAtStart > 0) {
            moverDifficulty += 1 * topObsDifficulty * (1 - relativeHopSize)
          }
          if (nTopObstaclesAtEnd > 0) {
            moverDifficulty += 1 * topObsDifficulty * (1 - relativeHopSize)
          }
          if (nObstacles > 0) {
            moverDifficulty += obsDifficulty * (1 + nTopObstaclesAtEnd)
          }

          if (hasGap) {
            moverDifficulty *= 1.5
          }
        }

        if (hasGap) {
          gapDifficulty = gapSize > 600 ? 2 : gapSize > 400 ? 1 : 0.5
        }

        console.log(hopIndex + ': ' + obsDifficulty.toFixed(2) + ' | ' + topObsDifficulty.toFixed(2) + ' | ' + moverDifficulty.toFixed(2) + ' * ' + (1 + guideDifficulty).toFixed(2))
        if (topObsDifficulty < 0) {
          // debugger
        }

        hopDifficulties[hopIndex] = Math.max(obsDifficulty, topObsDifficulty, moverDifficulty, gapDifficulty)
        // console.log('>> HOP ' + hopIndex + ' DIFFICULTY IS: ' + hopDifficulties[hopIndex].toFixed(2))

        hopDifficulties[hopIndex] *= 1 + guideDifficulty
        if (hopDifficulties[hopIndex] > 50) {
          // debugger
        }
        // Reset values for the next hop:
        hopIndex++
        nObstacles = 0
        nTopObstaclesAtStart = nTopObstaclesAtEnd
        nTopObstaclesAtEnd = 0
        nMovers = 0
        hasGap = false
        closestObsToGuideDist = 1000
        if (nTopObstaclesAtStart <= 0) {
          closestTopObsToGuideDist = 1000
        }
      }

      hopSize = (hops[hopIndex] - hops[hopIndex - 1])
      relativeHopSize = (hopSize - 300) / (1200 - 300)

      // Now, count (top)obstacles, movers and gaps, and see how close to the Guide they are:
      switch (defs[defIndex].t) {
        case 'o':
          nObstacles++
          info = LevelDefs.getGuideInfoAtX(x, hops[hopIndex - 1], hops[hopIndex])
          xAlongHop = (x - hops[hopIndex - 1]) / hopSize
          distToGuide = LevelDefs.getMaxObstacleHeight(info.point.y, xAlongHop, relativeHopSize) - defs[defIndex].c.h
          if (distToGuide < closestObsToGuideDist) {
            if (distToGuide < 0) {
              // debugger
            }
            closestObsToGuideDist = distToGuide
          }
          break
        case 'tO':
          if (x < hops[hopIndex - 1] + ((hops[hopIndex] - hops[hopIndex - 1]) / 2)) {
            nTopObstaclesAtStart++
          } else {
            nTopObstaclesAtEnd++
          }

          info = LevelDefs.getGuideInfoAtX(x, hops[hopIndex - 1], hops[hopIndex])
          distToGuide = LevelDefs.getMaxTopObstacleHeight(info.point.y, info.tan.x, relativeHopSize) - defs[defIndex].c.h
          if (distToGuide < closestTopObsToGuideDist) {
            closestTopObsToGuideDist = distToGuide
          }
          break
        case 'mO':
          nMovers++
          break
        case 'g':
          hasGap = true
          gapSize = defs[defIndex].c.w
          break
      }
    }

    // return (((Math.max(...hopDifficulties) + (0.1 * hops.length)) / 7.5) * 10).toFixed(2)
    return (Math.max(...hopDifficulties) + (0.05 * (hops.length - 1))).toFixed(2)
  }

  static getGuideInfoAtX (x, x0, x1) {
    const s = new Phaser.Math.Vector2(x0, 620)
    const c1 = new Phaser.Math.Vector2(x0 + 100, -50)
    const c2 = new Phaser.Math.Vector2(x1 - 100, -50)
    const e = new Phaser.Math.Vector2(x1, 620)

    const curve = new Phaser.Curves.CubicBezier(s, c1, c2, e)

    var t = (x - x0) / (x1 - x0)
    var point = curve.getPoint(t)
    var tan = curve.getTangent(t)

    return { point, tan }
  }

  static getMaxObstacleHeight (guideHeight, xAlongHop, relativeHopSize) {
    var maxHeight = 620 - guideHeight
    maxHeight += -180 - ((1 - relativeHopSize) * 90)
    maxHeight += (0.5 - Math.abs(xAlongHop - 0.5)) * 350 * (1 - relativeHopSize) - (100 * (1 - relativeHopSize))
    maxHeight += Math.abs(xAlongHop - 0.5) * 200 * relativeHopSize
    maxHeight += -30 * relativeHopSize
    return maxHeight
  }

  static getMaxTopObstacleHeight (guideHeight, tanX, relativeHopSize) {
    return guideHeight - 160 + ((1 - tanX) * -200)
  }

  // analyzeLevelOld (defs) {
  //   var avgDist = 0
  //   var avgDistScore = 0
  //   var lengthScore = 0
  //   var moversScore = 0
  //   var difficulty = 0
  //   var distChange = 0
  //   var distChangeScore = 0
  //   var heightScore = 0
  //   var prevDist = 0
  //   var prevTop = 0
  //   var doubleTopScore = 0

  //   for (var defIndex = 0; defIndex < defs.length; defIndex++) {
  //     if (defs[defIndex].t === 'm') {
  //       moversScore += 0.25
  //     } else if (defs[defIndex].t === 'tO' || defs[defIndex].t === 'o') {
  //       heightScore += defs[defIndex].c.h > 290 ? 1.5 : defs[defIndex].c.h > 240 ? 0.5 : defs[defIndex].c.h > 190 ? 0.1 : 0
  //       if (defs[defIndex].t === 'tO') {
  //         if (prevTop > 0) {
  //           var topDist = defs[defIndex].x - prevTop
  //           if (topDist < 175) {
  //             doubleTopScore += 0.5
  //           }
  //         }
  //         prevTop = defs[defIndex].x
  //       }
  //     } else if (defs[defIndex].t === 't') {
  //       for (var p = 0; p < defs[defIndex].c.length; p++) {
  //         if (p >= 1) {
  //           var dist = defs[defIndex].c[p] - defs[defIndex].c[p - 1]
  //           avgDist += dist
  //           if (p >= 2) {
  //             distChange = (dist - prevDist)
  //             if (distChange > 400) {
  //               distChangeScore += 0.1
  //             } else if (distChange < 0) {
  //               distChangeScore += (distChange / -150)
  //             }
  //           }
  //           prevDist = dist
  //         }
  //       }
  //       avgDist /= (defs[defIndex].c.length - 1)
  //       avgDistScore = (1000 - avgDist) / 150
  //       lengthScore = defs[defIndex].c.length >= 4 ? (defs[defIndex].c.length / 8) : 0
  //     }
  //   }
  //   difficulty = Math.round(avgDistScore + lengthScore + moversScore + distChangeScore + heightScore + doubleTopScore)
  //   return difficulty
  // }

  analyzeLevels () {
    var str = 'AnalyzeLevels() : \n'
    // for (var levelId = 1; levelId < 3; levelId++) {
    for (var levelId = 1; levelId < this.levels.length; levelId++) {
      var difficulty = this.analyzeLevel(this.levels[levelId].data)
      // str += 'Level ' + levelId + ': ' + difficulty
      str += difficulty // + ' (Should be around ' + (levelId * 0.1667).toFixed(2) + ')'
      str += '\n'
    }
    console.log(str)
  }

  loadIndividualLevels (printLevelsToConsole) {
    var allJSON = []
    var i = 1
    var json = this.scene.cache.json.get('level' + i)
    var level
    while (json) {
      level = this.createFromJSON(json)
      this.levels.push(level)
      i++
      allJSON.push(json)
      json = this.scene.cache.json.get('level' + i)
    }

    if (printLevelsToConsole) {
      console.log(JSON.stringify(allJSON))
    }
  }

  loadBatchedLevels () {
    var json
    var level
    var i
    json = this.scene.cache.json.get('allLevels')
    for (i = 0; i < json.length; i++) {
      level = this.createFromJSON(json[i])
      this.levels.push(level)
    }
    // console.log('batch loaded ' + json.length + ' levels')
  }

  createFromJSONString (jsonString) {
    var json = null
    try {
      json = JSON.parse(jsonString)
    } catch (e) {
      var decodedLevelStr = atob(jsonString)
      json = JSON.parse(decodedLevelStr)
    }
    return this.createFromJSON(json)
  }

  createFromJSON (obj) {
    var level = new Level()
    for (var i = 0; i < obj.length; i++) {
      if (obj[i].t) {
        level.setObjectDef(obj[i].x, obj[i].t, obj[i].c)
      } else if (obj[i].type) {
        level.setObjectDef(obj[i].x, obj[i].type, obj[i].config)
      }
    }
    return level
  }
}
