import Phaser from 'phaser'
import Button from '../components/button'

export default class NonEditorHudScene extends Phaser.Scene {
  create ({ game }) {
    this.game = game
    this.editorBtn = new Button(this, 1230, 40, 'Quit', 'exit', () => {
      if (this.game.levelEditor.enabled || !this.game.isCustomLevel) {
        if (location.hostname === 'localhost') {
          this.game.difficultyManager.increaseRating(-500)
        }
        this.game.player.onHit(0, false)
      } else {
        this.game.levelEditor.enable()
      }
    }, -50)

    this.landscape = window.innerWidth > window.innerHeight
    this.scale.on('resize', () => {
      const oldLS = this.landscape
      this.landscape = window.innerWidth > window.innerHeight
      if (this.landscape !== oldLS) {
        this.onResize()
      }
    })
    this.onResize()
  }

  onResize () {
    if (this.cameras.main) {
      if (this.landscape) {
        this.editorBtn.setX(1280 - 50)
        this.editorBtn.setY(40)// 360 - 320)
      } else {
        this.editorBtn.setX(720 - 50)
        this.editorBtn.setY(40)// 640 - 320)
      }
    }
  }
}
