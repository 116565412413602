import Ground from '../actors/ground'
import Gap from '../actors/gap'
import Obstacle from '../actors/obstacle'
import MovingObstacle from '../actors/movingObstacle'
import Finish from '../actors/finish'
import TopObstacle from '../actors/topObstacle'
import Phaser from 'phaser'
import Level from '../components/level'
import GapHandler from '../actors/gapHandler'
import Guide from '../actors/guide'
import MovingObstacleHandler from '../actors/movingObstacleHandler'
import GuideHandler from '../actors/guideHandler'
import LevelGenerator from './levelGenerator'
import DifficultyManager from './difficultyManager'

const Y_POS = 600
const MIN_SPIKE_DIST = 110

const BREAK_SIZE = 30// 30// 5

export default class Spawner {
  constructor (scene) {
    this.scene = scene
    this.player = scene.player

    this.objects = []
    this.gaps = []
    this.finishX = -1

    this.levelEnded = false
    this.nRespawns = 0

    this.lastGapEnd = -2000
    this.nextObstacleX = 1600

    this.rnd = new Phaser.Math.RandomDataGenerator()
    this.tempGround = this.spawnGround(this.lastGapEnd, 20000)

    this.animateDelay = 0
  }

  setLevel (level, nRespawns) {
    this.nRespawns = nRespawns
    this.level = level
    this.nextObjectDef = this.level.getNextObjectDef()

    this.spawnCompleteLevel()
    this.spawnPostFinishObjects()

    // this.startLine()
  }

  startLine () {
    this.line = 'case ' + this.scene.levelId + ':'
  }

  addLine (line) {
    this.line += '\n' + line
  }

  endLine () {
    this.line += '\nbreak'
    console.log(this.line)
  }

  setLevelFromJSONString (jsonString) {
    this.level = this.scene.levelDefs.createFromJSONString(jsonString)
    this.nextObjectDef = this.level.getNextObjectDef()

    this.spawnCompleteLevel()
    this.spawnPostFinishObjects()
  }

  setLevelEmpty () {
    this.level = new Level()
    this.nextObjectDef = -1
    this.spawnGuide([480, 900])
  }

  spawnRatedLevel (nRespawns) {
    this.level = new Level()
    this.nextObjectDef = -1
    this.nRespawns = nRespawns

    var levelGen = new LevelGenerator(this.scene, this.player)
    var seed = '' + (this.scene.levelId * 7919)
    levelGen.generateLevelWithRating(this.scene.difficultyManager.getRating(), seed)

    this.spawnPostFinishObjects()
  }

  update (time, delta) {
    // this.spawnRandom()

    if (this.level && !this.levelEnded) {
      this.spawnFromLevel()
    }
  }

  spawnFromDefinition (def, editor = false) {
    switch (def.t) {
      case 'movingobstacle':
      case 'mO':
        var mover
        // NB: there's a small bug here. If the mover's bottom equals 0, it won't get spawned.. lol.
        if (def.c.b) {
          mover = this.spawnMovingObstacle(def.x, def.c.b, def.c.r, def.c.p)
        } else if (def.c.bottom) {
          mover = this.spawnMovingObstacle(def.x, def.c.bottom, def.c.range, def.c.phase)
        }
        if (editor) {
          mover.pauseTween()
        }
        break
      case 'obstacle':
      case 'o':
        if (def.c.h) {
          this.spawnObstacle(def.x, def.c.h)
        } else if (def.c.height) {
          this.spawnObstacle(def.x, def.c.height)
        }
        break
      case 'topObstacle':
      case 'tO':
        if (def.x) {
          // console.log(def)
          if (def.c.h) {
            this.spawnTopObstacle(def.x, def.c.h)
          } else if (def.c.height) {
            this.spawnTopObstacle(def.x, def.c.height)
          }
        } else {
          // debugger
        }
        break
      case 'gap':
      case 'g':
        if (def.c.w) {
          this.spawnGap(def.x, def.c.w)
        } else if (def.c.width) {
          this.spawnGap(def.x, def.c.width)
        }
        break
      case 'finish':
      case 'f':
        this.spawnFinish(def.x)
        break
      case 't':
        this.spawnGuide(def.c)
        break
      case 'tH':
        this.insertGuideHandler(def.x)
        break
    }
  }

  spawnFromLevel (force = false) {
    if (force || this.player.image.x > this.nextObjectDef.x - 1400) {
      this.spawnFromDefinition(this.nextObjectDef)
      this.nextObjectDef = this.level.getNextObjectDef()
      this.animateDelay += 200
      if (this.nextObjectDef === -1) {
        this.levelEnded = true
      }
    }
  }

  spawnCompleteLevel () {
    var i = 0

    while (this.level && !this.levelEnded && i < 1000) {
      this.spawnFromLevel(true)
      i++
    }

    this.spawnGuide([480, 900])
  }

  spawnRandom () {
    if (this.player.image.x > this.nextObstacleX - 1400) {
      const r = this.rnd.between(0, 2)
      if (r === 0) {
        this.spawnTopObstacle(this.nextObstacleX, this.rnd.realInRange(0, 300))
      } else if (r === 1) {
        this.spawnObstacle(this.nextObstacleX, this.rnd.realInRange(0, 325))
      } else {
        this.spawnGate(this.nextObstacleX, this.rnd.realInRange(80, 180))
      }
      this.nextObstacleX += this.rnd.realInRange(MIN_SPIKE_DIST, 600)
    }
  }

  spawnGuideHandler (x, index) {
    var temp = new GuideHandler(x, 350, this.guide, this.scene, index)
    this.scene.add.existing(temp)
    this.guide.addHandler(temp)
  }

  spawnGuide (data) {
    if (this.guide) {
      return
    }

    var showGuide = false
    if (this.scene.levelId <= 5 || location.hostname === 'localhost') {
      showGuide = true
    } else {
      showGuide = this.nRespawns > Math.ceil(this.scene.difficultyManager.getRating() / 500)
    }

    this.guide = new Guide(this.scene)
    this.scene.add.existing(this.guide)
    for (var i = 0; i < data.length; i++) {
      this.spawnGuideHandler(data[i], i)
    }
    this.objects.push(this.guide)

    if (!showGuide) {
      this.guide.graphics.destroy()
      return
    }

    if (true) { //! (location.hostname === 'localhost' && process.env.NODE_ENV === 'development')) {
      var maxTextureSize = 1024
      try {
        maxTextureSize = this.scene.game.renderer.getMaxTextureSize()
      } catch (e) {}
      var textureSize = data[data.length - 1] + 100
      if (textureSize > maxTextureSize) {
        for (i = 0; i < Math.ceil(textureSize / maxTextureSize); i++) {
          this.guide.graphics.generateTexture('guide-' + i + '-' + this.scene.levelId, maxTextureSize, 1028)
          this.scene.add.image(i * maxTextureSize, 0, 'guide-' + i + '-' + this.scene.levelId).setOrigin(0, 0).setAlpha(0.5)
          this.guide.refresh((i + 1) * maxTextureSize)
        }
      } else {
        this.guide.graphics.generateTexture('guide' + this.scene.levelId, textureSize, 1028)
        this.scene.add.image(0, 0, 'guide' + this.scene.levelId).setOrigin(0, 0).setAlpha(0.5)
      }
      this.guide.graphics.destroy()
    }
  }

  insertGuideHandler (x) {
    this.guide.insertHandler(x)
  }

  spawnGate (x, gateSize) {
    this.spawnTopObstacle(x, 180 - gateSize)
    this.spawnObstacle(x, 180 - gateSize)
  }

  spawnTopObstacle (x, height) {
    var obstacle = new TopObstacle(x, height, this.scene)
    this.scene.add.existing(obstacle)
    this.objects.push(obstacle)

    // this.animateIn([obstacle.triangle, obstacle.square], -height - 200, this.animateDelay + Math.max(0, (x - 1000)))
    return obstacle
  }

  spawnObstacle (x, height) {
    var obstacle = new Obstacle(x, height, this.scene)
    this.scene.add.existing(obstacle)
    this.objects.push(obstacle)

    // this.animateIn([obstacle.triangle, obstacle.square], height + 200, this.animateDelay + Math.max(0, (x - 500)))
    return obstacle
  }

  spawnMovingObstacle (x, bottom, range, phase) {
    var obstacle = new MovingObstacle(x, bottom, range, phase, this.scene)
    this.scene.add.existing(obstacle)

    var topHandler = new MovingObstacleHandler(x, 558 - bottom - range, obstacle, this.scene)
    this.scene.add.existing(topHandler)
    obstacle.topHandler = topHandler

    var bottomHandler = new MovingObstacleHandler(x, 558 - bottom, obstacle, this.scene)
    this.scene.add.existing(bottomHandler)
    obstacle.bottomHandler = bottomHandler

    this.objects.push(obstacle)
    return obstacle
  }

  spawnGround (x, width) {
    var ground = new Ground(x, Y_POS, width, this.scene)
    this.scene.add.existing(ground)
    return ground
  }

  spawnFinish (x) {
    if (this.finishX < 0) {
      this.finish = new Finish(x, this.scene)
      this.scene.add.existing(this.finish)
      this.objects.push(this.finish)
      this.finishX = x
    }
  }

  setFinishX (x) {
    this.finishX = x
  }

  spawnPostFinishObjects () {
    if (!this.finish) {
      return
    }

    this.finish.image.destroy()

    // var particleOptions = {
    //   friction: 0.05,
    //   frictionStatic: 0.1,
    //   render: { visible: true },
    //   collisionFilter: { group: this.scene.collisionGroup1, category: this.scene.collisionCategory2 }
    // }

    this.scene.add.image(this.finishX + 495, 300, 'images', 'light').setAlpha(0.5).setDepth(1100).setScale(4)
    // spawn a cake:
    this.cakeIm = this.scene.add.sprite(this.finishX + 500, 512, 'cake2', 0)
    this.cakeIm.setDepth(1000)

    var coll = this.scene.matter.add.image(this.finishX + 500, 535, 'white')
    coll.setIgnoreGravity(true)
    coll.setStatic(true)
    coll.setSensor(true)
    coll.setVisible(false)
    coll.setSize(200, 135)
    coll.setDisplaySize(200, 135)
    coll.setCollisionGroup(this.scene.collisionGroup1)
    coll.setCollisionCategory(this.scene.collisionCategory2)

    // heart
    this.heartIm = this.scene.add.image(this.finishX + 550, 400, 'images', 'heart')
    this.heartIm.setVisible(false)
    this.heartIm.setDepth(1000)

    var ground = this.scene.matter.add.image(this.finishX + 300, Y_POS + 100, 'white')
    ground.setSize(2000, 200)
    ground.setDisplaySize(2000, 200)
    ground.setStatic(true)
    ground.setIgnoreGravity(true)
    ground.setFriction(1, 1)
    ground.setVisible(false)

    ground.setCollisionGroup(this.scene.collisionGroup2)

    // var black = false
    // var prev = null
    // for (var i = 0; i < 2; i++) {
    //   for (var j = 0; j < (600 / BREAK_SIZE) + 1; j++) {
    //     // prev = this.spawnPhysicsObject(this.finishX + BREAK_SIZE * i, (BREAK_SIZE * j) - (BREAK_SIZE / 2), black, prev)
    //     // prev = this.spawnPhysicsObject(this.finishX + Phaser.Math.Between(100, 400), (BREAK_SIZE * j) - (BREAK_SIZE / 2), black, prev)
    //     black = !black
    //   }
    // }

    // this.scene.matter.world.on('sleepend', (data) => {
    //   this.scene.time.delayedCall(10, function () {
    //     // var next = data.source.gameObject.getData('prev')
    //     // if (next) {
    //     // next.setAwake()
    //     // next.setAngularVelocity(0.05)
    //     // }
    //   })
    // })
  }

  spawnPhysicsObject (x, y, black, prev) {
    var image = this.scene.matter.add.image(x, y, 'white', '', {
      // isSleeping: true,
      // mass: 0.00000000000000001,
      // inverseMass: 1//,
      density: 0.0000001
    })
    image.setData('prev', prev)

    // image.setMa
    if (black) {
      image.setTint(this.scene.colorFG)// 0)
    }
    image.setSize(BREAK_SIZE, BREAK_SIZE)
    image.setDisplaySize(BREAK_SIZE, BREAK_SIZE)
    image.setFriction(0.1, 0, 0)// 0.05, 0.05)
    image.setSleepEndEvent(true)
    image.setCollisionGroup(this.scene.collisionGroup1)

    image.setOnCollide((data) => {
      if (data.bodyA.gameObject === this.player.image) {
        data.bodyB.gameObject.setAwake()
        this.scene.time.delayedCall(200, function () {
          // data.bodyB.gameObject.setFriction(0.1)
        })
      } else {
        this.scene.time.delayedCall(200, function () {
          // data.bodyB.gameObject.setAwake()
        })
        this.scene.time.delayedCall(400, function () {
          // data.bodyB.gameObject.setFriction(0.1)
        })
      }
    })

    return image
  }

  spawnGap (x, width) {
    this.gaps.push({ x, width })
    var gap = new Gap(x, Y_POS, width, 0xDCFF60, this.scene)
    this.scene.add.existing(gap)

    var gapHandlerL = new GapHandler(x, Y_POS, this.scene.colorSpikes, gap, this.scene)
    this.scene.add.existing(gapHandlerL)

    var gapHandlerR = new GapHandler(x + width, Y_POS, this.scene.colorSpikes, gap, this.scene)
    this.scene.add.existing(gapHandlerR)

    gap.leftHandler = gapHandlerL
    gap.rightHandler = gapHandlerR

    this.objects.push(gap)

    this.spawnGround(this.lastGapEnd, x - this.lastGapEnd)
    this.lastGapEnd = x + width

    if (this.tempGround) {
      this.tempGround.image.destroy()
      this.tempGround.line.destroy()
      this.tempGround.destroy()
    }

    this.tempGround = this.spawnGround(this.lastGapEnd, 100000)
  }

  isGapAt (x, leniency) {
    for (var i = 0; i < this.gaps.length; i++) {
      if (this.gaps[i].x < x - leniency && this.gaps[i].x + this.gaps[i].width > x + leniency) {
        return i
      }
    }
    return -1
  }

  getBounceDirAtGap (i, x, playerWidth) {
    if (x - playerWidth / 2 < this.gaps[i].x) {
      return (this.gaps[i].x) - (x - playerWidth / 2)
    } else if (x + playerWidth / 2 > this.gaps[i].x + this.gaps[i].width) {
      return (this.gaps[i].x + this.gaps[i].width) - (x + playerWidth / 2)
    }
    return 0
  }

  animateIn (objects, offset, delay) {
    for (var i = 0; i < objects.length; i++) {
      objects[i].y += offset
    }
    this.scene.tweens.add({
      targets: objects,
      y: '-= ' + offset,
      delay: delay,
      ease: 'Sine.easeInOut',
      duration: 250 + Math.abs(offset)
    })
  }

  pauseMovers () {
    for (var i = 0; i < this.objects.length; i++) {
      if (this.objects[i] instanceof MovingObstacle) {
        this.objects[i].pauseTween()
      }
    }
  }

  unPauseMovers () {
    for (var i = 0; i < this.objects.length; i++) {
      if (this.objects[i] instanceof MovingObstacle) {
        this.objects[i].resumeTween()
      }
    }
  }
}
