import Phaser, { Curves } from 'phaser'
import MovingObstacle from './movingObstacle'
import RotatingParticle from './rotatingParticle'

export const SPEED = 15
export const ACCEL = 10
export const DECEL = 1
export const SIZE = 80// 65
const BOUNCINESS = 1.325
const START_Y = -50// 150

export const CAT = 0
export const ELEPHANT = 1
export const GIRAFFE = 2
export const BUNNY = 3
export const PANDA = 4
export const UNICORN = 5
export const BLACK_CAT = 6
export const WHITE_CAT = 7
export const ROBOT = 8
export const SANTA = 9

export default class Player extends Phaser.GameObjects.Container {
  constructor (scene, lastY, useNextColor, selectedAnimal) {
    super(scene)

    this.useMatterGravity = false
    this.ignoreGravity = false

    this.scene = scene

    this.hasStarted = false
    this.selectedHat = selectedAnimal === WHITE_CAT

    this.score = 0
    this.combo = 1
    this.comboTimer = 0
    this.nBouncesThisCombo = 0
    this.nSpikesThisCombo = 0

    this.stretch = 1
    this.strechtDown = true

    this.canMove = true
    this.isGameOver = false
    this.fellInGap = false

    this.godMode = false
    if (process.env.NODE_ENV !== 'production') {
      this.scene.input.keyboard.on('keyup-Q', () => {
        this.godMode = !this.godMode
      })
    }

    this.setAnimal(selectedAnimal)// useNextColor ? -100 : lastY)
    this.image.setCircle(49)
    this.image.setSize(SIZE, SIZE)
    this.image.setDisplaySize(SIZE, SIZE)
    this.colorIndex = 0xffffff// color
    this.image.setBounce(BOUNCINESS)
    this.image.setFixedRotation()
    this.image.setDepth(500)
    this.image.setOnCollide((collisionData) => { this.onCollision(collisionData) })
    this.image.setCollisionGroup(this.scene.collisionGroup1)
    this.image.setCollisionCategory(this.scene.collisionCategory1)
    this.image.setCollidesWith(this.scene.collisionCategory2)
    this.add(this.image)
    this.image.setOrigin(0.80, 0.835)
    this.image.setIgnoreGravity(!this.useMatterGravity)

    if (this.selectedAnimal === UNICORN) {
      this.image.setScale(0.62, 0.62)
      this.image.setOrigin(0.80, 1.425)
    }

    this.keyRight = this.scene.input.keyboard.addKey('right')
    this.keyLeft = this.scene.input.keyboard.addKey('left')

    this.bounceParticles = this.scene.add.particles('images', 'circle')
    this.bounceParticles.setDepth(3)

    this.exhaust = this.bounceParticles.createEmitter({
      on: false,
      active: true,
      frequency: 1,
      quantity: 1,
      lifespan: 400,
      scale: { start: 0.28, end: 0 },
      alpha: { start: 1, end: 0 },
      follow: this.image
    })

    this.explode = this.bounceParticles.createEmitter({
      speed: { min: 150, max: 200 },
      angle: { min: 190, max: 230 },
      scale: { start: 0.08, end: 0.16 },
      alpha: { start: 1, end: 0 },
      quantity: 10,
      lifespan: 800,
      gravityY: 300,
      gravityX: 200,
      on: false
    })
    this.explode2 = this.bounceParticles.createEmitter({
      speed: { min: 150, max: 200 },
      angle: { min: 310, max: 350 },
      scale: { start: 0.08, end: 0.16 },
      alpha: { start: 1, end: 0 },
      quantity: 10,
      lifespan: 800,
      gravityY: 300,
      gravityX: -200,
      on: false
    })

    this.cakeParticles = this.scene.add.particles('images', 'circle')
    this.cakeParticles.setDepth(510)
    this.cakePa = this.cakeParticles.createEmitter({
      tint: 0xFFFFE4,
      speed: { min: 200, max: 400 },
      // angle: { min: 210, max: 330 },
      scale: { start: 0.7, end: 0.04 },
      // blendMode: 'SCREEN',
      quantity: 20,
      lifespan: 700,
      gravityY: 1000,
      on: false
      // radial: true
    })

    this.berryParticles = this.scene.add.particles('images', 'berry')
    this.berryParticles.setDepth(1020)
    this.berryP = this.berryParticles.createEmitter({
      // tint: 0xFFFFE4,
      speed: 800,
      angle: { min: 225, max: 315 },
      // scale: { start: 0.2, end: 0 },
      // blendMode: 'SCREEN',
      quantity: 1,
      lifespan: 4000,
      gravityY: 1000,
      on: false,
      rotate: { onEmit: function (particle, key, t, value) { return Phaser.Math.Between(0, 360) } },
      particleClass: RotatingParticle
      // radial: true
    })

    this.poofP = this.bounceParticles.createEmitter({
      speed: { min: 20, max: 400 },
      // angle: { min: 0, max: 360 },
      // tint: 0x000000,
      scale: { start: 0.8, end: 0 },
      alpha: { start: 1, end: 0 },
      // tint: [0xffffff, 0xffff00, 0x5555ff],
      // tint: { start: 0xffffff, end: 0x000000 },
      quantity: 30,
      lifespan: 400,
      // gravityY: 0,
      gravityY: 1000,
      on: false,
      radial: true
    })

    this.constraints = []
    this.tails = []
    this.setAnimalTails()

    if (this.selectedHat) {
      this.addHat()
    }
  }

  setAnimal (index) {
    if (this.image) {
      this.image.destroy()
    }

    this.selectedAnimal = index
    switch (this.selectedAnimal) {
      default:
      case SANTA:
        this.image = this.scene.matter.add.image(0, START_Y, 'santa_images', 'santa')
        break
      case CAT:
      case BLACK_CAT:
      case WHITE_CAT:
        this.image = this.scene.matter.add.image(0, START_Y, 'images', 'cat')
        switch (this.selectedAnimal) {
          case CAT:
            this.image.setTint(0xFCB86F)
            break
          case BLACK_CAT:
            this.image.setTint(0x555555)
            break
          case WHITE_CAT:
            this.image.setTint(0xF0DBC8)
            break
        }
        break
      case ELEPHANT:
        this.image = this.scene.matter.add.image(0, START_Y, 'images', 'elephant')
        break
      case ROBOT:
        this.image = this.scene.matter.add.image(0, START_Y, 'images', 'robot')
        break
      case BUNNY:
        this.image = this.scene.matter.add.image(0, START_Y, 'images', 'bunny')
        break
      case PANDA:
        this.image = this.scene.matter.add.image(0, START_Y, 'images', 'panda')
        break
      case GIRAFFE:
        this.image = this.scene.matter.add.image(0, START_Y, 'images', 'giraffe_body')
        break
      case UNICORN:
        this.image = this.scene.matter.add.image(0, START_Y, 'images', 'unicorn')
        break
    }
  }

  poof () {
    this.image.setVisible(false)
    for (var i = 0; i < this.tails.length; i++) {
      this.tails[i].setVisible(false)
    }

    // if (this.image.body.velocity.y < -2) {
    //   this.poofP.setAngle({ min: 120, max: 60 })
    // } else if (this.image.body.velocity.y > 2) {
    //   this.poofP.setAngle({ min: 60, max: 120 })
    // } else {

    // }

    // this.poofP.setGravityY(this.image.body.velocity.y * 100)
    this.image.setIgnoreGravity(true)
    this.image.setStatic(true)

    this.poofP.setPosition(this.image.x, this.image.y)
    this.poofP.explode()
  }

  setAnimalTails () {
    // if (this.constraints) {
    //   for (var i = 0; i < this.tails.length; i++) {
    //     this.tails[i].destroy()
    //     this.constraints[i].destroy()
    //   }
    // }

    var limbColor
    switch (this.selectedAnimal) {
      default:
      case SANTA:
        limbColor = 0xE00000
        this.addChain(3, { x: 27, y: -13 }, limbColor, false)
        this.addChain(3, { x: -27, y: -13 }, limbColor, false)
        this.addChain(3, { x: 19, y: 25 }, limbColor, false, true)
        this.addChain(3, { x: -19, y: 25 }, limbColor, false, true)
        this.leftArmIndx = 0
        this.rightArmIndx = 3
        this.leftLegIndx = 6
        this.rightLegIndx = 9

        this.addChain(6, { x: 0, y: -36 }, limbColor, true)
        // for (var i = this.tails.length - 1; i >= 13; i--) {
        //   this.sendToBack(this.tails[i])
        // }

        this.moveUp(this.tails[12])
        break
      case CAT:
      case BLACK_CAT:
      case WHITE_CAT:
        switch (this.selectedAnimal) {
          case CAT:
            limbColor = 0xFCB86F
            break
          case BLACK_CAT:
            limbColor = 0x555555
            break
          case WHITE_CAT:
            limbColor = 0xF0DBC8
            break
        }
        this.addChain(8, { x: 0, y: 30 }, limbColor, true)
        this.addChain(2, { x: 18, y: -13 }, limbColor, false)
        this.addChain(2, { x: -18, y: -13 }, limbColor, false)
        this.addChain(4, { x: 21, y: 21 }, limbColor, false, true)
        this.addChain(4, { x: -21, y: 21 }, limbColor, false, true)
        this.leftArmIndx = 8
        this.rightArmIndx = 10
        this.leftLegIndx = 13
        this.rightLegIndx = 16
        break
      case ELEPHANT:
        limbColor = 0x9E9E9E
        this.addChain(1, { x: 24, y: -5 }, limbColor, false)
        this.addChain(1, { x: -24, y: -5 }, limbColor, false)
        this.addChain(1, { x: 21, y: 21 }, limbColor, false)
        this.addChain(1, { x: -21, y: 21 }, limbColor, false)
        this.addChain(5, { x: 0, y: -22 }, limbColor, true)
        this.leftArmIndx = 0
        this.rightArmIndx = 1
        this.leftLegIndx = 2
        this.rightLegIndx = 3
        break
      case GIRAFFE:
        limbColor = 0xE5B95A
        this.addChain(2, { x: 24, y: -5 }, limbColor, false)
        this.addChain(2, { x: -24, y: -5 }, limbColor, false)
        this.addChain(2, { x: 21, y: 21 }, limbColor, false)
        this.addChain(2, { x: -21, y: 21 }, limbColor, false)
        this.addChain(6, { x: 0, y: -27 }, limbColor, true)
        this.leftArmIndx = 0
        this.rightArmIndx = 2
        this.leftLegIndx = 4
        this.rightLegIndx = 6
        break
      case BUNNY:
        limbColor = 0xFFFFFF
        this.addChain(2, { x: -27, y: -12 }, limbColor, false)
        this.addChain(2, { x: 27, y: -12 }, limbColor, false)
        this.addChain(2, { x: -10, y: 27 }, limbColor, false)
        this.addChain(2, { x: 10, y: 27 }, limbColor, false)
        this.addChain(6, { x: 13, y: -28 }, limbColor, true)
        this.addChain(6, { x: -13, y: -28 }, limbColor, true)
        this.leftArmIndx = 0
        this.rightArmIndx = 2
        this.leftLegIndx = 4
        this.rightLegIndx = 6
        break
      case ROBOT:
        limbColor = 0xCECECE
        this.addChain(3, { x: -30, y: -10 }, limbColor, false)
        this.addChain(3, { x: 30, y: -10 }, limbColor, false)
        this.leftArmIndx = 0
        this.rightArmIndx = 2
        this.leftLegIndx = 5
        this.rightLegIndx = 3
        this.addChain(4, { x: 0, y: -35 }, 0, true)
        for (var i = this.tails.length - 1; i >= 6; i--) {
          this.sendToBack(this.tails[i])
        }
        break
      case PANDA:
        limbColor = 0
        this.addChain(2, { x: -30, y: -9 }, limbColor, false)
        this.addChain(2, { x: 30, y: -9 }, limbColor, false)
        this.addChain(2, { x: -20, y: 24 }, limbColor, false)
        this.addChain(2, { x: 20, y: 24 }, limbColor, false)
        this.leftArmIndx = 0
        this.rightArmIndx = 2
        this.leftLegIndx = 4
        this.rightLegIndx = 6
        break
      case UNICORN:
        limbColor = 0xFBE1EC
        this.addChain(2, { x: -27, y: -9 }, limbColor, false)
        this.addChain(2, { x: 27, y: -9 }, limbColor, false)
        this.addChain(2, { x: -20, y: 24 }, limbColor, false)
        this.addChain(2, { x: 20, y: 24 }, limbColor, false)
        this.leftArmIndx = 0
        this.rightArmIndx = 2
        this.leftLegIndx = 4
        this.rightLegIndx = 6
        this.addChain(8, { x: -8, y: 28 }, 0xF954F7, true)
        this.addChain(9, { x: 8, y: 28 }, 0xF92231, true)
        this.addChain(10, { x: 0, y: 28 }, 0xF9F62F, true)
        for (var i = this.tails.length - 1; i >= this.rightLegIndx + 2; i--) {
          this.sendToBack(this.tails[i])
        }
        break
    }
  }

  addHat () {
    this.hasHat = true
    this.hat = this.scene.matter.add.image(0, 0, 'images', 'hat')
    this.hat.setScale(0.5)
    this.hat.setCollisionGroup(-1)
    this.hat.setCollisionCategory(0)
    this.hat.setIgnoreGravity(true)
    this.hat.setTint(0)
    this.hat.setDepth(650)
  }

  addChain (nSec, offset, color, isTail, isFeet = false) {
    var tail
    var constr
    var prev = this.image
    for (var i = 0; i < nSec; i++) {
      if (isTail && i === nSec - 1 && (this.selectedAnimal === GIRAFFE || this.selectedAnimal === UNICORN)) {
        if (this.selectedAnimal === GIRAFFE) {
          tail = this.scene.matter.add.image(0, START_Y, 'images', 'giraffe_head')
          tail.setScale(1)
        } else if (this.selectedAnimal === UNICORN) {
          tail = this.scene.matter.add.image(0, START_Y, 'images', 'unicorn_tail')
          tail.setScale(1 - (0.06 * (6 - i)), 0.9)
        }
      } else {
        if (this.selectedAnimal === GIRAFFE) {
          tail = this.scene.matter.add.image(0, START_Y, 'images', 'giraffe_limb')
        } else if (this.selectedAnimal === ROBOT) {
          if (isTail) {
            tail = this.scene.matter.add.image(0, START_Y, 'images', 'robot_limb')
          } else if (i === nSec - 1) {
            tail = this.scene.matter.add.image(0, START_Y, 'images', 'robot_hand')
          } else {
            tail = this.scene.matter.add.image(0, START_Y, 'images', 'robot_limb')
          }
        } else if (this.selectedAnimal === SANTA) {
          if (isTail && i === nSec - 1) {
            tail = this.scene.matter.add.image(0, START_Y, 'images', 'robot_limb')
          } else if (isTail && i === 0) {
            tail = this.scene.matter.add.image(0, START_Y, 'santa_images', 'santa_limb')
          } else if (isFeet && i === nSec - 1) {
            tail = this.scene.matter.add.image(0, START_Y, 'santa_images', 'santa_limb')
          } else {
            tail = this.scene.matter.add.image(0, START_Y, 'images', 'limb')
          }
        } else {
          tail = this.scene.matter.add.image(0, START_Y, 'images', 'limb')
        }

        if (isTail) {
          if (this.selectedAnimal === SANTA) {
            if (i === nSec - 1) {
              tail.setScale(0.5)
            } else if (i === 0) {
              tail.setScale(1, 0.4)//, 0.2)
            } else {
              tail.setScale(0 + (0.15 * (nSec - i)))
            }
          } else if (this.selectedAnimal === GIRAFFE) {
            tail.setScale(0.3 + (0.1 * (6 - i)))
          } else if (this.selectedAnimal === BUNNY) {
            tail.setScale(1 - (0.06 * (6 - i)), 0.9)
          } else if (this.selectedAnimal === ELEPHANT) {
            tail.setScale(0.4 + (0.05 * (6 - i)))
          } else if (this.selectedAnimal === UNICORN) {
            tail.setScale(1 - (0.06 * (6 - i)), 0.9)
          } else if (this.selectedAnimal === ROBOT) {
            if (i === nSec - 1) { tail.setScale(0.45) } else { tail.setScale(0.1, 0.6) }
          } else {
            tail.setScale(0.5, 0.6)
          }
        } else {
          if (this.selectedAnimal === ROBOT) {
            tail.setScale(1)
          } else {
            tail.setScale(0.6)
          }
        }
      }
      tail.setCircle(1)
      this.add(tail)
      if (isTail) {
        if (this.selectedAnimal === SANTA && i === 0) {
          tail.setTint(0xffffff)
        } else if (i === nSec - 1 && (this.selectedAnimal === GIRAFFE || this.selectedAnimal === SANTA)) {
          //
        } else {
          tail.setTint(color)
        }
        tail.setFrictionAir(0.4 - (i * (0.4 / nSec)))
      } else {
        if (i < nSec - 1) {
          tail.setTint(color)
        } else {
          if (this.selectedAnimal === SANTA) {
            if (isFeet) {
              tail.setTint(0x000000)
            } else {
              tail.setTint(0xffffff)
            }
          } else if (this.selectedAnimal === CAT || this.selectedAnimal === WHITE_CAT || this.selectedAnimal === ROBOT) {
            tail.setTint(0xffffff)
          } else if (this.selectedAnimal === GIRAFFE) {
            tail.setTint(0xF9DB9C)
          } else if (this.selectedAnimal === UNICORN) {
            tail.setTint(0xFFF6B2)
          } else {
            tail.setTint(color)
          }
        }
        tail.setFrictionAir(0.4)
      }
      tail.setMass(0.0000000000001)
      var dist = 8
      if (this.selectedAnimal === ROBOT) {
        dist = 12
        if (isTail) { dist = 4 }
      }
      if (i === 0) {
        constr = this.scene.matter.add.constraint(prev, tail, dist, 1, { pointA: offset })
      } else {
        constr = this.scene.matter.add.constraint(prev, tail, dist, 1)
      }
      tail.setCollisionGroup(this.scene.collisionGroup3)
      tail.setCollisionCategory(this.scene.collisionCategory4)
      this.constraints.push(constr)
      this.tails.push(tail)
      prev = tail
    }
  }

  // return a color string - with at least 444444
  genHexString (len) {
    let output = ''
    for (let i = 0; i < len; ++i) {
      output += (Math.floor(4 + Math.random() * 12)).toString(16)
    }
    return output
  }

  setupInput () {
    this.keySpace = this.scene.input.keyboard.addKey('space')
    this.keyEditor = this.scene.input.keyboard.addKey('E')
    this.keyEsc = this.scene.input.keyboard.addKey('esc')
    this.keyEscWasDown = false
  }

  doStretch (value, down) {
    this.stretch = value
    this.strechtDown = down
  }

  updateTails () {
    if (this.tails.length <= 0) { return }

    for (var i = 0; i < this.tails.length; i++) {
      var diff = new Phaser.Math.Vector2()
      diff.x = this.constraints[i].bodyB.position.x - (this.constraints[i].bodyA.position.x + this.constraints[i].pointA.x)
      diff.y = this.constraints[i].bodyB.position.y - (this.constraints[i].bodyA.position.y + this.constraints[i].pointA.y)
      this.tails[i].setRotation(diff.angle() - 0.5 * Math.PI)
    }

    // if (this.selectedAnimal === OCTOPUS) {
    //   //
    // } else {
    const d = this.selectedAnimal === ELEPHANT ? 2 : 8
    var dir = this.tails[this.leftArmIndx].x > this.image.x ? 1 : -1
    this.tails[this.leftArmIndx].setVelocityX(dir * d * 1.2)
    this.tails[this.rightArmIndx].setVelocityX(dir * -d)
    this.tails[this.leftLegIndx].setVelocityX(dir * d * 2)
    this.tails[this.rightLegIndx].setVelocityX(dir * -d * 1.2)
    // }

    var neckDir
    var j
    if (this.selectedAnimal === SANTA) {
      neckDir = new Phaser.Math.Vector2(0, -14)
      neckDir.rotate(this.image.rotation)
      for (j = 0; j < 2; j++) {
        this.tails[j + 12].setVelocityX(neckDir.x * ((6 - j) / 6))
        this.tails[j + 12].setVelocityY(neckDir.y * ((6 - j) / 6))
      }
    }
    if (this.selectedAnimal === GIRAFFE) {
      neckDir = new Phaser.Math.Vector2(0, -14)
      neckDir.rotate(this.image.rotation)
      for (j = 0; j < 6; j++) {
        this.tails[j + 8].setVelocityX(neckDir.x * ((6 - j) / 6))
        this.tails[j + 8].setVelocityY(neckDir.y * ((6 - j) / 6))
      }
    } else if (this.selectedAnimal === ROBOT) {
      neckDir = new Phaser.Math.Vector2(0, -14)
      neckDir.rotate(this.image.rotation)
      for (j = 0; j < 4; j++) {
        this.tails[j + 6].setVelocityX(neckDir.x)// * ((6 - j) / 6))
        this.tails[j + 6].setVelocityY(neckDir.y)// * ((6 - j) / 6))
      }
    } else if (this.selectedAnimal === BUNNY) {
      neckDir = new Phaser.Math.Vector2(0, -13)
      neckDir.rotate(this.image.rotation + Math.PI * 0.15)
      for (j = 0; j < 6; j++) {
        this.tails[j + 8].setVelocityX(neckDir.x * ((6 - j) / 6))
        this.tails[j + 8].setVelocityY(neckDir.y * ((6 - j) / 6))
      }
      neckDir = new Phaser.Math.Vector2(0, -13)
      neckDir.rotate(this.image.rotation - Math.PI * 0.05)
      for (j = 0; j < 6; j++) {
        this.tails[j + 14].setVelocityX(neckDir.x * ((6 - j) / 6))
        this.tails[j + 14].setVelocityY(neckDir.y * ((6 - j) / 6))
      }
    }

    if (this.selectedHat) {
      if (this.hasHat) {
        // 1. calc rotation, which is slightly delayed
        var oldRot = this.hat.rotation
        var targetRot = this.image.rotation
        var dist = Math.abs(targetRot - oldRot)
        if (Math.abs(targetRot) < 1) {
          this.hat.setRotation(Phaser.Math.Angle.RotateTo(oldRot, targetRot, dist * 0.1))
        } else {
          this.hat.setRotation(targetRot)
        }

        // 2. set position
        var a = new Phaser.Math.Vector2(this.image.x, this.image.y)
        var b = new Phaser.Math.Vector2(0, -60 + (Math.min(0.9, Math.abs(targetRot)) * 7))
        b.rotate(this.image.rotation)
        b.x = a.x + b.x
        b.y = a.y + b.y

        var c = new Phaser.Math.Vector2(0, -2)
        c.rotate(this.hat.rotation)
        c.x = b.x + c.x
        c.y = b.y + c.y

        this.hat.setPosition(c.x, c.y)
      } else {
        var oldRot = this.hat.rotation
        this.hat.setRotation(Phaser.Math.Angle.RotateTo(oldRot, oldRot + 0.02 * this.hat.body.velocity.x, 1))// 0.01)) // TODO: use diff as interpSpeed
      }
      if (this.hat.y > 590) {
        this.hat.y = 590
        this.hat.setVelocityY(0)
        this.hat.setIgnoreGravity(true)
      }
    }
    // diff.x = c.x - b.x
    // diff.y = c.y - b.y
    // var angle = diff.angle() + 0.5 * Math.PI
    // this.hat.setRotation(angle)
  }

  updateStretch (delta) {
    if (this.stretch > 1) {
      this.stretch = Math.max(this.stretch - (delta * 0.001), 1)
    }

    if (this.strechtDown) {
      this.setScale(this.stretch, 1 / this.stretch)
      this.setPosition(this.image.x * (1 - this.scaleX), this.image.y * (1 - this.scaleY) + (this.stretch - 1) * 15)
    } else {
      this.setScale(1 / this.stretch, this.stretch)
      this.setPosition(this.image.x * (1 - this.scaleX) + (this.stretch - 1) * 15, this.image.y * (1 - this.scaleY))
    }
  }

  toggleUseMatterGravity () {
    this.useMatterGravity = !this.useMatterGravity
    this.image.setIgnoreGravity(this.ignoreGravity || !this.useMatterGravity)
    // console.log('Using MATTER gravity = ' + this.useMatterGravity)
  }

  preUpdate (time, delta) {
    // if (!this.useMatterGravity && (delta < 18)) {
    //   this.toggleUseMatterGravity()
    // } else if (this.useMatterGravity && (delta >= 20)) {
    //   this.toggleUseMatterGravity()
    // }

    var realDelta = delta
    if (!this.useMatterGravity) {
      realDelta = 16.66
    }

    this.updateTails()
    this.updateStretch(realDelta)

    if (this.isGameOver) {
      return
    }

    if (this.fellInGap) {
      if (this.image.y >= 680) {
        this.scene.gameover()
        this.image.setVelocityX(0)
      }
      return
    }

    if (this.image.y >= 555) {
      this.onGround()
      if (this.image.y >= 680) {
        this.scene.gameover()// this.onHit(-90, true)
        this.image.setVelocityX(0)
      }
    }

    if (!this.useMatterGravity && !this.ignoreGravity) {
      var velY = this.image.body.velocity.y
      this.image.setVelocityY(velY + (35 * (16 / 1000)))
    }

    if (!this.hasStarted || this.scene.levelEditor.enabled) {
      return
    }

    if (!this.keyEsc.isDown && this.keyEscWasDown) {
      if (this.scene.isCustomLevel) {
        this.scene.levelEditor.enable()
      } else {
        this.onHit(0, false)
      }
    }
    this.keyEscWasDown = this.keyEsc.isDown

    if (this.keyEditor.isDown) {
    // if (!this.scene.levelEditor.enabled) {
      this.scene.levelEditor.enable()
    }

    // if (this.scene.spawner.finishX >= 0 && this.image.x > this.scene.spawner.finishX) {
    //   this.scene.finish()
    // }

    var speed = this.image.body.velocity.x
    var accel = ACCEL
    if (this.keyRight.isDown && !this.scene.finished) {
      if (this.speed < 0) { accel *= 2 }
      speed += accel * (realDelta / 1000)
      var maxSpeed = SPEED
      if (this.image.x > this.scene.spawner.finishX) {
        maxSpeed *= (750 - (this.image.x - this.scene.spawner.finishX)) / 750
      }
      speed = Math.min(speed, maxSpeed)
      this.image.setVelocityX(speed)
    } else if (this.keyLeft.isDown && this.image.x > -200 && !this.scene.finished) {
      if (this.speed > 0) { accel *= 2 }
      speed -= accel * (realDelta / 1000)
      speed = Math.max(speed, -SPEED)
      this.image.setVelocityX(speed)
    } else if (speed > 0) {
      speed -= DECEL * (realDelta / 1000)
      speed = Math.max(speed, 0)
      this.image.setVelocityX(speed)
    } else if (speed < 0) {
      speed += DECEL * (realDelta / 1000)
      speed = Math.min(speed, 0)
      this.image.setVelocityX(speed)
    }

    if (this.scene.touch) {
      if (this.keyLeft.isDown) {
        this.scene.touch.leftInputImg.setAlpha(1)
      } else {
        this.scene.touch.leftInputImg.setAlpha(0.5)
      }
      if (this.keyRight.isDown) {
        this.scene.touch.rightInputImg.setAlpha(1)
      } else {
        this.scene.touch.rightInputImg.setAlpha(0.5)
      }
    }

    if (!this.image.isStatic() && !this.scene.finished) {
      if (false) { // this.image.x > this.scene.spawner.finishX) {
        // var cakePos = new Phaser.Math.Vector2(this.scene.spawner.finishX + 500, 800)
        // var imPos = new Phaser.Math.Vector2(this.image.x, this.image.y)
        // var diff = new Phaser.Math.Vector2(cakePos.x - imPos.x, cakePos.y - imPos.y)
        // var angle = diff.angle() + 0.5 * Math.PI
        // var currentAngle = this.image.angle
        // var targetAngle = Phaser.Math.RadToDeg(angle)
        // var t = 3 / (Math.abs(targetAngle - currentAngle))
        // this.image.angle = Phaser.Math.Linear(currentAngle, targetAngle, t)
      } else {
        var rotationSpeed = 0
        if (this.keyRight.isDown) {
          rotationSpeed = 3// 20
        } else if (this.keyLeft.isDown) {
          rotationSpeed = -3// 20
        } else {
          rotationSpeed = (this.image.body.velocity.x / SPEED) * 3// 20
        }
        // var currentAngle = this.image.angle
        this.image.angle += rotationSpeed

        // var cakePos = new Phaser.Math.Vector2(this.scene.spawner.finishX + 500, 800)
        // var imPos = new Phaser.Math.Vector2(this.image.x, this.image.y)
        // var diff = new Phaser.Math.Vector2(cakePos.x - imPos.x, cakePos.y - imPos.y)

        // var angle = Phaser.Math.RadToDeg(diff.angle() + 0.5 * Math.PI)
        // var distToCake = Math.abs(cakePos.x - imPos.x)
        // var frac = distToCake / 500

        // console.log(frac)

        // TODO: rotate TOWARDS angle...
        // if (distToCake < 500) {
        // this.image.angle = Phaser.Math.Linear(angle, targetAngle, frac)// += rotationSpeed
        // } else {
        // targetAngle
        // }

      // this.image.rotation = angle
      }
    }
  }

  addCombo () {
    this.combo += 1
    this.emit('combo', this.combo)
  }

  addScore () {
    this.nSpikesThisCombo++

    if (this.nBouncesThisCombo <= 1) {
      for (var i = 0; i < this.nSpikesThisCombo; i++) {
        this.addCombo()
      }
    }

    this.score += this.combo
    this.emit('score', this.score)

    this.nBouncesThisCombo = 0
  }

  /**
   * @param {Phaser.Types.Physics.Matter.MatterCollisionData} collisionData
   */
  onCollision (collisionData) {
    if (!this.isGameOver && collisionData.bodyB.isSensor) {
      const velocity = new Phaser.Math.Vector2(-this.image.body.velocity.x, -(this.image.body.velocity.y))
      const angle = Phaser.Math.RadToDeg(velocity.angle())
      this.onHit(angle, true, collisionData)

      if (collisionData.bodyB.gameObject && collisionData.bodyB.gameObject.parentContainer) {
        try {
          collisionData.bodyB.gameObject.parentContainer.pauseTween(false)
        } catch (e) {}
      }
    }
  }

  /**
   * @param {Phaser.Types.Physics.Matter.MatterCollisionData} collisionData
   */
  onHit (angle, hideTitle, collisionData = null) {
    if (this.hasHat) { // && this.hat.y > this.image.y) {
      this.hasHat = false

      this.hat.setVelocityX(this.image.body.velocity.x)
      this.hat.setVelocityY(Math.min(-4, this.image.body.velocity.y))
      this.hat.setIgnoreGravity(false)
    }

    this.doStretch(1.25, this.image.body.velocity.y > 0)

    this.image.setIgnoreGravity(true)
    this.ignoreGravity = true

    if (this.image.x > this.scene.spawner.finishX && collisionData) {
      var shape
      if (collisionData.collision.supports.length > 1) {
        shape = new Phaser.Geom.Line(
          Math.min(collisionData.collision.supports[0].x, collisionData.collision.supports[1].x) - 10,
          collisionData.collision.supports[0].y,
          Math.max(collisionData.collision.supports[0].x, collisionData.collision.supports[1].x) + 10,
          collisionData.collision.supports[1].y)
      } else {
        shape = new Phaser.Geom.Line(
          collisionData.collision.supports[0].x - 5,
          collisionData.collision.supports[0].y,
          collisionData.collision.supports[0].x + 5,
          collisionData.collision.supports[0].y)
      }

      if (this.image.body.velocity.y > 0 && this.image.y < 500) { // this.image.x > this.scene.spawner.cakeIm.x - 100 && this.image.x < this.scene.spawner.cakeIm.x + 100) {
        if (this.image.x < this.scene.spawner.cakeIm.x - 50) {
          this.scene.spawner.cakeIm.setTexture('cake2', 2)
        } else if (this.image.x > this.scene.spawner.cakeIm.x + 50) {
          this.scene.spawner.cakeIm.setTexture('cake2', 3)
        } else {
          this.scene.spawner.cakeIm.setTexture('cake2', 1)
        }

        collisionData.bodyB.gameObject.setCollisionGroup(this.scene.collisionGroup2)
        collisionData.bodyB.gameObject.setCollisionCategory(this.scene.collisionCategory3)

        this.berryP.setPosition(this.scene.spawner.cakeIm.x, this.scene.spawner.cakeIm.y - 45)
        this.berryP.explode()

        // this.image.setStatic(false)
        this.image.setVelocityX(0)
        this.image.setVelocityY(0.15)
      } else {
        // point arms towards cake??

        this.image.setStatic(true)
        this.image.setVelocityX(0)
        this.image.setVelocityY(0)
      }

      this.cakePa.setEmitZone({ type: 'random', source: shape })
      // this.cakePa.setAngle({ min: angle - 40, max: angle + 40 })
      this.cakePa.setAngle({ min: 245, max: 295 })
      this.cakePa.explode()
      this.scene.finish()

      this.scene.spawner.heartIm.x = this.image.x + 70
      this.scene.spawner.heartIm.y = this.image.y - 70
      if (this.image.x < this.scene.spawner.finishX + 500) {
        this.scene.spawner.heartIm.x -= 140
        this.scene.spawner.heartIm.flipX = true
      }
      setTimeout(() => {
        this.scene.spawner.heartIm.setVisible(true)
      }, 1000)
    } else {
      this.image.setStatic(true)
      this.image.setVelocityX(0)
      this.image.setVelocityY(0)
      // this.scene.cameras.main.shake(50, 0.01)
      this.scene.gameover(hideTitle)
    }
  }

  onGround () {
    const gap = this.scene.spawner.isGapAt(this.image.x, SIZE * 0.35)
    if (gap >= 0) {
      const bounceDir = this.scene.spawner.getBounceDirAtGap(gap, this.image.x, SIZE)
      if (bounceDir !== 0) {
        // this.onHit(bounceDir > 0 ? -45 : -135, true)
        this.fellInGap = true
        this.image.setVelocityX(Math.sign(bounceDir) * 5)
      }
      return
    }

    if (this.hasHat && this.hat.y > this.image.y) {
      this.hasHat = false

      this.hat.setVelocityX(this.image.body.velocity.x)
      this.hat.setVelocityY(-20)
      this.hat.setIgnoreGravity(false)
    }
    //    var v = this.image.body.velocity.x * 3
    //  this.explode.setAngle({ min: 225 + v, max: 315 + v })
    this.explode.setPosition(this.image.x - 5, 600)
    this.explode.explode()

    this.explode2.setPosition(this.image.x + 5, 600)
    this.explode2.explode()

    this.exhaust.on = true
    setTimeout(() => { this.exhaust.on = false }, 40)

    this.doStretch(1.25, true)

    this.image.y = 550

    var vel = this.hasStarted ? -26 : -22
    this.image.setVelocityY(vel)

    this.nBouncesThisCombo++
    this.nSpikesThisCombo = 0

    if (this.nBouncesThisCombo >= 2) {
      this.combo = 1
      this.emit('combo', this.combo)
    }
  }

  teleportToFinish () {
    this.image.x = this.scene.spawner.cakeIm.x
    this.image.y = -200

    this.image.setVelocityX(0)
    this.image.setVelocityY(0)
  }

  pause () {
    this.image.setStatic(true)
  }

  unpause () {
    this.image.setStatic(false)
  }
}
