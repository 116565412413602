// import Ground from '../actors/ground'
// import Gap from '../actors/gap'
// import Obstacle from '../actors/obstacle'
import Phaser from 'phaser'

const MIN_RATING = 50
const MAX_RATING = 2500

export default class DifficultyManager {
  constructor (game) {
    this.game = game

    this.rating = this.loadRating()
  }

  getRating () {
    return this.rating
  }

  onLevelFailed (levelRating) {
  }

  onLevelSuccess (nRespawns) {
    // By nRespawns:
    // 0: 65..165
    // 1: 40
    // 2: 15
    // 3: -10
    // 4: -35
    // 5: -60
    // 6: -85
    // 7: -110
    // etc
    if (nRespawns === 0) {
      this.increaseRating(65 + Math.round(this.rating / 25))
    } else {
      this.increaseRating(40 - ((nRespawns - 1) * 25))
    }
  }

  increaseRating (by) {
    // console.log('increasing rating by ' + by)
    this.setRating(this.rating + by)
  }

  setRating (to) {
    // console.log('setRating to: ' + to)
    this.rating = Phaser.Math.Clamp(to, MIN_RATING, MAX_RATING)
    this.game.game.registry.set('rating', this.rating)
    this.saveRating()
  }

  saveRating () {
    // console.log('saving rating (' + this.rating + ') to localStorage..')
    try {
      window.localStorage.setItem('rating', this.rating)
    } catch (e) {
      this.game.game.registry.set('ratingBackup', this.rating)
    }
  }

  loadRating () {
    // console.log('retreiving rating from localStorage..')

    try {
      return parseInt(window.localStorage.getItem('rating')) || MIN_RATING
    } catch (e) {
      return this.game.game.registry.get('ratingBackup') || MIN_RATING
    }
  }
}
