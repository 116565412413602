import Phaser from 'phaser'

// export const GROUND_WIDTH = 400
export const GROUND_HEIGHT = 200

export default class GapHandler extends Phaser.GameObjects.Container {
  constructor (x, y, tint, gap, scene) {
    super(scene)

    this.gap = gap

    this.image = this.scene.add.image(x, y, 'white')
    this.image.setTint(tint)
    this.image.setSize(10, GROUND_HEIGHT)
    this.image.setDisplaySize(10, GROUND_HEIGHT)
    this.image.setOrigin(0, 0)
    this.image.setDepth(6)

    this.collider = this.scene.matter.add.image(x, y, 'white')
    this.collider.setTint(0xFFFFFF)
    this.collider.setSize(100, GROUND_HEIGHT + 100)
    this.collider.setDisplaySize(100, GROUND_HEIGHT + 100)
    this.collider.setIgnoreGravity(true)
    this.collider.setStatic(true)
    this.collider.setSensor(true)
    this.collider.setDepth(5)
    this.collider.setVisible(false)
    this.collider.parentContainer = this
    this.collider.setCollisionGroup(this.scene.collisionGroup2)
    this.collider.setCollisionCategory(this.scene.collisionCategory3)
  }

  preUpdate (time, delta) {
    // if (this.image.x < this.scene.player.image.x - this.image.width - 400) {
    //   this.image.destroy()
    //   this.destroy()
    // }
  }

  getX () {
    return this.image.x
  }

  getY () {
    return this.image.y
  }

  setX (x) {
    this.image.x = x
    this.collider.x = x

    if (this === this.gap.leftHandler) {
      this.gap.setLeft(x)
    } else {
      this.gap.setRight(x)
    }
  }

  toDef () {
    return this.gap.toDef()
  }

  onDeleted () {
    this.image.destroy()
    this.collider.destroy()
  }

  delete () {
    return this.gap.delete()
  }
}
