export default class Button {
  constructor (scene, x, y, text, image, callback, textOffset = -70) {
    this.scene = scene
    this.callback = callback
    this.textOffset = textOffset

    var config = {
      fontSize: '30px',
      fontStyle: 'bold'
    }

    this.text = this.scene.add.text(x + textOffset, y + 30, text, config)
    // this.text.setShadow(2, 2, '#bbb')
    this.text.setVisible(false)

    this.image = this.scene.add.image(x, y, 'images', image)
    this.image.setScale(0.25)
    this.image.setInteractive()
    this.image.input.alwaysEnabled = true
    this.image.setAlpha(0.5)
    this.image.on('pointerover', () => { this.image.setAlpha(1); this.text.setVisible(true) })
    this.image.on('pointerout', () => { this.image.setAlpha(0.5); this.text.setVisible(false) })
    this.image.on('pointerdown', (pointer, localX, localY, event) => { this.callback() })
  }

  destroy () {
    this.text.destroy()
    this.image.destroy()
  }

  hide () {
    this.text.setVisible(false)
    this.image.setVisible(false)
  }

  unhide () {
    this.text.setVisible(true)
    this.image.setVisible(true)
  }

  setX (x) {
    this.text.x = x + this.textOffset
    this.image.x = x
  }

  setY (y) {
    this.image.y = y
    this.text.y = y + 30
  }
}
