import Phaser from 'phaser'

export default class TouchInput extends Phaser.GameObjects.Container {
  /**
     * @param {Phaser.Scene} scene
     * @param {Player} player
     */
  constructor (scene, player) {
    super(scene)

    this.scene = scene
    this.player = player

    // button images:
    this.leftInputImg = this.scene.add.image(0, 0, 'images', 'touch_input')
    this.leftInputImg.setFlipX(true)
    this.leftInputImg.setScrollFactor(0)
    this.leftInputImg.setDepth(1000)
    this.leftInputImg.setAlpha(0.5)

    this.rightInputImg = this.scene.add.image(0, 0, 'images', 'touch_input')
    this.rightInputImg.setScrollFactor(0)
    this.rightInputImg.setDepth(1000)
    this.rightInputImg.setAlpha(0.5)

    // button sensors:
    this.leftInput = this.scene.add.image(0, 0, 'white')
    this.leftInput.setScrollFactor(0)
    this.leftInput.setDepth(1000)
    this.leftInput.setVisible(false)

    this.rightInput = this.scene.add.image(0, 0, 'white')
    this.rightInput.setScrollFactor(0)
    this.rightInput.setDepth(1000)
    this.rightInput.setVisible(false)

    // if (!this.scene.game.device.os.desktop) {
    this.leftInput.setInteractive()
    this.leftInput.input.alwaysEnabled = true
    this.leftInput.on('pointerdown', (pointer, localX, localY, event) => {
      if (localX < 1 && localY < 1) {
        this.player.keyLeft.isDown = true
        this.player.keyLeft.isUp = false
      }
    })
    this.leftInput.on('pointerup', (pointer, localX, localY, event) => {
      this.player.keyLeft.isDown = false
      this.player.keyLeft.isUp = true
    })

    this.rightInput.setInteractive()
    this.rightInput.input.alwaysEnabled = true
    this.rightInput.on('pointerdown', (pointer, localX, localY, event) => {
      if (localX < 1 && localY < 1) {
        this.player.keyRight.isDown = true
        this.player.keyRight.isUp = false
      }
    })
    this.rightInput.on('pointerup', (pointer, localX, localY, event) => {
      this.player.keyRight.isDown = false
      this.player.keyRight.isUp = true
    })
    // }

    this.rotateDeviceIm = this.scene.add.image(0, 0, 'images', 'rotate_device')
    this.rotateDeviceIm.setScrollFactor(0)
    this.rotateDeviceIm.setAlpha(0.5)
    this.rotateDeviceIm.setDepth(1000)
    this.rotateDeviceIm.setVisible(false)
    this.rotateDeviceIm.setScale(0.75)

    this.landscape = true
  }

  onResize (landscape) {
    this.landscape = landscape
    if (landscape) {
      this.leftInput.setPosition(300, 350)
      this.leftInput.setSize(600, 800)
      this.leftInput.setDisplaySize(600, 800)

      this.rightInput.setPosition(980, 350)
      this.rightInput.setSize(600, 800)
      this.rightInput.setDisplaySize(600, 800)

      if (this.scene && this.scene.game.device.os.desktop) {
        this.leftInputImg.setPosition(80, 660)
        this.rightInputImg.setPosition(1200, 660)
        this.leftInputImg.setScale(0.350)
        this.rightInputImg.setScale(0.350)
      } else {
        this.leftInputImg.setPosition(80, 510)
        this.rightInputImg.setPosition(1200, 510)
        this.leftInputImg.setScale(0.6)
        this.rightInputImg.setScale(0.6)
      }
    } else {
      this.leftInput.setPosition(0, 640)
      this.leftInput.setSize(640, 1000)
      this.leftInput.setDisplaySize(640, 2400)

      this.rightInput.setPosition(720, 640)
      this.rightInput.setSize(640, 1000)
      this.rightInput.setDisplaySize(640, 2400)

      this.leftInputImg.setPosition(140, 1200)
      this.leftInputImg.setScale(2)

      this.rightInputImg.setPosition(580, 1200)
      this.rightInputImg.setScale(2)

      this.rotateDeviceIm.setPosition(360, 1650)
      this.rotateDeviceIm.setVisible(true)
    }
  }

  preUpdate (time, delta) {
    if (this.scene && !this.scene.player.hasStarted && !this.scene.game.device.os.desktop) {
      if (time % 1000 < 500) {
        this.rightInputImg.setAlpha(1)
        this.leftInputImg.setAlpha(0.5)
      } else {
        this.rightInputImg.setAlpha(0.5)
        this.leftInputImg.setAlpha(1)
      }
    }
  }
}
