import Phaser from 'phaser'

// export const GROUND_WIDTH = 400
export const GROUND_HEIGHT = 200

export default class Gap extends Phaser.GameObjects.Container {
  constructor (x, y, width, tint, scene) {
    super(scene)

    this.x = x
    this.width = width
    this.leftHandler = null
    this.rightHandler = null

    // this.image = this.scene.add.image(x, y - 100, 'white')
    // this.image.setSize(width, 20)
    // this.image.setDisplaySize(width, 20)
    // this.image.setOrigin(0, 0)
    // this.image.setDepth(6)
  }

  preUpdate (time, delta) {
    // if (this.image.x < this.scene.player.image.x - this.image.width - 400) {
    //   this.image.destroy()
    //   this.destroy()
    // }
  }

  getX () {
    return this.x
  }

  setLeft (x) {
    this.width -= (x - this.x)
    this.x = x

    // this.image.x = x
    // this.image.setSize(this.width, 20)
    // this.image.setDisplaySize(this.width, 20)
  }

  setRight (x) {
    this.width = x - this.x

    // this.image.setSize(this.width, 20)
    // this.image.setDisplaySize(this.width, 20)
  }

  toDef () {
    return { x: Math.round(this.x), t: 'g', c: { w: Math.round(this.width) } }
  }

  delete () {
    const index = this.scene.spawner.isGapAt(this.x + 1, 0)
    if (index > -1) {
      this.scene.spawner.gaps.splice(index, 1)
    }

    this.leftHandler.onDeleted()
    this.leftHandler.destroy()
    this.rightHandler.onDeleted()
    this.rightHandler.destroy()
    return this
  }
}
