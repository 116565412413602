import Phaser from 'phaser'
import Button from '../components/button'

export default class EditorHudScene extends Phaser.Scene {
  create ({ game, player }) {
    this.player = player
    this.game = game
    const y = 80

    var temp = new Button(this, 970, y, 'Download Level', 'download', () => {
      this.game.levelEditor.save(true)
    })
    temp = new Button(this, 1045, y, 'Share Level', 'share', () => {
      var level = this.game.levelEditor.save(false)
      this.game.levelEditor.share(level)
    })
    temp = new Button(this, 1120, y, 'Test Level', 'play', () => {
      this.game.levelEditor.disable()
    })

    temp = new Button(this, 100, y, 'Add Obstacle', 'add_spike', () => { this.game.levelEditor.spawnType('o') })
    temp = new Button(this, 150, y, 'Add Top Obstacle', 'add_top_spike', () => { this.game.levelEditor.spawnType('tO') })
    temp = new Button(this, 200, y, 'Add Moving Obstacle', 'add_moving', () => { this.game.levelEditor.spawnType('mO') })
    temp = new Button(this, 250, y, 'Add Gap', 'add_gap', () => { this.game.levelEditor.spawnType('g') })
    temp = new Button(this, 300, y, 'Add GuidePoint', 'play', () => { this.game.levelEditor.spawnType('tH') })
  }

  update (time, delta) {

  }
}
