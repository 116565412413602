export default class Level {
  constructor () {
    this.data = []
    this.index = 0
  }

  setObjectDef (x, t, c) {
    this.data.push({ x, t, c })

    // TODO: sort can probably be postponed to the save() call..
    this.data.sort(function (a, b) {
      return a.x - b.x
    })
  }

  getNextObjectDef () {
    if (this.index < this.data.length) {
      this.index++
      return this.data[this.index - 1]
    }
    return -1
  }

  getDefs () {
    return this.data
  }
}
