import Phaser from 'phaser'
const types = ['o', 'tO', 'g', 'mO', 'f']

export default class LevelEditor extends Phaser.GameObjects.Container {
  /**
   * @param {Phaser.Scene} scene
   */
  constructor (scene) {
    super(scene)

    this.scene = scene
    this.enabled = false

    var config = {
      fontSize: '30px',
      fontStyle: 'bold'
    }

    this.editorText = this.scene.add.text(50, 650, 'EDITOR', config)
    this.editorText.setScrollFactor(0, 0)
    this.editorText.setDepth(1000)
    this.editorText.setVisible(false)

    this.keyEnterWasDown = false
    this.keySpaceWasDown = false
    this.keySaveWasDown = false
    this.keyEditWasDown = false
    this.keyDeleteWasDown = false

    this.offsetX = 0
    this.offsetY = 0

    this.highlight = this.scene.add.image(0, 310, 'images', 'circle')
    this.highlight.setSize(40, 40)
    this.highlight.setDisplaySize(128, 128)
    this.highlight.setAlpha(0.5)
    this.highlight.setVisible(false)

    this.image = this.scene.matter.add.image(0, 310, 'white')
    this.image.setSize(40, 40)
    this.image.setDisplaySize(10, 10)
    this.image.setTint(0x555555)
    this.image.setVisible(false)
    this.image.setIgnoreGravity(true)
    this.image.setSensor(true)
    this.image.setOnCollide((collisionData) => { this.onCollision(collisionData) })
    this.image.setOnCollideActive((collisionData) => { this.onCollisionActive(collisionData) })
    this.image.setOnCollideEnd((collisionData) => { this.onCollisionEnd(collisionData) })
    this.image.setDepth(1)
    this.image.setAngle(90)
    this.image.setCollisionGroup(this.scene.collisionGroup2)
    this.image.setCollidesWith(this.scene.collisionCategory2)
    this.image.setCollisionCategory(this.scene.collisionCategory3)

    this.type = types[0]

    this.highlightedObject = null
    this.isDragging = false
    this.isScrolling = false
    this.scrollOriginX = 0
  }

  setupInput () {
    this.keyCycle = this.scene.input.keyboard.addKey('E')
    this.keySpawn = this.scene.input.keyboard.addKey('space')
    this.keyEditor = this.scene.input.keyboard.addKey('Q')
    this.keyDelete = this.scene.input.keyboard.addKey('delete')
    this.keySave = this.scene.input.keyboard.addKey('S')

    this.keyLeft = this.scene.input.keyboard.addKey('left') // not needed
    this.keyRight = this.scene.input.keyboard.addKey('right') // not needed

    this.keyEdit = this.scene.input.keyboard.addKey('I') // not needed
    this.keyUp = this.scene.input.keyboard.addKey('up') // not needed
    this.keyDown = this.scene.input.keyboard.addKey('down')// not needed
  }

  enable () {
    if (!(location.hostname === 'localhost' && process.env.NODE_ENV === 'development')) {
      return
    }
    this.enabled = true
    this.scene.spawner.spawnCompleteLevel()
    this.editorText.text = ''
    this.editorText.setVisible(true)
    this.scene.spawner.pauseMovers()
    this.scene.isCustomLevel = true
    this.scene.startEditorHUD()
    this.scene.enableGrayscale()
    this.scene.player.pause()
  }

  disable () {
    this.enabled = false
    this.scene.spawner.unPauseMovers()
    this.scene.hideEditorHud()
    this.scene.disableGrayscale()
    this.scene.player.unpause()
    this.highlight.setVisible(false)
  }

  preUpdate (time, delta) {
    if (!this.enabled) {
      return
    }

    if (this.keyEditor.isDown) {
      this.disable()
      return
    }

    this.image.x = Phaser.Math.Clamp(this.scene.cameras.main.scrollX + this.scene.input.activePointer.x, -100, this.scene.spawner.finishX > 0 ? this.scene.spawner.finish.image.x + 1000 : 4000)
    this.image.y = this.scene.cameras.main.scrollY + this.scene.input.activePointer.y

    if (this.keyCycle.isDown && !this.keySpaceWasDown) {
      this.type = types[(types.indexOf(this.type) + 1) % types.length]
      this.editorText.text = ''
    }

    if (this.keyDelete.isDown && !this.keyDeleteWasDown) {
      if (this.highlightedObject) {
        var object = this.highlightedObject.delete()
        const index = this.scene.spawner.objects.indexOf(object)
        if (object && index > -1) {
          this.scene.spawner.objects.splice(index, 1)
        }
        this.highlightedObject = null
        this.highlight.setVisible(false)
        this.editorText.text = ''
      }
    }

    if (this.keySpawn.isDown && !this.keyEnterWasDown) {
      this.spawn(this.image.x)
    }

    if (this.keySave.isDown && !this.keySaveWasDown) {
      var lvl = this.save(true)
      this.share(lvl)
    }

    if (this.scene.input.activePointer.isDown) {
      if (!this.isDragging && this.highlightedObject) {
        this.isDragging = true
        this.offsetX = this.highlightedObject.getX() - this.image.x
        this.offsetY = this.highlightedObject.getY() - this.scene.input.activePointer.y
      } else if (!this.isDragging && !this.isScrolling && !this.scene.game.device.os.desktop) {
        this.isScrolling = true
        this.scrollOriginX = this.scene.input.activePointer.x
      }
    } else {
      this.isDragging = false
      this.isScrolling = false
    }

    if (this.highlightedObject && this.isDragging) {
      const x = this.image.x + this.offsetX
      const y = this.scene.input.activePointer.y + this.offsetY
      this.highlightedObject.setX(x)
      this.highlightedObject.setY(558 - y)

      this.editorText.text = this.objectText(this.highlightedObject)
    }

    if (this.isScrolling) {
      const x = this.scene.input.activePointer.x - this.scrollOriginX
      this.scrollOriginX = this.scene.input.activePointer.x
      this.scene.cameraController.scrollX(x)
    }

    if (this.highlightedObject) {
      this.highlight.x = this.highlightedObject.getX()
      this.highlight.y = this.highlightedObject.getY()
    }

    this.keyEnterWasDown = this.keySpawn.isDown
    this.keySpaceWasDown = this.keyCycle.isDown
    this.keySaveWasDown = this.keySave.isDown
    this.keyDeleteWasDown = this.keyDelete.isDown
    this.keyEditWasDown = this.scene.input.activePointer.isDown
  }

  spawnType (type) {
    this.type = type
    this.spawn(this.scene.cameras.main.scrollX + 640)
  }

  spawn (x) {
    var c = {}
    switch (this.type) {
      case 'mO':
        c = { b: 55, r: 420, p: 0 }
        break
      case 'o':
        c = { h: -2 }
        break
      case 'tO':
        c = { h: 40 }
        break
      case 'g':
        c = { w: 200 }
        break
    }
    var def = { x: x, t: this.type, c }
    this.scene.spawner.spawnFromDefinition(def, true)
    if (this.type !== 'tH') {
      this.scene.spawner.level.setObjectDef(def.x, def.t, def.c)
    }
  }

  onCollision (collisionData) {
    // moved this to onCollisionActive..
  }

  onCollisionActive (collisionData) {
    if (!this.isDragging && collisionData.bodyB.gameObject.parentContainer && collisionData.bodyB.gameObject.parentContainer !== this.highlightedObject) {
      this.highlightedObject = collisionData.bodyB.gameObject.parentContainer
      this.editorText.text = this.objectText(this.highlightedObject)
      this.highlight.x = this.highlightedObject.getX()
      this.highlight.y = this.highlightedObject.getY()
      this.highlight.setVisible(true)
    }
  }

  onCollisionEnd (collisionData) {
    if (!collisionData.bodyB.gameObject || (this.highlightedObject && collisionData.bodyB.gameObject.parentContainer === this.highlightedObject)) {
      this.highlightedObject = null
      this.highlight.setVisible(false)
      this.editorText.text = ''
    }
  }

  convertObjectToDef (object) {
    return object.toDef()
  }

  convertObjectsToDefs (objects) {
    var defs = []

    for (var i = 0; i < objects.length; i++) {
      defs.push(this.convertObjectToDef(objects[i]))
    }

    defs.sort(function (a, b) { return a.x - b.x })

    return defs
  }

  save (toFile = false) {
    var defs = this.convertObjectsToDefs(this.scene.spawner.objects)
    if (toFile) {
      const a = document.createElement('a')
      a.href = URL.createObjectURL(new Blob([JSON.stringify(defs)], {
        type: 'text/plain'
      }))
      a.setAttribute('download', 'level.json')
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
    return JSON.stringify(defs)
  }

  share (levelStr) {
    var urlStr = 'https://sharp-fermat-4ec2d5.netlify.app/?level='
    var encodedStr = btoa(levelStr)
    var fullURL = urlStr + encodedStr

    var oReq = new XMLHttpRequest()
    oReq.addEventListener('load', (e) => {
      this.scene.scene.run('SharePopup', { game: this.scene, url: e.currentTarget.responseText })
      this.scene.hideEditorHud()
    })
    oReq.open('GET', 'https://tinyurl.com/api-create.php?url=' + fullURL)
    oReq.send()
  }

  objectText (object) {
    const json = object.toDef()
    var str = ''
    switch (json.t) {
      case 'o':
        str += 'Obstacle x: ' + json.x + ', height: ' + json.c.h
        break
      case 'tO':
        str += 'topObstacle x: ' + json.x + ', height: ' + json.c.h
        break
      case 'mO':
        str += 'movingObstacle x: ' + json.x + ', bottom: ' + json.c.b + ', range: ' + json.c.r + ', phase: ' + json.c.p
        break
      case 'g':
        str += 'Gap x: ' + json.x + ', width: ' + json.c.w
        break
      case 'f':
        str += 'Finish x: ' + json.x
        break
      case 't':
        str += 'Guide: ' + json.c
        break
    }
    return str
  }

  spawnText (type) {
    var str = 'Add: '
    switch (type) {
      case 'o':
        str += 'Spike'
        break
      case 'tO':
        str += 'Hanging Spike'
        break
      case 'mO':
        str += 'Moving Spike'
        break
      case 'g':
        str += 'Gap'
        break
      case 'f':
        str += 'Finish'
        break
      case 't':
        str += 'Guide'
        break
    }
    return str
  }
}
