import Phaser from 'phaser'
import Button from '../components/button'
import MenuButton from '../components/menuButton'
import { SANTA } from '/actors/player'

const MAJOR_VERSION = 1
const MINOR_VERSION = '0.9'

const UNLOCKS = [0, 3, 6, 9, 13, 18, 24, 31, 39, 100000000]
// const UNLOCKS = [0, 3, 6, 9, 13, 16, 22, 28, 34, 100]

export default class TitleScene extends Phaser.Scene {
  create ({ game, player }) {
    this.game = game
    this.player = player

    this.unlockProgress = this.game.loadLevelProgress()

    if (this.game.game.device.os.desktop && location.hostname === 'localhost' && process.env.NODE_ENV === 'development') {
      this.newBtn = new Button(this, 1140, 60, 'New Level', 'create', () => {
        this.game.isCustomLevel = true
        this.startLevel('NEW')
        this.game.levelEditor.enable()
      }, -55)
      this.loadBtn = new Button(this, 1190, 60, 'Load Level', 'load', () => {
        var input = document.createElement('input')
        input.type = 'file'
        input.onchange = e => {
          var file = e.target.files[0]
          var reader = new FileReader()
          reader.readAsText(file, 'UTF-8')
          reader.onload = readerEvent => {
            var content = readerEvent.target.result

            this.game.isCustomLevel = true
            this.startLevel(content)
            this.game.levelEditor.enable()
            this.game.input.activePointer.isDown = false
          }
        }
        input.click()
      }, -105)
    }

    this.menuButtons = []
    for (var i = 0; i < UNLOCKS.length - 1; i++) {
      // var txt = ''
      // if (this.unlockProgress > UNLOCKS[i]) {
      //   txt = this.getTextForUnlock(i)
      // } else if (UNLOCKS[i] === UNLOCKS[this.nextUnlock(this.unlockProgress)]) {
      //   txt = '  ???'// 'level ' + UNLOCKS[i]
      // }
      const btn = new MenuButton(this, 0, 0, i, (unlockId) => {
        if (UNLOCKS[unlockId] < this.unlockProgress) {
          this.game.game.registry.set('selectedAnimal', unlockId)
          this.game.game.registry.set('lastPlayerY', -200)
          this.player.poof()
          setTimeout(() => { this.game.restart(false) }, 400)
        }
      }, UNLOCKS[i] < this.unlockProgress ? 'unlocks' : 'unlocks_locked',
      this.unlockProgress > UNLOCKS[i], UNLOCKS[i] < this.unlockProgress ? 1 : 4)
      this.menuButtons.push(btn)
    }

    // CHRISTMAS VERSION:
    // const santaBtn = new MenuButton(this, 0, 0, 9, (unlockId) => {
    //   this.game.game.registry.set('selectedAnimal', unlockId)
    //   this.game.game.registry.set('lastPlayerY', -200)
    //   this.player.poof()
    //   setTimeout(() => { this.game.restart(false) }, 400)
    // }, 'unlocks',
    // true, 1)
    // this.menuButtons.push(santaBtn)

    // if (this.game.game.registry.get('selectedAnimal') !== SANTA) {
    //   this.santaMsg = this.add.image(0, 0, 'santa_images', 'santa_msg')
    //   this.santaMsg.setScale(0.6)
    //   this.santaMsg.setAngle(2)
    //   this.tweens.add({
    //     targets: this.santaMsg,
    //     y: '-= 15',
    //     ease: 'Sine.easeInOut',
    //     duration: 500,
    //     repeat: -1,
    //     yoyo: true,
    //     angle: '+=3',
    //     scale: '+=0.02'
    //   })
    // }

    this.copyright = this.add.text(0, 0, '')// (c)2020 casper van est')
    this.version = this.add.text(0, 0, '')// v' + MAJOR_VERSION + '.' + MINOR_VERSION)

    this.name = this.add.sprite(0, 0, 'names', player.selectedAnimal)
    this.name2 = this.add.sprite(0, 0, 'names', 0)
    this.name2.setVisible(player.selectedAnimal === 6 || player.selectedAnimal === 7)

    this.title = this.add.image(0, 0, 'images', 'play_btn')
    this.playBtn = this.add.image(0, 0, 'images', 'play')
    this.playBtn.setScale(0.6)
    this.inputOutline = this.add.image(640, 480, 'images', 'inputField')
    this.inputOutline.setVisible(false)

    this.playBtnHitArea = this.add.image(640, 300, 'white')
    this.playBtnHitArea.setScale(1280, 600)
    this.playBtnHitArea.setVisible(false)
    this.playBtnHitArea.setInteractive()
    this.playBtnHitArea.input.alwaysEnabled = true
    this.playBtnHitArea.on('pointerup', (pointer, localX, localY, event) => {
      if (this.game.game.device.os.desktop) {
        this.keyboard.setVisible(true)
      } else {
        this.startLevel(this.game.levelId)
      }
    })

    // this.level = this.add.image(0, 0, 'images', 'level')
    const totalLevels = UNLOCKS[this.nextUnlock(this.unlockProgress)]
    if (this.game.levelId === 1 || totalLevels === UNLOCKS[UNLOCKS.length - 1]) {
      this.levelText = this.add.text(0, 0, this.game.levelId, { rtl: true, fontSize: '40px', fontStyle: 'bold' })
    } else {
      this.levelText = this.add.text(0, 0, this.game.levelId + '/' + totalLevels, { rtl: true, fontSize: '40px', fontStyle: 'bold' })

      this.barBG = this.add.image(0, 0, 'white')
      this.barBG.setDisplaySize(200, 30)
      this.barBG.setTint(0)
      this.barBG.setAlpha(0.25)

      this.barFG = this.add.image(0, 0, 'white')
      this.barFG.setOrigin(0, 0.5)
      this.barFG.setScale(0, 30)
      const scaleX = this.unlockPercentage(this.unlockProgress) * 200
      this.tweens.add({
        targets: this.barFG,
        scaleX: scaleX,
        duration: scaleX * 10
      })

      this.nextUnlockIm = new MenuButton(this, 0, 0, this.nextUnlock(this.unlockProgress), () => {}, 'unlocks', '')
      this.nextUnlockIm.setScale(0.75)
    }

    this.anims.create({
      key: 'keyboard_wiggle',
      frames: 'keyboard',
      frameRate: 2,
      repeat: -1
    })
    this.keyboard = this.add.sprite(0, 0, 'keyboard')
    this.keyboard.play('keyboard_wiggle')
    this.keyboard.setVisible(this.game.game.device.os.desktop && this.game.levelId === 1)

    this.keyEditor = this.input.keyboard.addKey('E')
    this.keyEditorWasDown = false
    // this.keySpace = this.input.keyboard.addKey('space')
    // this.keySpaceWasDown = false
    this.keyEnter = this.input.keyboard.addKey('enter')
    this.keyEnterWasDown = false

    this.allowInputTimer = 0.5

    this.inputField = this.add.dom(540, 460, 'input', {
      background: 'transparent',
      border: 0,
      color: 'white',
      fontSize: '28pt',
      fontWeight: 'bold',
      fontFamily: 'Courier',
      outline: 'none'
    }).setOrigin(0, 0)
    this.inputField.node.size = 9
    var levelId = this.game.game.registry.get('lastLevel')
    this.inputField.node.value = levelId || 'CUSTOM'
    this.inputField.node.addEventListener('keydown', (el) => {
      if (el.key === 'Enter') {
        this.game.isCustomLevel = true
        this.startLevel(el.target.value)
      }
    })

    this.focusedOnInputField = false
    this.hoverOverInputField = false

    this.inputField.node.onfocus = () => {
      if (this.inputField.node.value === 'CUSTOM') {
        this.inputField.node.value = ''
      }
      this.focusedOnInputField = true
    }
    this.inputField.node.onblur = () => { this.focusedOnInputField = false }
    this.inputField.node.onmouseenter = () => { this.hoverOverInputField = true }
    this.inputField.node.onmouseleave = () => { this.hoverOverInputField = false }

    this.pointerWasDown = false
    this.hasTriedToStart = false

    this.inputField.setVisible(false)

    // const ignoreURL = this.game.game.registry.get('ignoreURL')
    // if (!ignoreURL) {
    // const params = new URLSearchParams(window.location.search)
    // var levelStr = params.get('level')
    // if (levelStr) {
    //   this.game.isLevelURL = true
    //   this.game.isCustomLevel = true
    //   this.startLevel(levelStr)
    // }
    // }

    this.landscape = window.innerWidth > window.innerHeight
    this.scale.on('resize', () => {
      const oldLS = this.landscape
      this.landscape = window.innerWidth > window.innerHeight
      if (this.landscape !== oldLS) {
        this.onResize()
      }
    })
    this.onResize()
  }

  static hasJustUnlocked (levelId) {
    for (var i = 0; i < UNLOCKS.length; i++) {
      if (levelId === UNLOCKS[i]) {
        return i
      }
    }
    return -1
  }

  nextUnlock (levelId) {
    for (var i = 0; i < UNLOCKS.length; i++) {
      if (levelId <= UNLOCKS[i]) {
        return i
      }
    }
    return 1
  }

  unlockPercentage (levelId) {
    for (var i = 0; i < UNLOCKS.length; i++) {
      if (levelId <= UNLOCKS[i]) {
        return ((levelId - 1) - UNLOCKS[i - 1]) / (UNLOCKS[i] - UNLOCKS[i - 1])
      }
    }
  }

  onResize () {
    if (this.cameras.main) {
      if (this.landscape) {
        this.cameras.main.setZoom(1)
      } else {
        this.cameras.main.setZoom(0.5625)
      }

      this.title.setPosition((this.scale.gameSize.width / 2) + 15, 230)
      this.name.setPosition((this.scale.gameSize.width / 2) - 220 + 12, 231)
      this.name2.setPosition((this.scale.gameSize.width / 2) - 220 + 12, 231)

      if (this.player.selectedAnimal === 6 || this.player.selectedAnimal === 7) {
        this.name.x -= 140
      }

      if (this.barBG) { this.barBG.setPosition((this.scale.gameSize.width / 2), 440) }
      if (this.barFG) { this.barFG.setPosition((this.scale.gameSize.width / 2) - 100, 440) }
      if (this.nextUnlockIm) { this.nextUnlockIm.setPosition((this.scale.gameSize.width / 2) + 90, 440) }
      if (this.santaMsg) { this.santaMsg.setPosition((this.scale.gameSize.width / 2) + 410, 580) }

      this.playBtn.setPosition((this.scale.gameSize.width / 2), 360)
      this.levelText.setPosition((this.scale.gameSize.width / 2) + 105, 340)
      this.keyboard.setPosition((this.scale.gameSize.width / 2), 525)

      this.playBtnHitArea.setPosition(this.scale.gameSize.width / 2, 300)

      if (this.landscape) {
        this.copyright.setPosition(this.scale.gameSize.width - 235, 10)
        this.version.setPosition(this.scale.gameSize.width - 60, this.scale.gameSize.height - 20)
      } else {
        this.copyright.setPosition(this.scale.gameSize.width + 45, 10)
        this.version.setPosition(this.scale.gameSize.width + 200, this.scale.gameSize.height + 450)
      }
      const x = (this.scale.gameSize.width / 2) - ((this.menuButtons.length / 2) * 80) - 40
      for (var i = 0; i < this.menuButtons.length; i++) {
        this.menuButtons[i].setPosition(x + (i + 1) * 80, 655)
      }
    }
  }

  update (time, delta) {
    if (this.allowInputTimer > 0) {
      this.allowInputTimer -= delta / 1000
      return
    }

    if (this.player.hasStarted || this.hasTriedToStart) {
      return
    }

    if (this.focusedOnInputField) {
      var pointer = this.input.activePointer
      if (this.pointerWasDown && !pointer.isDown) {
        if (!this.hoverOverInputField) {
          this.inputField.node.blur()
        }
      }
      this.pointerWasDown = pointer.isDown
      return
    }

    if ((this.keyEditorWasDown && !this.keyEditor.isDown) && location.hostname === 'localhost' && process.env.NODE_ENV === 'development') {
      this.inputField.setVisible(true)
      this.inputOutline.setVisible(true)
    }
    this.keyEditorWasDown = this.keyEditor.isDown

    if (this.player.keyRight.isDown || this.player.keyLeft.isDown) { // || (this.keySpaceWasDown && !this.keySpace.isDown)) {
      this.startLevel(this.game.levelId)
    }
    // this.keySpaceWasDown = this.keySpace.isDown
  }

  startLevel (id) {
    this.hasTriedToStart = true
    this.game.startLevel(id, 0)

    this.inputField.destroy()

    this.game.tweens.add({
      targets: [this.inputOutline, this.title, this.playBtn, this.levelText, this.name, this.name2, this.keyboard],
      alpha: '0',
      duration: 200
    })

    if (this.barFG && this.barBG) {
      this.game.tweens.add({
        targets: [this.barBG, this.barFG],
        alpha: '0',
        duration: 200
      })
    }

    for (var i = 0; i < this.menuButtons.length; i++) {
      this.menuButtons[i].destroy()
    }

    if (this.nextUnlockIm) { this.nextUnlockIm.destroy() }
    this.copyright.destroy()
    this.version.destroy()
    this.playBtnHitArea.destroy()

    if (this.newBtn) {
      this.newBtn.destroy()
      this.loadBtn.destroy()
    }

    if (this.santaMsg) {
      this.santaMsg.destroy()
    }

    if (this.game.registry.get('poki')) {
      this.game.getBanner().destroyAd()
    }
  }

  getTextForUnlock (id) {
    switch (id) {
      case 0:
        return '  CAT'
      case 1:
        return ' OLLIE'
      case 2:
        return 'GIRAFFO'
      case 3:
        return ' BUNNI'
      case 4:
        return ' PANDO'
      case 5:
        return ' UNIE'
      case 6:
        return ' LUCKY'
      case 7:
        return ' FANCY'
      case 8:
        return ' ROBOT'
      case 9:
        return '   -'
      case 10:
        return '   -'
      case 11:
        return '   -'
      case 12:
        return '   -'
      case 13:
        return '   -'
      case 14:
        return '   -'
    }
  }
}
