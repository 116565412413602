import 'babel-polyfill'
import Phaser from 'phaser'
import LoadingScene from './scenes/loading'
import './style.css'

const config = {
  title: 'Bouncy Ball',
  url: '',
  parent: 'game',
  input: {
    activePointers: 4
  },
  dom: {
    createContainer: true
  },
  type: Phaser.WEBGL,
  width: 1280, // this will be resized on DOMContentLoaded
  height: 720, // this will be resized on DOMContentLoaded
  physics: {
    default: 'matter',
    matter: {
      debug: false, // process.env.NODE_ENV !== 'production',
      gravity: { y: 2 }
    }
  },
  scale: {
    mode: Phaser.Scale.FIT,
    autoCenter: Phaser.Scale.CENTER_BOTH
  },
  backgroundColor: '#000000',
  banner: false
}

export default class Game extends Phaser.Game {
  start () {
    super.start()
    this.input.keyboard.addCapture('SPACE') // to prevent the page from scrolling

    // this.registry.set('analytics', true) // make sure to comment out analytics code from index.html
    this.registry.set('analytics', !(location.hostname === 'localhost' && process.env.NODE_ENV === 'development'))

    // this.registry.set('poki', true)
    this.registry.set('poki', !(location.hostname === 'localhost' && process.env.NODE_ENV === 'development'))

    if (this.registry.get('poki')) {
      PokiSDK.init().then(() => {
        this.continueToGame()
      }).catch(() => {
        this.continueToGame()
      })
      // PokiSDK.setDebug(true)
      PokiSDK.setDebug(false)
    } else {
      this.continueToGame()
    }
  }

  continueToGame () {
    // const muted = window.localStorage.getItem('sound') === 'mute'
    // this.sound.setMute(muted)
    this.scene.add('LoadingScene', LoadingScene, true)
  }
}

document.addEventListener('DOMContentLoaded', () => {
  if (window.innerWidth > window.innerHeight) {
    config.width = 1280
    config.height = 720
  } else {
    config.width = 720
    config.height = 1280
  }

  const game = new Game(config)
  if (process.env.NODE_ENV !== 'production') {
    window.game = game
  }
})

if (module.hot && process.env.NODE_ENV !== 'production') {
  module.hot.dispose(() => {
    if (window.game) {
      const manager = window.game.scene
      console.log('Hot reload dispose, removing these scenes:')
      manager.dump()
      Object.keys(manager.keys).forEach(s => manager.remove(s))
    }
  })
  module.hot.accept(() => {
    if (window.game) {
      const manager = window.game.scene
      const initialScene = LoadingScene.loadScenes(manager)
      manager.start(initialScene)
      console.log('Reloaded scenes:')
      manager.dump()
    }
  })
}
